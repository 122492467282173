define('webstore-client/pages/artist',[
    'jquery',
    'webstore-client/locker/mark-owned',
    'translate'
], function ($, markOwned, translate) {
    return function initHomepage() {
        markOwned.updateOwnership().done(markOwned.updateTrackBuyButtons);
    };
});


