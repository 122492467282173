define('webstore-client/ui/playback/player/browser-controller',[
    'jquery',
    'lodash',
    'client-config',
    'debug',
    'webstore-client/analytics/track',
    'jplayer-playlist'
], function ($, _, clientConfig, debug, track, JPlayerPlaylist) {

    var initialised = false;
    var _error;
    var _isPlaying = false;
    var _isLoading = false;
    var _isReady = false;
    var _isFirstPlay = true;
    var _isMuted = false;
    var _stateChangeEvents = ['setmedia', 'loadstart', 'playing', 'loadabort', 'pause', 'error', 'stop', 'playlistended', 'playlistcleared'];
    var jPlayerPlaylist, jPlayerContext;
    var jPlayerEventPrefix = 'jPlayer_';

    var setEmptyProperties = function (accum, val) {
        accum[val] = '';
        return accum;
    };

    var selectors = _.reduce([
        'videoPlay', 'play', 'pause', 'stop', 'seekBar', 'playBar', 'mute',
        'unmute', 'volumeBar', 'volumeBarValue', 'volumeMax', 'noSolution',
        'playbackRateBar', 'playbackRateBarValue', 'currentTime', 'gui',
        'duration', 'fullScreen', 'restoreScreen', 'repeat', 'repeatOff',
        'title'
    ], setEmptyProperties, {});

    var jPlayerOptions = {
        swfPath: clientConfig.player.swfPath,
        volume: 0.7,
        errorAlerts: clientConfig.isDevelopment,
        warningAlerts: clientConfig.isDevelopment,
        solution: 'html,flash',
        supplied: 'm4a',
        cssSelector: selectors, // Clear all controls selectors because we manage all UI updates
        playlistOptions: {},
        smoothPlayBar: true //oh yeah, that's right B-)
    };

    function initJPlayer() {
        var stopwatch = track.startNewStopwatch();
        jPlayerContext = $('.jplayer-stub').removeClass('jplayer-stub');

        jPlayerOptions.ready = function () {
            track.trackTiming('Performance', 'Player ready', stopwatch.stop());
            _isReady = true;
            jPlayerContext.addClass('jplayer-active');
        };

        //This has to go before the jPlayerPlaylist binds its event handlers,
        //otherwise the current track has already moved on and playlistended
        //fires one track too early.
        on('ended', function () {
            if (jPlayerPlaylist.current === (jPlayerPlaylist.playlist.length - 1)) {
                triggerEvent('playlistended');
            }
        });

        on('error', function (e) {
            handleError(e.jPlayer);
            _isPlaying = false;
        });

        jPlayerPlaylist = new JPlayerPlaylist({
            jPlayer: jPlayerContext,
            cssSelectorAncestor: ''
        }, [], jPlayerOptions);

        on('setmedia', function () { clearError(); });
        on('preplay', function () { _isPlaying = true; });
        on('setmedia', function () { _isPlaying = true; });
        on('playing', function () { _isPlaying = true; });
        on('pause', function () { _isPlaying = false; });
        on('stop', function () { _isPlaying = false; });
        on('loadabort', function () { _isPlaying = false; });
        on('playlistended', function () { _isPlaying = false; });
        on('preplay', function () { _isLoading = true; });
        on('setmedia', function () { _isLoading = true; });
        on('playing', function () { _isLoading = false; });

        track.trackTiming('Performance', 'Player initialisation', stopwatch.stop());
    }

    function handleError(jPlayerState) {
        // Don't log or stash new errors if there's already been a fatal error
        if (_error && _error.isFatal) { return; }

        var err = jPlayerState.error;
        debug.error(err.message, null, jPlayerState);

        switch (err.type) {
            case $.jPlayer.error.NO_SOLUTION: return setError('PlaybackNotSupported', true);
            case $.jPlayer.error.URL: return setError('PreviewNotAvailable');
            default: return setError('Error_Processing_Request');
        }
    }

    function setError(translationKey, isFatal) {
        _error = { translationKey: translationKey, isFatal: isFatal };
    }

    function getError() {
        return _error;
    }

    function clearError() {
        _error = undefined;
    }

    function triggerEvent(eventName) {
        jPlayerContext.trigger(jPlayerEventPrefix + eventName);
    }

    // Used when an empty Playlist arrives, probably due to a preorder having
    // no preview streams. Triggers a 'Preview not currently
    // available' error and stops trying to play tracks
    function triggerEmptyPlaylistError() {
        var falseEvent = {
            error: {
                message: 'Empty playlist error',
                type: $.jPlayer.error.URL
            }
        };
        handleError(falseEvent);
        abortLoad();
    }

    function setSelectors(selectors) {
        if (selectors.root) {
            jPlayerContext.jPlayer('option', 'cssSelectorAncestor',
                selectors.root);
        }
        jPlayerContext.jPlayer('option', 'cssSelector', _.omit(selectors, 'root'));
    }

    function isReady() {
        return _isReady;
    }

    function isMuted() {
        return _isMuted;
    }

    function on(event, cb) {
        jPlayerContext.on(jPlayerEventPrefix + event, cb);
    }

    function off(event, cb) {
        jPlayerContext.off(jPlayerEventPrefix + event, cb);
    }

    // The first audio element play call must occur synchronously after a user
    // interaction, so this should be called as early as possible when a play
    // button is clicked
    function initAudioElement() {
        if (_isFirstPlay) {
            var audioElement = $('audio', jPlayerContext)[0];

            // Trigger a play synchronously on the audio element so it's in the
            // same call stack as the click event. Any future play calls can be
            // made asynchronously.
            if (audioElement) { audioElement.play(); }
            _isFirstPlay = false;
        }
    }

    function play(playlist, pos) {
        triggerEvent('preplay');

        pos = pos || 0;
        if (playlist.length === 0) {
            triggerEmptyPlaylistError();
            return;
        }
        playlist.forEach(function (track) { track.m4a = track.streamUrl; });
        jPlayerPlaylist.setPlaylist(playlist);
        jPlayerPlaylist.play(pos);
    }

    function jumpToPlaylistPosition(position) {
        jPlayerPlaylist.play(position);
    }

    function resume() {
        triggerEvent('preplay');
        jPlayerPlaylist.play();
    }

    function pause() {
        jPlayerPlaylist.pause();
    }

    function next() {
        jPlayerPlaylist.next();
    }

    function previous() {
        jPlayerPlaylist.previous();
    }

    function setVolume(value) {
        jPlayerContext.jPlayer('volume', value);
    }

    function toggleMute() {
        if (_isMuted) {
            jPlayerContext.jPlayer('unmute');
            _isMuted = false;
        } else {
            jPlayerContext.jPlayer('mute');
            _isMuted = true;
        }
    }

    function abortLoad() {
        var currentPos = jPlayerPlaylist.current;
        var currentPlaylist = jPlayerPlaylist.playlist;

        if (jPlayerContext.data('jPlayer').status.src) {
            jPlayerContext.jPlayer('stop');

            //clearMedia is the only command that actually cancels the download.
            //Unfortunately trying to play the playlist from the same point is
            //broken after clearMedia (due to some weirdness in jPlayerPlaylist).
            //To get around this we clear the playlist then reload it, and put
            //ourselves back at the same point in it, which gets jPlayer back into
            //a working state.
            jPlayerContext.jPlayer('clearMedia');

            jPlayerPlaylist.remove();
            jPlayerPlaylist.setPlaylist(currentPlaylist);
            jPlayerPlaylist.select(currentPos);
        }

        triggerEvent('loadabort');
    }

    function stop() {
        jPlayerContext.jPlayer('stop');
    }

    function setPlayheadSeek(currentTime, duration) {
        var percentOfSeekable = Math.floor((currentTime / duration) * 100);
        jPlayerContext.jPlayer('playHead', percentOfSeekable);
    }

    function currentTrack() {
        // Don't pass playlist objects by reference
        return _.cloneDeep(jPlayerPlaylist.playlist[jPlayerPlaylist.current]);
    }

    function currentTrackTime() {
        var s = jPlayerContext.data('jPlayer').status;
        return s.currentTime;
    }

    function currentTrackDuration() {
        var s = jPlayerContext.data('jPlayer').status;
        return s.duration;
    }

    function isPlaying() {
        return _isPlaying;
    }

    function isLoading() {
        return _isPlaying && _isLoading;
    }

    function clearPlaylist() {
        jPlayerPlaylist.remove();
        triggerEvent('playlistcleared');
    }

    if (!initialised) {
        initJPlayer();
        initialised = true;
    }

    function onWakeUp(cb) {
        on('preplay', cb);
    }

    function onStateChange(cb) {
        _.forEach(_stateChangeEvents, function (eventName) { on(eventName, cb); });
    }

    function onProgress(cb) {
        on('timeupdate', cb);
    }

    function onPlaylistEnded(cb) {
        on('playlistended', cb);
    }

    function removeWakeUpListener(cb) {
        off('preplay', cb);
    }

    function removeStateChangeListener(cb) {
        _.forEach(_stateChangeEvents, function (eventName) { off(eventName, cb); });
    }

    function removeProgressListener(cb) {
        off('timeupdate', cb);
    }

    function removePlaylistEndedListener(cb) {
        off('playlistended', cb);
    }

    function shouldUpdatePlaybackBar() {
        return false;
    }

    return {
        setSelectors: setSelectors,
        isReady: isReady,
        isMuted: isMuted,

        play: play,
        pause: pause,
        resume: resume,
        next: next,
        previous: previous,
        jumpToPlaylistPosition: jumpToPlaylistPosition,
        stop: stop,
        setVolume: setVolume,
        toggleMute: toggleMute,
        setPlayheadSeek: setPlayheadSeek,
        abortLoad: abortLoad,
        clearPlaylist: clearPlaylist,

        isPlaying: isPlaying,
        isLoading: isLoading,
        getError: getError,
        clearError: clearError,
        currentTrack: currentTrack,

        onWakeUp: onWakeUp,
        onStateChange: onStateChange,
        onProgress: onProgress,
        onPlaylistEnded: onPlaylistEnded,

        removeWakeUpListener: removeWakeUpListener,
        removeStateChangeListener: removeStateChangeListener,
        removeProgressListener: removeProgressListener,
        removePlaylistEndedListener: removePlaylistEndedListener,

        shouldUpdatePlaybackBar: shouldUpdatePlaybackBar,
        currentTrackDuration: currentTrackDuration,
        currentTrackTime: currentTrackTime,

        initAudioElement: initAudioElement
    };
});

