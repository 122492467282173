define('webstore-client/ui/playback/play-buttons/display',[
    'jquery',
    'webstore-client/ui/playback/controller'
], function ($, controller) {

    function setNoTracksCurrent() {
        $('[data-trackid].is-current-track').removeClass('is-current-track');
    }

    function setNothingPlaying() {
        $('[data-trackid].is-playing-track')
            .removeClass('is-playing-track');

        $('[data-trackid].is-loading-track')
            .removeClass('is-loading-track');

        $('[data-releaseid].is-playing-release')
            .removeClass('is-playing-release');

        $('[data-releaseid].is-loading-release')
            .removeClass('is-loading-release');

    }

    function setTrackCurrent(trackId) {
        setNoTracksCurrent();
        $('[data-trackid="' + trackId + '"]').addClass('is-current-track');
    }

    function setTrackPlaying(trackId) {
        setTrackCurrent(trackId);
        $('[data-trackid="' + trackId + '"]').addClass('is-playing-track').removeClass('is-loading-track');
    }

    function setTrackLoading(trackId) {
        $('.is-loading-track').removeClass('is-loading-track');
        $('[data-trackid="' + trackId + '"]').addClass('is-loading-track');
    }

    function setReleasePlaying(releaseId) {
        var release = $('[data-releaseid="' + releaseId + '"]');
        release.addClass('is-playing-release').removeClass('is-loading-release');
    }

    function setReleaseLoading(releaseId) {
        $('.is-loading-release').removeClass('is-loading-release');
        $('[data-releaseid="' + releaseId + '"]').addClass('is-loading-release');
    }

    function sync() {
        setNothingPlaying();
        setNoTracksCurrent();

        var currentTrack = controller.currentTrack();
        var currentPlaylist = controller.currentPlaylistMeta();

        if (currentTrack) {
            setTrackCurrent(currentTrack.trackId);
        }

        if (controller.isLoading()) {
            if (currentPlaylist.type === 'release' ||
                currentPlaylist.type === 'yourmusic-release') {
                setReleaseLoading(currentPlaylist.id);
            }

            if (currentTrack) {
                setTrackLoading(currentTrack.trackId);
            }

        } else if (controller.isPlaying() && currentTrack) {
            setTrackPlaying(currentTrack.trackId);

            if (currentPlaylist.type === 'release' ||
                currentPlaylist.type === 'yourmusic-release') {
                setReleasePlaying(currentPlaylist.id);
            }
        }
    }

    return {
        sync: sync,
        setReleaseLoading: setReleaseLoading,
        setReleasePlaying: setReleasePlaying,
        setTrackLoading: setTrackLoading,
        setTrackPlaying: setTrackPlaying
    };
});

