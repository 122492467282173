define('webstore-client/ui/user-details',[
    'jquery',
    'webstore-client/utilities/ajax',
    'debug',
    'client-config',
    'webstore-client/ui/playback/controller'
], function ($, ajax, debug, config, playbackController) {
    var initialised = false;

    if (!initialised) {
        if (config.user.isSignedIn) {
            ajax({
                url: '/user/details',
                background: true
            })
            .done(function (details) {
                $('<a/>', {
                    'href': '/account',
                    'class': 'user-signed-in-as',
                    'title': details.email,
                    'html': details.email
                }).prependTo('.header-account-signed-in');
            })
            .fail(function (jqXhr) {
                debug.ajaxError('couldn\'t get user details', jqXhr);
            });

            $('.header-account-signout').on('submit', onLogout);
        }

        initialised = true;
    }

    function onLogout(event) {
        // Clearing playlist will synchronously stop a Cast session
        // and the form submission will continue normally
        playbackController.clearPlaylist();
    }
});

