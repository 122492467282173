define('webstore-client/utilities/stopwatch',[],function () {
    return function () {
        var startTime,
            endTime;

        function now() {
            return (new Date()).getTime();
        }

        function elapsedTime() {
            if (endTime) {
                return (endTime - startTime);
            }
            return (now() - startTime);
        }

        function start() {
            startTime = now();
        }

        function stop() {
            endTime = now();
            return elapsedTime();
        }

        return {
            start: start,
            stop: stop,
            elapsedTime: elapsedTime
        };
    };
});

