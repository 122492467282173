define('webstore-client/ui/search/mobile-search',[
    'jquery',
    'webstore-client/ui/search/url-builder',
    'webstore-client/utilities/history'
], function ($, searchUrl, history) {

    var headerItems = $('.header-navigation-items');
    var searchInput = $('#search-input');
    var searchAction = searchInput.closest('form').attr('action');

    function deactivateSearch() {
        $('.header-search').removeClass('is-active')
            .parent().removeClass('has-active-search');
    }

    function dismissKeyboard() {
        searchInput.blur();
    }

    function toggleSearch(e) {
        e.preventDefault();
        e.stopPropagation();
        $('.header-search').toggleClass('is-active')
            .find('#search-input').trigger('focus').end()
            .parent().toggleClass('has-active-search');
    }

    function initialise() {
        // Remove href in JavaScript land instead of doing stopPropagation in the
        // click handler because other event listeners rely on the event bubbling
        $('.header-search-mobile a', headerItems).removeAttr('href');

        // Mobile search show/hide
        headerItems.on('mousedown', '.header-search-mobile a', toggleSearch);

        // Toggle search when blurred
        headerItems.on('blur', '#search-input', deactivateSearch);

        // Hide search once query has been submitted
        headerItems.on('submit', 'form[action="/search"]', function (e) {
            e.preventDefault();
            var searchTerm = $.trim(searchInput.val());
            if (searchTerm && searchTerm.length) {
                history.pushUrl(searchUrl.fullSearchResults(searchAction, searchTerm));
                dismissKeyboard();
                deactivateSearch();
            }
        });
    }

    function destroy() {

        // Mobile search show/hide
        headerItems.off('mousedown', '.header-search-mobile a', toggleSearch);

        // Toggle search when blurred
        headerItems.off('blur', '#search-input', deactivateSearch);

        // Hide search once query has been submitted
        headerItems.off('submit', 'form[action="/search"]', deactivateSearch);
    }

    return {
        init: initialise,
        destroy: destroy
    };
});

