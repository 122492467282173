define('webstore-client/pages/yourmusic-release',[
    'jquery',
    'lodash',
    'template-yourmusic-track-download-link'
], function ($, _, renderTrackDownloadLink) {
    var animationTime = (400 * 2);

    function updateDownloadManagerLinks(releaseFormatId) {
        $('.yourmusic-track-download').each(function () {
            var $trackDownload = $(this);
            var formats = $trackDownload.data('download-formats');
            var matchingFormat;

            // Special case - always show a PDF's download button
            if (formats.length === 1 && formats[0].id === '10') {
                matchingFormat = true;
            } else {
                matchingFormat = _.find(formats, function (f) {
                    return +f.id === +releaseFormatId;
                });
            }

            var $links = $('.yourmusic-track-download-links', $trackDownload);
            $links.empty();

            if (matchingFormat) {
                var linkForFormat = renderTrackDownloadLink(matchingFormat);
                $links.append(linkForFormat);
                $trackDownload.parents('tr').toggleClass('is-not-owned', false);
            } else {
                $trackDownload.parents('tr').toggleClass('is-not-owned', true);
            }
        });
    }

    function updateButtonFormat($el, formatId) {
        // Update the hidden form field containing the format id
        $el.prevAll('input[name="formatId"]').val(formatId);

        // Triggers the pulse animation
        $el.addClass('is-highlighted');
    }

    function clearAnimation() {
        $('.release-track .is-highlighted').removeClass('is-highlighted');
    }

    return function initFormatSelector() {
        $('input[name=formatId]').on('change', function (event) {
            var formatId = $(this).val();

            $('.yourmusic-track-download-btn').each(function () {
                updateButtonFormat($(this), formatId);
            });

            updateDownloadManagerLinks(formatId);

            setTimeout(clearAnimation, animationTime);
        });

        var initialFormatId = $('input[name=formatId]').val();

        updateDownloadManagerLinks(initialFormatId);
    };
});

