define('webstore-client/ui/playback/player/volume-slider',[
    'jquery',
    'lodash'
], function ($, _) {

    var CURSOR_OFFSET = 8; // Horizontal pixel offset to center drag position on mouse
    var DEFAULT_VOLUME = 0.7;

    var _initialised = false;
    var _slideMin;
    var _slideWidth;
    var _volumeLevel;
    var _onValueChangedCallback;

    // To give smooth UI feedback but avoiding clobbbering devices with volume requests,
    // we let the drag event happen naturally but debounce the volume change updates
    var _debouncedNotify = _.debounce(notifyChange, 100);

    init();

    function init() {
        if (_initialised) {
            return;
        }
        _volumeLevel = DEFAULT_VOLUME;
        _initialised = true;
        _slideWidth = $('#player-volume-bar-background').width();

        var player = $('#player');
        player.on('mousedown', '#player-volume-bar-head', onDragStart);
        player.on('click', '#player-volume-bar-background', onClickVolumeBar);
    }

    function setValue(value) {
        value = isNaN(value) ? 0 : value;
        _volumeLevel = Math.min(1, Math.max(0, value));
        var volumePercent = (_volumeLevel * 100) + '%';
        $('#player-volume-bar-head').css({left:volumePercent});
        $('#player-volume-bar-current').css({width:volumePercent});
    }

    function getValue() {
        return _volumeLevel;
    }

    function onClickVolumeBar(event) {
        // Firefox doesn't support offsetX
        // See http://bugs.jquery.com/ticket/8523
        // And http://stackoverflow.com/a/14872192/3252835
        var offsetX = (event.offsetX || event.pageX - $(event.target).offset().left);
        setValue(offsetX / _slideWidth);
        notifyChange();
    }

    function onDragStart(event) {
        event.preventDefault();
        $(document).on('mousemove', onDragMove);
        $(document).on('mouseup', onDragEnd);
        _slideMin = $('#player-volume-bar-background').offset().left - CURSOR_OFFSET;
    }

    function onDragMove(event) {
        var pos = event.clientX - _slideMin - CURSOR_OFFSET;
        setValue(pos / _slideWidth);
        _debouncedNotify();
    }

    function onDragEnd() {
        $(document).off('mousemove', onDragMove);
        $(document).off('mouseup', onDragEnd);
    }

    function notifyChange() {
        if (_onValueChangedCallback) {
            _onValueChangedCallback(_volumeLevel);
        }
    }

    function addUpdateListener(cb) {
        _onValueChangedCallback = cb;
    }

    return {
        setValue: setValue,
        getValue: getValue,
        addUpdateListener: addUpdateListener
    };
});

