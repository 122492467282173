//Index of UI modules which need initialising by app.js

define('webstore-client/ui/init',[
    'webstore-client/ui/shop-suggest',
    'webstore-client/ui/packshot',
    'webstore-client/ui/packshot-collection',
    'webstore-client/ui/basket',
    'webstore-client/ui/footer',
    'webstore-client/ui/playback',
    'webstore-client/ui/search',
    'webstore-client/ui/lazyload',
    'webstore-client/ui/notice',
    'webstore-client/ui/user-details',
    'webstore-client/ui/side-navigation',
    'webstore-client/ui/search/search-filters'
], function () {
});

