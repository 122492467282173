define('template-notification-basket',['handlebars'], function(Handlebars) {

return Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.shouldDisplay : depth0),{"name":"if","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"2":function(depth0,helpers,partials,data) {
    var stack1, alias1=helpers.helperMissing, alias2=this.escapeExpression;

  return "	<header class=\"notification-header\">\n		<strong class=\"notification-header-content\">"
    + alias2((helpers.translate || (depth0 && depth0.translate) || alias1).call(depth0,"Basket",{"name":"translate","hash":{},"data":data}))
    + "</strong>\n	</header>\n	<div class=\"notification-body\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.itemRemoved : depth0),{"name":"if","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.hasError : depth0),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":this.program(7, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.hasError : depth0),{"name":"unless","hash":{},"fn":this.program(15, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "		<div class=\"notification-item\">\n			<a class=\"notification-action-full\" href=\"/checkout\">"
    + alias2((helpers.translate || (depth0 && depth0.translate) || alias1).call(depth0,"Checkout",{"name":"translate","hash":{},"data":data}))
    + "</a>\n		</div>\n	</div>\n";
},"3":function(depth0,helpers,partials,data) {
    return "			<p class=\"basket-notice notification-feedback success\">"
    + this.escapeExpression((helpers.translate || (depth0 && depth0.translate) || helpers.helperMissing).call(depth0,"ItemRemoved",{"name":"translate","hash":{},"data":data}))
    + "</p>\n";
},"5":function(depth0,helpers,partials,data) {
    return "			<p class=\"basket-notice notification-feedback error\">"
    + this.escapeExpression((helpers.translate || (depth0 && depth0.translate) || helpers.helperMissing).call(depth0,"Error_Processing_Request",{"name":"translate","hash":{},"data":data}))
    + "</p>\n";
},"7":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "		<div class=\"basket-item\">\n			<a href=\""
    + alias3(((helper = (helper = helpers.releaseUrl || (depth0 != null ? depth0.releaseUrl : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"releaseUrl","hash":{},"data":data}) : helper)))
    + "\" class=\"basket-item-packshot-image\">\n				<img src=\""
    + alias3(((helper = (helper = helpers.packshotUrl || (depth0 != null ? depth0.packshotUrl : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"packshotUrl","hash":{},"data":data}) : helper)))
    + "\">\n			</a>\n\n			<div class=\"basket-item-contents\">\n				<a href=\""
    + alias3(((helper = (helper = helpers.releaseUrl || (depth0 != null ? depth0.releaseUrl : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"releaseUrl","hash":{},"data":data}) : helper)))
    + "\" class=\"basket-item-name\">"
    + alias3(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"name","hash":{},"data":data}) : helper)))
    + "</a>\n				<a href=\""
    + alias3(((helper = (helper = helpers.artistUrl || (depth0 != null ? depth0.artistUrl : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"artistUrl","hash":{},"data":data}) : helper)))
    + "\" class=\"basket-item-artist\">"
    + alias3(((helper = (helper = helpers.artistName || (depth0 != null ? depth0.artistName : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"artistName","hash":{},"data":data}) : helper)))
    + "</a>\n				<span class=\"basket-item-format\">"
    + alias3(((helper = (helper = helpers['package'] || (depth0 != null ? depth0['package'] : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"package","hash":{},"data":data}) : helper)))
    + "</span>\n				<strong class=\"basket-item-price\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.isVoucher : depth0),{"name":"if","hash":{},"fn":this.program(8, data, 0),"inverse":this.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "				</strong>\n			</div>\n\n			<form class=\"basket-item-form\" action=\"/basket/remove\" method=\"post\">\n				<input type=\"hidden\" name=\"itemId\" value=\""
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n				<button type=\"submit\" class=\"basket-item-remove\">&times; "
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias1).call(depth0,"Remove",{"name":"translate","hash":{},"data":data}))
    + "\n				</button>\n			</form>\n\n		</div>\n";
},"8":function(depth0,helpers,partials,data) {
    return "						"
    + this.escapeExpression((helpers.translate || (depth0 && depth0.translate) || helpers.helperMissing).call(depth0,"Voucher",{"name":"translate","hash":{},"data":data}))
    + "\n";
},"10":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.isFree : depth0),{"name":"if","hash":{},"fn":this.program(11, data, 0),"inverse":this.program(13, data, 0),"data":data})) != null ? stack1 : "");
},"11":function(depth0,helpers,partials,data) {
    return "							"
    + this.escapeExpression((helpers.translate || (depth0 && depth0.translate) || helpers.helperMissing).call(depth0,"FreePrice",{"name":"translate","hash":{},"data":data}))
    + "\n";
},"13":function(depth0,helpers,partials,data) {
    var helper;

  return "							"
    + this.escapeExpression(((helper = (helper = helpers.formattedPrice || (depth0 != null ? depth0.formattedPrice : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"formattedPrice","hash":{},"data":data}) : helper)))
    + "\n";
},"15":function(depth0,helpers,partials,data) {
    var helper, alias1=helpers.helperMissing, alias2=this.escapeExpression;

  return "		<div class=\"basket-subtotal\">\n			<p class=\"basket-subtotal-label\">"
    + alias2((helpers.translate || (depth0 && depth0.translate) || alias1).call(depth0,"OrderTotal",{"name":"translate","hash":{},"data":data}))
    + ":</p>\n			<strong class=\"basket-subtotal-amount\">"
    + alias2(((helper = (helper = helpers.formattedTotalPrice || (depth0 != null ? depth0.formattedTotalPrice : depth0)) != null ? helper : alias1),(typeof helper === "function" ? helper.call(depth0,{"name":"formattedTotalPrice","hash":{},"data":data}) : helper)))
    + "</strong>\n		</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div id=\"notification-basket\" class=\"notification basket is-hidden\">\n"
    + ((stack1 = helpers['with'].call(depth0,(depth0 != null ? depth0.basket : depth0),{"name":"with","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true})

});
