define('template-player-display',['handlebars'], function(Handlebars) {

return Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return "			<img src=\""
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.track : depth0)) != null ? stack1.image : stack1), depth0))
    + "\" alt=\""
    + ((stack1 = helpers['if'].call(depth0,((stack1 = (depth0 != null ? depth0.track : depth0)) != null ? stack1.title : stack1),{"name":"if","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\">\n";
},"2":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return alias2(alias1(((stack1 = (depth0 != null ? depth0.track : depth0)) != null ? stack1.artist : stack1), depth0))
    + " - "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.track : depth0)) != null ? stack1.title : stack1), depth0));
},"4":function(depth0,helpers,partials,data) {
    return "				"
    + this.escapeExpression((helpers.translate || (depth0 && depth0.translate) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.error : depth0),{"name":"translate","hash":{},"data":data}))
    + "\n";
},"6":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,((stack1 = (depth0 != null ? depth0.track : depth0)) != null ? stack1.title : stack1),{"name":"if","hash":{},"fn":this.program(7, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"7":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "				<a class=\"player-now-playing-title\" id=\"player-now-playing-title\" href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.track : depth0)) != null ? stack1.releaseUrl : stack1), depth0))
    + "\">\n					"
    + ((stack1 = helpers['if'].call(depth0,((stack1 = (depth0 != null ? depth0.track : depth0)) != null ? stack1.trackNumber : stack1),{"name":"if","hash":{},"fn":this.program(8, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.track : depth0)) != null ? stack1.title : stack1), depth0))
    + "\n				</a>\n				<span class=\"player-now-playing-by-artist-container\">\n					<span class=\"player-now-playing-by\">"
    + alias2((helpers.translate || (depth0 && depth0.translate) || helpers.helperMissing).call(depth0,"ProductBy",{"name":"translate","hash":{},"data":data}))
    + "</span>\n					<a class=\"player-now-playing-artist\" id=\"player-now-playing-artist\" href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.track : depth0)) != null ? stack1.artistUrl : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.track : depth0)) != null ? stack1.artist : stack1), depth0))
    + "</a>\n				</span>\n";
},"8":function(depth0,helpers,partials,data) {
    var stack1;

  return this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.track : depth0)) != null ? stack1.trackNumber : stack1), depth0))
    + ". ";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.escapeExpression;

  return "<div id=\"player-display\" class=\"player-display\">\n	<div class=\"player-now-playing\" id=\"player-now-playing\">\n		<a id=\"player-artwork\" class=\"player-artwork\" href=\""
    + alias1(this.lambda(((stack1 = (depth0 != null ? depth0.track : depth0)) != null ? stack1.releaseUrl : stack1), depth0))
    + "\">\n"
    + ((stack1 = helpers['if'].call(depth0,((stack1 = (depth0 != null ? depth0.track : depth0)) != null ? stack1.image : stack1),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "		</a>\n		<div class=\"player-now-playing-text-container\">\n			<div class=\"player-now-playing-text\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.error : depth0),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "			</div>\n		</div>\n	</div>\n	<div class=\"player-progress\">\n		<div id=\"player-progress-bar-container\" class=\"player-progress-bar-container\">\n			<div class=\"player-progress-bar-background\"></div>\n			<div class=\"player-progress-bar-buffered\" id=\"player-progress-bar-buffered\">\n				<div class=\"player-progress-bar-current\" id=\"player-progress-bar-current\"></div>\n			</div>\n		</div>\n	</div>\n    <div class=\"player-progress-time\" id=\"player-progress-time\">--:--</div>\n    <div class=\"player-volume-mute-status\">\n        <button class=\"player-volume-mute\" id=\"player-mute-toggle-btn\">"
    + alias1((helpers.translate || (depth0 && depth0.translate) || helpers.helperMissing).call(depth0,"PlayerVolumeMute",{"name":"translate","hash":{},"data":data}))
    + "</button>\n    </div>\n    <div id=\"player-volume-bar-container\" class=\"player-volume-bar-container\">\n        <div class=\"player-volume-bar-background\" id=\"player-volume-bar-background\">\n            <div class=\"player-volume-bar-current\" id=\"player-volume-bar-current\">\n            </div>\n        </div>\n        <div class=\"player-volume-bar-head\" id=\"player-volume-bar-head\"></div>\n    </div>\n</div>\n";
},"useData":true})

});
