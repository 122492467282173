define('webstore-client/ui/playback/playlist-retriever',[
    'jquery',
    'lodash',
    'webstore-client/utilities/ajax',
    'webstore-client/utilities/page-type',
    'webstore-client/analytics/track',
    'debug'
], function ($, _, ajax, getPageType, track, debug) {
    var currentRequest;

    function cancelPending() {
        if (currentRequest) {
            currentRequest.abort();
            currentRequest = undefined;
        }
    }

    function titleWithVersion(res) {
        var version = res.VersionName ? ' (' + res.VersionName + ')' : '';
        return res.Title + version;
    }

    function buildReleasePlaylistUrl(releaseId) {
        return '/playlist/release/' + releaseId;
    }

    function buildYourMusicReleasePlaylistUrl(releaseId) {
        return '/playlist/yourmusic-release/' + releaseId;
    }

    function buildSingleTrackPlaylistUrl(trackId) {
        return '/playlist/track/' + trackId;
    }

    function buildArtistTopTracksPlaylistUrl(artistId) {
        return '/playlist/artist-top-tracks/' + artistId;
    }

    function getPlaylist(description, url, cb) {
        cancelPending();
        currentRequest = ajax({
            url: url,
            background: true
        })
        .done(function (res) {
            var playlist = res.playlist;
            playlist.meta = res.meta;
            currentRequest = undefined;

            return cb(playlist);
        })
        .fail(function (jqXhr) {
            currentRequest = undefined;

            debug.ajaxError(description + ' playlist request failed', jqXhr, {
                url: url
            });
        });
    }

    function getPlaylistForRelease(releaseId, cb) {
        var url = buildReleasePlaylistUrl(releaseId);
        getPlaylist('Release', url, cb);
    }

    function getPlaylistForSingleTrack(trackId, cb) {
        var url = buildSingleTrackPlaylistUrl(trackId);
        getPlaylist('Track', url, cb);
    }

    function getPlaylistForYourMusicRelease(releaseId, cb) {
        var url = buildYourMusicReleasePlaylistUrl(releaseId);
        getPlaylist('Your music release', url, cb);
    }

    function getPlaylistForArtistTopTracks(artistId, cb) {
        var url = buildArtistTopTracksPlaylistUrl(artistId);
        getPlaylist('Artist top tracks', url, cb);
    }

    return {
        forTrack: getPlaylistForSingleTrack,
        forRelease: getPlaylistForRelease,
        forYourMusicRelease: getPlaylistForYourMusicRelease,
        forArtistTopTracks: getPlaylistForArtistTopTracks,
        abort: cancelPending
    };
});

