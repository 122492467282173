define('webstore-client/ui/footer',['jquery'], function ($) {
    var initialised = false;
    var $buttonContainer;

    function toggle(e) {
        if (!$buttonContainer) {
            $buttonContainer = $(this).parent().parent();
        }

        $buttonContainer.toggleClass('is-selected');

        e.stopPropagation();

        if ($buttonContainer.hasClass('is-selected')) {
            $('body').on('click', toggle);
        } else {
            $('body').off('click', toggle);
        }
    }

    if (!initialised) {
        $('.country-selection-instruction').click(toggle);

        initialised = true;
    }

});

