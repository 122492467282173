define('webstore-client/utilities/page-type',[
    'jquery'
], function ($) {

    return function getPageType() {
        return $('meta[property="7d:page-type"]').attr('content') || 'unknown';
    };

});

