define('webstore-client/ui/playback/playlist-manager',[
    'lodash',
    'webstore-client/ui/playback/playlist-retriever',
    'webstore-client/analytics/track'
], function (_, loadPlaylist, analytics) {

    var PLAYLIST_TYPE_RELEASE = 'release';
    var PLAYLIST_TYPE_SINGLE_TRACK = 'single-track';
    var PLAYLIST_TYPE_YOUR_MUSIC = 'yourmusic-release';
    var PLAYLIST_TYPE_ARTIST_TRACKS = 'artist-top-tracks';

    var ANALYTICS_TRACK = 'ANALYTICS_TRACK';
    var ANALYTICS_RELEASE = 'ANALYTICS_RELEASE';
    var ANALYTICS_ARTIST = 'ANALYTICS_ARTIST';

    var _position;
    var _playlist;
    var _playlistMeta;
    var _playlistUpdateHandler;
    var _nextTrackHandler;
    var _previousTrackHandler;
    var _positionUpdateHandler;

    function onPlaylistUpdate(cb) {
        _playlistUpdateHandler = cb;
    }

    function onPlaylistPositionUpdate(cb) {
        _positionUpdateHandler = cb;
    }

    function onPlaylistNextTrack(cb) {
        _nextTrackHandler = cb;
    }

    function onPlaylistPreviousTrack(cb) {
        _previousTrackHandler = cb;
    }

    function getQueue() {
        return {
            playlist: _playlist,
            position: _position,
            meta: _.cloneDeep(_playlistMeta)
        };
    }

    function playPlaylist(playlistData) {
        var type = playlistData.type;
        var trackId = playlistData.trackId;
        var releaseId = playlistData.releaseId;
        var artistId = playlistData.artistId;

        switch (type) {
            case PLAYLIST_TYPE_ARTIST_TRACKS:
                playArtistTopTrack(trackId, artistId);
                break;

            case PLAYLIST_TYPE_YOUR_MUSIC:
                if (trackId === undefined) {
                    playYourMusicRelease(releaseId);
                } else {
                    playYourMusicTrack(trackId, releaseId);
                }
                break;

            case PLAYLIST_TYPE_SINGLE_TRACK:
                playSingleTrack(trackId, releaseId);
                break;

            default:
                if (trackId === undefined) {
                    playRelease(releaseId);
                } else {
                    playTrack(trackId, releaseId);
                }
                break;
        }
    }

    function playSingleTrack(trackId, releaseId) {
        if (playlistIsActive(PLAYLIST_TYPE_RELEASE, releaseId)) {
            updatePlaylist({
                position:0,
                analytics:ANALYTICS_TRACK
            });
        } else {
            loadPlaylist.forTrack(trackId, function (playlist) {
                updatePlaylist({
                    playlist:playlist,
                    position:0,
                    analytics:ANALYTICS_TRACK
                });
            });
        }
    }

    function playTrack(trackId, releaseId) {
        if (playlistIsActive(PLAYLIST_TYPE_RELEASE, releaseId)) {
            updatePlaylist({
                position:getTrackPosition(_playlist, trackId),
                analytics:ANALYTICS_TRACK
            });
        } else {
            loadPlaylist.forRelease(releaseId, function (playlist) {
                updatePlaylist({
                    playlist:playlist,
                    position:getTrackPosition(playlist, trackId),
                    analytics:ANALYTICS_TRACK
                });
            });
        }
    }

    function playYourMusicTrack(trackId, releaseId) {
        if (playlistIsActive(PLAYLIST_TYPE_YOUR_MUSIC, releaseId)) {
            updatePlaylist({
                position:getTrackPosition(_playlist, trackId),
                analytics:ANALYTICS_TRACK
            });
        } else {
            loadPlaylist.forYourMusicRelease(releaseId, function (playlist) {
                updatePlaylist({
                    playlist:playlist,
                    position:getTrackPosition(playlist, trackId),
                    analytics:ANALYTICS_TRACK
                });
            });
        }
    }

    function playRelease(releaseId) {
        if (playlistIsActive(PLAYLIST_TYPE_RELEASE, releaseId)) {
            updatePlaylist({
                analytics:ANALYTICS_RELEASE
            });
        } else {
            loadPlaylist.forRelease(releaseId, function (playlist) {
                updatePlaylist({
                    playlist:playlist,
                    position:0,
                    analytics:ANALYTICS_RELEASE
                });
            });
        }
    }

    function playYourMusicRelease(releaseId) {
        if (playlistIsActive(PLAYLIST_TYPE_YOUR_MUSIC, releaseId)) {
            updatePlaylist({
                analytics:ANALYTICS_RELEASE
            });
        } else {
            loadPlaylist.forYourMusicRelease(releaseId, function (playlist) {
                updatePlaylist({
                    playlist:playlist,
                    position:0,
                    analytics:ANALYTICS_RELEASE
                });
            });
        }
    }

    function playArtistTopTrack(trackId, artistId) {
        if (playlistIsActive(PLAYLIST_TYPE_ARTIST_TRACKS, artistId)) {
            updatePlaylist({
                position:getTrackPosition(_playlist, trackId),
                analytics:ANALYTICS_ARTIST
            });
        } else {
            loadPlaylist.forArtistTopTracks(artistId, function (playlist) {
                updatePlaylist({
                    playlist:playlist,
                    position:getTrackPosition(playlist, trackId),
                    analytics:ANALYTICS_ARTIST
                });
            });
        }
    }

    function updatePlaylist(updates) {
        var isNextPosition = updates.position === _position + 1;
        var isPreviousPosition = updates.position === _position - 1;

        _position = getValueIfDefined(updates.position, _position);
        _playlist = getValueIfDefined(updates.playlist, _playlist);
        _playlistMeta = _playlist.meta;

        trackPlaylistUpdate(updates.analytics);

        if (updates.playlist !== undefined) {
            safeNotifyHandler(_playlistUpdateHandler);
        } else if (updates.position !== undefined) {
            if (isNextPosition) {
                safeNotifyHandler(_nextTrackHandler);
            } else if (isPreviousPosition) {
                safeNotifyHandler(_previousTrackHandler);
            } else {
                safeNotifyHandler(_positionUpdateHandler);
            }
        }
    }

    function next() {
        if (_position < _playlist.length - 1) {
            _position++;
            safeNotifyHandler(_nextTrackHandler);
        }
    }

    function previous() {
        if (_position >= 1) {
            _position--;
            safeNotifyHandler(_previousTrackHandler);
        }
    }

    function clearPlaylist() {
        _position = 0;
        _playlist = [];
        _playlistMeta = {};
    }

    function safeNotifyHandler(handler) {
        if (handler !== undefined) {
            handler();
        }
    }

    function abortLoad() {
        loadPlaylist.abort();
    }

    function getTrackPosition(playlist, trackId) {
        return _.findIndex(playlist, function isTrack(track) {
            return track.trackId === trackId;
        });
    }

    function playlistIsActive(type, id) {
        if (_playlistMeta === undefined) {
            return false;
        } else {
            return _playlistMeta.type === type && +_playlistMeta.id === +id;
        }
    }

    function getValueIfDefined(value, defaultValue) {
        return value !== undefined ? value : defaultValue;
    }

    function trackPlaylistUpdate(updateType) {
        if (updateType === undefined) { return false; }
        switch (updateType) {
            case ANALYTICS_TRACK: return analyticsPlayTrackEvent(_playlist[_position]);
            case ANALYTICS_RELEASE: return analyticsPlayReleaseEvent(_playlist);
            case ANALYTICS_ARTIST: return analyticsPlayArtistTopTrackEvent(_playlist[_position]);
        }
    }

    function analyticsPlayTrackEvent(playlistTrack) {
        if (!playlistTrack) { return false; }
        analytics.event('Previews', 'Play One', playlistTrack.artist + ' - ' + playlistTrack.title);
    }

    function analyticsPlayReleaseEvent(playlist) {
        analytics.event('Previews', 'Play All', playlist.meta.artist + ' - ' + playlist.meta.title);
    }

    function analyticsPlayArtistTopTrackEvent(playlistTrack) {
        analytics.event('Previews', 'Play Artist Top Track', playlistTrack.artist + ' - ' + playlistTrack.title);
    }

    return {
        playPlaylist: playPlaylist,
        getQueue: getQueue,
        playlistIsActive: playlistIsActive,
        next: next,
        previous: previous,
        clearPlaylist: clearPlaylist,
        abortLoad: abortLoad,

        onPlaylistUpdate: onPlaylistUpdate,
        onPlaylistPositionUpdate: onPlaylistPositionUpdate,
        onPlaylistNextTrack: onPlaylistNextTrack,
        onPlaylistPreviousTrack: onPlaylistPreviousTrack
    };
});

