/*
 * Search url builder 7digital.com
 * (c) 7digital.com
 */
define('webstore-client/ui/search/url-builder',[
    'lodash',
    'webstore-client/lib/jsurl'
], function (_, Url) {

    function fullSearchResults(searchUrl, searchTerm, source, packages) {
        var fullSearchUrl = new Url(searchUrl);

        fullSearchUrl.query.q = searchTerm;
        if (source) {
            fullSearchUrl.query.src = source;
        }

        // make the URL relative
        fullSearchUrl.host = '';
        fullSearchUrl.protocol = '';
        fullSearchUrl.port = '';

        return fullSearchUrl.toString();
    }

    return {
        fullSearchResults: fullSearchResults
    };
});

