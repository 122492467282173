/*
 * anchorchecker module for 7digital.com
 * Determine if an anchor supports partial page loading
 * (c) 7digital.com
 */

define('webstore-client/contentloading/anchorchecker',['debug',
        'webstore-client/utilities/history'
    ],
    function (debug, history) {
        // [0] = whole match, [1] = protocol, [2] = domain, [3] = port
        var urlRegExp = /^([\w.+-]+:)?(?:\/\/([^\/?#:]*)(?::(\d+)|)|)/;

        function hrefSupportsPartialPage(href) {
            return href !== undefined && href[0] !== '#';
        }

        function attributesSupportPartialPage(anchor) {
            var supportsPartial = anchor.data('allow-partial');

            if (supportsPartial !== undefined) { return supportsPartial; }

            if (anchor.attr('rel') === 'nofollow') { return false; }

            return true;
        }

        // Taken from jQuery AJAX source
        function isCrossDomain(requestedUrl) {
            var currentUrl, currentUrlParts, requestedUrlParts, result;

            currentUrl = history.currentUrl();

            currentUrlParts = urlRegExp.exec(currentUrl.toLowerCase());
            requestedUrlParts = urlRegExp.exec(requestedUrl.toLowerCase());

            requestedUrlParts[1] = requestedUrlParts[1] || currentUrlParts[1];
            requestedUrlParts[2] = requestedUrlParts[2] || currentUrlParts[2];
            requestedUrlParts[3] = requestedUrlParts[3] || currentUrlParts[3];

            result = !!(currentUrlParts && requestedUrlParts &&
                (currentUrlParts[1] !== requestedUrlParts[1] || currentUrlParts[2] !== requestedUrlParts[2] ||
                (currentUrlParts[3] || (currentUrlParts[1] === 'http:' ? 80 : 443)) !==
                (requestedUrlParts[3] || (requestedUrlParts[1] === 'http:' ? 80 : 443)))
            );

            return result;
        }

        function supportsPartialPage(anchor) {
            if (!anchor) { return false; }

            var href = anchor.attr('href');

            return hrefSupportsPartialPage(href)
                && attributesSupportPartialPage(anchor)
                && !isCrossDomain(href);
        }

        return {
            supportsPartialPage: supportsPartialPage,
            isCrossDomain: isCrossDomain
        };
    }
);

