define('webstore-client/pages/release',[
    'lodash',
    'jquery',
    'client-config',
    'webstore-client/utilities/get-relevant-buttons',
    'webstore-client/utilities/ajax',
    'translate',
    'webstore-client/locker/ownership'
], function (_, $, config, getRelevantButtons, ajax, translate, availability) {
    var animationTime = (400 * 2);

    function fetchLocker() {
        if (!availability.hasData()) {
            availability.fetchLockerData()
                .done(setReleaseData).then(onFetchLockerSuccess)
                .fail(onFetchLockerError);
        } else {
            setReleaseData();
            onFetchLockerSuccess();
        }
    }

    function setReleaseData(data) {
        var availablePackages = JSON.parse($('.release')
            .attr('data-available-packages'));

        availability.setReleaseData(availablePackages);
    }

    function onFetchLockerSuccess() {
        if (config.isOnWrongShop) {
            return;
        }

        updateTrackButtons(availability);
        disableOwnedPackagesInFormatSelector(availability);

        return onFormatSelectorChange();
    }

    function updateTrackButtons(availability) {
        // Update prices for all track buy buttons
        $('[data-trackid]').each(function () {
            var trackId = $(this).data('trackid');
            var ownedTrackPackages = availability.ownedTrackPackages(trackId);
            var button = $(this).find('button');

            _.each(ownedTrackPackages, function (packageId) {
                button.attr('data-package-price-' + packageId, 'false');
            });
        });
    }

    function disableOwnedPackagesInFormatSelector(availability) {
        var packageIds = availability.ownedReleasePackages();
        var yourMusicLink = $('form.release-purchase').data('in-locker-link');

        var anyPackagesOnSale = $('.package-info-type.is-reduced').length > 0;
        // Update prices for release format selector
        _.forOwn(packageIds, function (packageId) {
            var $youOwnThis = $('<a>', {
                'href': yourMusicLink,
                'class': 'in-locker is-compact',
                text: translate('YouOwnThis')
            });

            var $row = $('#release-format-row-' + packageId);
            $row.find('input').attr('disabled', true);

            // Replace price with You Own This, remove any sale labels
            if (anyPackagesOnSale) {
                $row.find('.release-format-selector-price').html('');
                $row.find('.release-format-selector-offer-wrapper').html($youOwnThis);
            } else {
                $row.find('.release-format-selector-price').html($youOwnThis);
            }
        });
    }

    function onFetchLockerError() {
        return onFormatSelectorChange();
    }

    function createYouOwnThisLink($parent) {
        var translatedText = $parent.attr('data-in-locker-text');
        var link = $parent.attr('data-in-locker-link');

        $parent.prepend($('<a>', { href: link,
                                    text: translatedText,
                                    'class': 'in-locker'}));
    }

    function toggleOwnedState($el, isOwned) {
        var $parent = $el
            .prop('disabled', isOwned)
            .parents('td') .toggleClass('is-owned', isOwned)
            .toggleClass('is-not-owned', !isOwned);

        if (isOwned) {
            if ($parent.find('.in-locker').length === 0) {
                createYouOwnThisLink($parent);
            }
        } else {
            $parent.find('.in-locker').remove();
        }
    }

    function updateButtonPrice($el, packageType) {
        var price = $el.attr('data-package-price-' + packageType);

        if (price !== 'false') {
            // Update the hidden form field containing the package id
            $el.prevAll('input[name="packageId"]').val(packageType);

            // Triggers the pulse animation
            $el.addClass('is-highlighted').html(price);

            toggleOwnedState($el, false);
        } else {
            toggleOwnedState($el, true);
        }
    }

    function clearAnimation() {
        $('.release-track .is-highlighted').removeClass('is-highlighted');
    }

    function onFormatSelectorChange(event) {
        var packageType = $('.release-info input[name=packageId]:checked').val();

        $('[data-package-buy-button]').each(function () {
            updateButtonPrice($(this), packageType);
        });

        var $checkout = $('.release-purchase button[type=submit]');
        if ($('.release-purchase input:checked').is(':disabled')) {
            $checkout.prop('disabled', true);
        } else {
            $checkout.prop('disabled', false);
        }

        setTimeout(clearAnimation, animationTime);
    }

    return function initFormatSelector() {
        if (config.user.isSignedIn && !config.isOnWrongShop) {
            fetchLocker();
        }

        $('input[name=packageId]').on('change', onFormatSelectorChange);
    };
});

