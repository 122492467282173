/*
 * page updater - update page with partial html content from an ajax response
 * Functionality for 7digital.com
 * (c) 7digital.com
 */

define('webstore-client/contentloading/pageupdater',[
    'jquery',
    'lodash',
    'webstore-client/utilities/urlhelpers',
    'webstore-client/ui/side-navigation',
    'webstore-client/ui/lazyload'
], function ($, _, urlhelpers, sideNav, lazyload) {

    function updatePageContent(pageContent) {
        sideNav.hide();
        $('.body-container').replaceWith(pageContent.html());
        lazyload.revalidate();
    }

    function updateTitle(headResponse) {
        var title = $.trim(headResponse.find('title').text());
        $('title').html(title);
    }

    function updateCanonicalLink(headResponse) {
        var newCanon = headResponse.find('link[rel="canonical"]');
        var oldCanon = $.find('link[rel="canonical"]');

        if (oldCanon.length === 0) {
            $('head').append('<link rel="canonical" href="' + newCanon.attr('href') + '">');
        } else if (newCanon.length === 0) {
            $(oldCanon).remove();
        } else {
            $(oldCanon).attr('href', newCanon.attr('href'));
        }
    }

    function updateMetadata(head, metaProps) {
        $('[data-dynamic=true]', head).remove();
        metaProps.insertAfter($('meta:last', head));
    }

    function updateNotices(parent, notices) {
        $('[data-dynamic=true]', parent).remove();
        notices.insertBefore($('.body-container', parent));
    }

    function updateReturnUrls() {
        var path = urlhelpers.getPathName();
        var returnUrl = path + urlhelpers.getQueryStr();
        if (path !== '/signup' && path !== '/signin') {
            $('.user-sign-in-link').attr('href', '/signin?return=' + returnUrl);
            $('.user-sign-up-link').attr('href', '/signup?return=' + returnUrl);
        }
    }

    function updateSideNav() {
        var path = urlhelpers.getPathName();
        var isCheckout = path === '/checkout';
        if (!isCheckout) {
            $('body').removeClass('hides-menu');
        }
    }

    function updatePage(pageData, scroll, callback) {
        try {
            var resultDom = $(pageData);
            var pageContent = resultDom.find('#pageContent');
            var headResponse = resultDom.find('#headResponse');
            var metaProps = resultDom.find('#metaProps').children();
            var notices = resultDom.find('#notices').children();
            var returnUrl = window.location.href;
            var fragmentNodeOffset;

            if (pageContent.length === 0) {
                return callback(new Error('Response page content had length 0'));
            }
            if (headResponse.length === 0) {
                return callback(new Error('Response head had length 0'));
            }

            updatePageContent(pageContent);
            updateTitle(headResponse);
            updateCanonicalLink(headResponse);
            updateMetadata($('head'), metaProps);
            updateNotices($('.page-container'), notices);
            updateReturnUrls(returnUrl);
            updateSideNav();

            // History.JS poorly handles path + fragment changes - this fixes
            // the scroll position in that case.
            if (document.location.hash.length !== 0) {
                fragmentNodeOffset = $(document.location.hash).offset();
                if (fragmentNodeOffset) {
                    scroll = fragmentNodeOffset.top;
                }
            }

            $(window).scrollTop(scroll);

            return callback();
        } catch (e) {
            return callback(e);
        }
    }

    return {
        test: {
            updateMetadata: updateMetadata,
            updateNotices: updateNotices
        },
        updateReturnUrls: updateReturnUrls,
        updatePage: updatePage
    };
});

