define('webstore-client/pages/sign-in',[
    'jquery',
    'webstore-client/ui/playback/controller'
], function ($, playbackController) {

    return function init() {
        var accountSignInForm = $('#account-form-sign-in');
        accountSignInForm.on('submit', loginHandler);
    };

    function loginHandler(event) {
        // Clearing playlist will synchronously stop a Cast session
        // and the form submission will continue normally
        playbackController.clearPlaylist();
    }
});

