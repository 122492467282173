/*
 * handlebars-helpers module for 7digital.com
 * (c) 7digital.com
 */

define('webstore-client/utilities/handlebars-helpers',[
    'lodash',
    'handlebars',
    'translate',
    'client-config'
], function (_, Handlebars, translate, clientConfig) {
    var initialised = false;

    function translateHelper() {
        var args = _.toArray(arguments);
        var key = args.shift();
        var translation = translate(key);

        if (translation === undefined) {
            return clientConfig.isDevelopment ? '[' + key + ']' : '';
        }

        if (args[args.length - 1] === undefined) {
            args.pop(); // Discard shop
        }

        if (args.length > 0) {
            return translation.replace(/\{(\d)\}/g, function (match, num) {
                return (args[num] !== undefined) ? args[num] : match;
            });
        }

        return translation;
    }

    function foreachHelper(arr, options) {
        return _.map(arr, function (item, index) {
            item.$index = index;
            item.$count = index + 1;
            item.$first = index === 0;
            item.$second = index === 1;
            item.$last = index === arr.length - 1;
            return options.fn(item);
        }).join('');
    }

    function formatPackagesHelper(release) {
        var tracks = _(release.discs).pluck('tracks').flatten();
        return JSON.stringify(tracks.map(function (t) {
            if (!t.id) {
                return undefined;
            }
            return {
                trackId: t.id,
                formats: t.formats,
                packages: _(t.packages).map(function (p) {
                    if (!p.formats) { return undefined; }
                    return {
                        id: p.id,
                        formats: _(p.formats.format).pluck('id').map(Number).value()
                    };
                }).compact().value()
            };
        }).compact().value());
    }

    function lowerCaseHelper(str) {
        if (str && typeof str === 'string') {
            return str.toLowerCase();
        }
    }

    function jsonHelper(obj) {
        return JSON.stringify(obj);
    }

    function newRelicTimingHeaderHelper() {
    }

    if (!initialised) {
        Handlebars.registerHelper('newRelicTimingHeader', newRelicTimingHeaderHelper);
        Handlebars.registerHelper('translate', translateHelper);
        Handlebars.registerHelper('foreach', foreachHelper);
        Handlebars.registerHelper('lowerCase', lowerCaseHelper);
        Handlebars.registerHelper('json', jsonHelper);
        Handlebars.registerHelper('formatPackages', formatPackagesHelper);
        initialised = true;
    }

    return {
        translateHelper: translateHelper,
        foreachHelper: foreachHelper,
        lowerCaseHelper: lowerCaseHelper,
        formatPackages: formatPackagesHelper,
        jsonHelper: jsonHelper
    };
});

