define('webstore-client/utilities/history',[
    'jquery',
    'lodash',
    'webstore-client/lib/history',
    'webstore-client/utilities/location',
    'webstore-client/ui/side-navigation',
    'debug'
], function ($, _, _history, location, sideNav, debug) {
    var historyIsSupported = Boolean(window.history && window.history.pushState);
    var urlChangeInProgess = false;
    var disableCallback = false;

    function tryGetHash(url) {
        var hashIndex = url.indexOf('#');
        if (hashIndex !== -1) {
            var hash = url.substring(hashIndex + 1, url.length);
            return hash;
        }
    }

    function makeWrappedStateChangeCallback(callback, onceOnly) {
        onceOnly = onceOnly || false;

        return function wrapped() {
            if (!disableCallback) {
                var state = _history.getState();
                var previousState = getPreviousState();

                // Try to detect if replace state caused the state change event and
                // intercept it to prevent a partial page AJAX request for the
                // current page.
                if (previousState.url === state.url) { return; }

                //Unbind self if onceOnly flag is set
                if (onceOnly) {
                    //_history.Adapter does not expose "one" at this stage,
                    //so we have to call the underlying jquery one method. Bit of
                    //an implementation leak, but no way around it at present
                    //(there isn't even an "unbind" exposed)
                    $(window).unbind('statechange', wrapped);
                }

                urlChangeInProgess = true;
                return callback(state.url, state.data);
            }
        };
    }

    // Fires on "full page" change only (not anchors)
    function onUrlChange(callback) {
        var wrapped = makeWrappedStateChangeCallback(callback);
        _history.Adapter.bind(window, 'statechange', wrapped);
    }

    // Fires once only on the next "full page" change
    function onUrlChangeOnce(callback) {
        var wrapped = makeWrappedStateChangeCallback(callback, true);
        _history.Adapter.bind(window, 'statechange', wrapped);
    }

    function urlChangeHandled() {
        debug.log('Url change handled');
        urlChangeInProgess = false;
    }

    function urlChangeHandlingFailed() {
        debug.log('Url change failed, reverting');

        disableCallback = true;
        //This leaves a dead 'forward' state in the browser which is
        //inaccessible, but there is no way to actually delete states.
        //Possibly fix by loading in error content into the page instead?
        _history.back();
        disableCallback = false;

        urlChangeInProgess = false;
    }

    function pushUrl(url) {
        var hash, newState;
        var currentPageTitle = document.title;

        if (historyIsSupported) {
            hash = tryGetHash(url);
            url = hash ? url.replace('#' + hash, '') : url;
            newState = {
                scrollTop: 0,
                hash: hash
            };

            //Avoid 'Loading...' stuck title change if following link to the page you're already on
            if (_history.getFullUrl(url) !== _history.getFullUrl(currentUrl())) {
                storeScrollPositionOfCurrentPage();
                debug.log('Pushstate in pushUrl:', url, newState);
                if (urlChangeInProgess) {
                    return _history.replaceState(newState, currentPageTitle, url);
                } else {
                    return _history.pushState(newState, currentPageTitle, url);
                }
            } else {
                sideNav.hide();
                debug.log('Tried to pushstate to same url, aborting');
                return false;
            }
        }

        location.href = url;
    }

    function back() { return _history.back(); }

    function currentUrl() {
        var state;
        if (urlChangeInProgess) {
            state = getPreviousState();
        } else {
            state = _history.getState();
        }
        return decodeURIComponent(state.url);
    }

    function getPreviousState() {
        return _history.getStateByIndex(-2);
    }

    function storeScrollPositionOfCurrentPage() {
        var historyState = _history.getState(),
            hash = tryGetHash(historyState.url),
            currentPageTitle = document.title;

        historyState.data.scrollTop = $(window).scrollTop();
        historyState.data.hash = historyState.data.hash || hash;

        debug.log('Storing scroll position for current page', historyState.url,
            historyState.data);
        _history.replaceState(historyState.data, currentPageTitle,
            historyState.url);
    }

    function getHash() { return location.hash; }

    function pushHash(hash) { location.hash = hash; }

    function onHashChange(callback) {
        $(window).on('hashchange', function (e) {
            debug.log('hashchange');
            callback(getHash());
        });
    }

    function init() {
    }

    return {
        init: init,
        onUrlChange: onUrlChange,
        onUrlChangeOnce: onUrlChangeOnce,
        urlChangeHandled: urlChangeHandled,
        urlChangeHandlingFailed: urlChangeHandlingFailed,
        pushUrl: pushUrl,
        back: back,
        currentUrl: currentUrl,
        getHash: getHash,
        pushHash: pushHash,
        onHashChange: onHashChange,
        isSupported: historyIsSupported
    };
});

