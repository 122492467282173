// The cast sender will try (and fail) to load the desired API from every installed
// Chrome extension until it finds the right one. This can result in several error logs appearing
// in the browser console. Apparently this is known and won't be fixed: http://stackoverflow.com/a/26095117
(function () {
    define('webstore-client/ui/playback/player/google-cast/cast-detection',[
        'debug',
        'webstore-client/ui/playback/player/google-cast/google-cast-proxy',
        'client-config'
    ], function (debug, googleCast, config) {
        var _chrome = googleCast.chrome;
        var _initialized = false;
        var _deviceIsAvailable;
        var _onDeviceAvailabilityCallback;
        var _existingSession;

        init();

        function init() {
            if (_initialized) {
                return;
            }

            _initialized = true;

            if (_chrome === undefined || config.googleCastID === undefined) {
                // Non Chrome Browser or Cast Application not defined/enabled in webstore
                _deviceIsAvailable = false;
            } else if (_chrome.cast !== undefined && _chrome.cast.isAvailable) {
                // Cast library and extension are loaded and ready
                initializeCastApi();
            } else {
                // Else wait for Cast availability
                googleCast.onCastAPIavailable(initializeCastApi, onAPIerror);
            }
        }

        function onAPIerror(errorInfo) {
            debug.error(errorInfo);
            _deviceIsAvailable = false;
        }

        function onDeviceAvailability(cb) {
            _onDeviceAvailabilityCallback = cb;
            if (_deviceIsAvailable !== undefined) {
                _onDeviceAvailabilityCallback(_deviceIsAvailable);
            }
        }

        function getExistingSession() {
            return _existingSession;
        }

        function initializeCastApi() {
            var sessionRequest = new _chrome.cast.SessionRequest(config.googleCastID);
            var apiConfig = new _chrome.cast.ApiConfig(
                sessionRequest,
                sessionListener,
                receiverListener,
                _chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
                _chrome.cast.DefaultActionPolicy.CREATE_SESSION
            );

            _chrome.cast.addReceiverActionListener(receiverActionListener);
            _chrome.cast.initialize(apiConfig, onInitSuccess, onError);
        }

        function onInitSuccess() {
            // noop
        }

        function onError(error) {
            debug.error(error);
        }

        function sessionListener(session) {
            _existingSession = session;
        }

        function receiverActionListener(receiver, action) {
            if (action === _chrome.cast.ReceiverAction.STOP) {
                _existingSession = undefined;
            }
        }

        function receiverListener(availability) {
            _deviceIsAvailable = (availability === _chrome.cast.ReceiverAvailability.AVAILABLE);
            if (_onDeviceAvailabilityCallback !== undefined) {
                _onDeviceAvailabilityCallback(_deviceIsAvailable);
            }
        }

        return {
            onDeviceAvailability: onDeviceAvailability,
            getExistingSession: getExistingSession
        };
    });
})();

