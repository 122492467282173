define('webstore-client/ui/playback/player/event-handler',[
    'jquery',
    'lodash',
    'webstore-client/ui/playback/controller',
    'webstore-client/ui/playback/player/display',
    'webstore-client/analytics/track',
    'webstore-client/ui/playback/player/volume-slider'
], function ($, _, controller, playerDisplay, track, volume) {
    var initialised = false;

    if (!initialised) {
        controller.setSelectors({
            root: '#player'
        });

        controller.onWakeUp(playerDisplay.sync);
        controller.onStateChange(playerDisplay.sync);
        controller.onProgress(playerDisplay.syncRemainingTime);
        controller.onPlaylistEnded(playerDisplay.hide);
        controller.onCastDeviceStatusChange(playerDisplay.syncCastDeviceStatus);
        controller.onMuteStatusChange(playerDisplay.setMuteStatus);
        controller.onVolumeChange(volume.setValue);

        volume.addUpdateListener(controller.setVolume);

        $('#player-play-pause').on('click', function () {
            if (controller.isLoading()) {
                controller.abortLoad();
                track.event('Player', 'Interaction', 'Abort');
            } else if (controller.isPlaying()) {
                controller.pause();
                track.event('Player', 'Interaction', 'Pause');
            } else {
                controller.resume();
                track.event('Player', 'Interaction', 'Resume');
            }
        });

        $('#player-next').on('click', function () {
            controller.next();
            track.event('Player', 'Interaction', 'Next');
        });

        $('#player-previous').on('click', function () {
            controller.previous();
            track.event('Player', 'Interaction', 'Previous');
        });

        $('#player-close').on('click', function () {
            track.event('Player', 'Interaction', 'Close');

            if (controller.isLoading()) {
                controller.abortLoad();
                track.event('Player', 'Interaction', 'Abort');
            } else if (controller.isPlaying()) {
                controller.stop();
            }
            controller.clearPlaylist();

            playerDisplay.hide();
        });

        $('#player-artwork').on('click', function () {
            track.event('Player', 'Interaction', 'Artwork Click');
        });

        $('#player-cast-icon-button').on('click', function () {
            controller.initCastDevice();
        });

        $('#player').on('click', '#player-progress-bar-buffered', function (event) {
            var barWidth = $(event.currentTarget).width();
            var clickPosition = event.offsetX;
            controller.seek(clickPosition / barWidth);
        });

        $('#player').on('click', '#player-mute-toggle-btn', function () {
            controller.toggleMute();
            playerDisplay.setMuteStatus(controller.isMuted());
        });

        initialised = true;
    }
});

