define('webstore-client/ui/basket/header',[
    'jquery',
    'webstore-client/contentloading/controller',
    'webstore-client/ui/basket/controller',
    'template-notification-basket',
    'template-header-basket'
], function ($, contentLoading, controller, renderBasketDropDown, renderHeader) {

    var initialised = false;

    function hideBasketDropDown() {
        //Re-render notification with 'cached' basket. This has the effect of
        //clearing any added/removed/error notices which aren't cached in the
        //controller.
        controller.getCurrent().done(updateDropDown);
    }

    function basketFitsOnScreen() {
        var windowHeight = $(window).height();
        var basketHeight = $('#notification-basket').height() + 90;
        return windowHeight > basketHeight;
    }

    function handleBasketClicks(e) {
        var $target = $(e.target);
        var $basket = $('.basket');
        var $basketShowing = $basket.hasClass('is-visible');
        var basketHeaderClicked = $target.closest('.header-basket-link').length;
        if (basketHeaderClicked && basketFitsOnScreen()) {
            e.preventDefault();
            e.stopPropagation(); // Don't bubble-up to navigation listener

            if ($basketShowing) {
                return hideBasketDropDown();
            }

            return $basket.toggleClass('is-visible is-hidden');
        }

        // Click outside the basket drop-down
        if ($basketShowing && !$target.closest('.basket').length) {
            hideBasketDropDown();
            return;
        }
    }

    function bindDropDownRendering() {
        $('#page-container').click(handleBasketClicks);

        contentLoading.onNavEnd(function () {
            hideBasketDropDown();
        });
    }

    function update(basketResponse) {
        var wasShowing = $('.basket').hasClass('is-visible');

        updateDropDown(basketResponse);
        updateHeader(basketResponse);

        if (basketResponse.basket.shouldDisplay && wasShowing) {
            $('.basket.is-hidden').toggleClass('is-visible is-hidden');
        }
    }

    function updateDropDown(b) {
        var newMarkup = renderBasketDropDown(b);
        $('.basket').replaceWith(newMarkup);
    }

    function updateHeader(b) {
        var newMarkup = renderHeader(b);
        $('#header-basket').replaceWith(newMarkup);
    }

    if (!initialised) {
        controller.getCurrent().done(update);
        controller.onUpdate(update);
        bindDropDownRendering();
        initialised = true;
    }
});

