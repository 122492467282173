/*
 * feedback module for 7digital.com
 * (c) 7digital.com
 */

define('webstore-client/contentloading/feedback',[
    'debug',
    'webstore-client/contentloading/controller',
    'lib/nprogress'
], function (debug, controller, NProgress) {
    var delayVisibility, visible = false;

    function init() {
        NProgress.configure({ showSpinner: false });

        controller.onNavStart(function () {
            delayVisibility = setTimeout(function () {
                NProgress.configure({ speed: 200 });
                NProgress.start();

                visible = true;
            }, 200);
        });

        controller.onNavEnd(function () {
            // Clear timer if available
            if (typeof delayVisibility !== 'undefined') {
                clearTimeout(delayVisibility);
            }

            if (visible) {
                // Don't hang around when done
                NProgress.configure({ speed: 100 });
                NProgress.done();
            }

            visible = false;
        });

        controller.onNavProgress(function (data) {
            var newProg = data.progress;
            var oldProg = NProgress.status;
            if (newProg > oldProg && visible) { NProgress.set(newProg); }
        });
    }

    return {
        init: init
    };
});

