define('src/app',[
    'jquery',
    'webstore-client/analytics/track',
    'client-config',
    'webstore-client/contentloading/controller',
    'webstore-client/contentloading/feedback',
    'webstore-client/utilities/history',
    'webstore-client/utilities/handlebars-helpers',
    'webstore-client/ui/init',
    'webstore-client/pages/init'
], function ($, track, clientConfig, contentLoadingController, contentLoadingFeedback, history) {
    track.pageView();

    if (history.isSupported) {
        contentLoadingController.init();
        contentLoadingFeedback.init();
        history.init();
    }

    var endTime = (new Date()).getTime();
    var timeTaken = endTime - clientConfig.clientStartTime;
    track.trackTiming('Performance', 'Core JavaScript Initialised', timeTaken);
});

