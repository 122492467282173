define('webstore-client/pages/init',[
    'require',
    'webstore-client/utilities/page-type',
    'webstore-client/contentloading/controller',
    //Update the module's unit tests to add stubs for pages as they are added
    //here to prevent potentially weird dependency errors and slowdown
    'webstore-client/pages/download-purchase',
    'webstore-client/pages/checkout',
    'webstore-client/pages/charts',
    'webstore-client/pages/yourmusic',
    'webstore-client/pages/yourmusic-release',
    'webstore-client/pages/release',
    'webstore-client/pages/artist',
    'webstore-client/pages/sign-in'

], function (require, getPageType, clController, downloadPurchase, checkout,
    charts, yourMusic, yourMusicRelease, release, artist, signIn) {

    //Whitelist
    var modules = {
        'download-purchase': downloadPurchase,
        'checkout': checkout,
        'charts': charts,
        'yourmusic': yourMusic,
        'yourmusic-release': yourMusicRelease,
        'artist': artist,
        'release': release,
        'sign-in': signIn
    };

    function loadModuleForPage() {
        var p = getPageType();
        var module = modules[p];

        //If the module exports a function, run it. Note this is different from
        //modules which self initialise
        if (module !== undefined) {
            module();
        }
    }

    clController.onNavEnd(loadModuleForPage);
    loadModuleForPage();
});

