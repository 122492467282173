define('webstore-client/ui/playback/player/google-cast/cast-track-adapter',[
    'jquery',
    'webstore-client/ui/playback/player/google-cast/google-cast-proxy',
    'debug'
], function ($, googleCast, debug) {

    var PRELOAD_TIME = 30;

    var IMAGE_SIZE_MATCH = /_[0-9]{1,3}\./;
    var LARGE_IMAGE_REPLACE = '_800.';
    var STREAM_URL_MATCH = '/stream/release/';
    var CAST_URL_REPLACE = '/stream/cast/release/';

    var _chrome = googleCast.chrome;
    var _trackBeingAdapted;
    var _trackInfoCallback;
    var _adaptedQueue = [];
    var _originalTracks = [];

    function convertTracklistToMediaInfoList(tracks, callback) {
        var onQueueItemReady = function (mediaInfo) {
            _adaptedQueue.push(createQueueItemFromMediaInfo(mediaInfo));
            if (_adaptedQueue.length === tracks.length) {
                callback(_adaptedQueue);
            } else {
                _trackBeingAdapted = tracks[_adaptedQueue.length];
                getCastUrl(_trackBeingAdapted, onCastUrlResponse);
            }
        };

        _originalTracks = tracks;
        _adaptedQueue = [];
        _trackBeingAdapted = tracks[0];
        _trackInfoCallback = onQueueItemReady;

        getCastUrl(_trackBeingAdapted, onCastUrlResponse);
    }

    function createQueueItemFromMediaInfo(mediaInfo) {
        var queueItem = new _chrome.cast.media.QueueItem(mediaInfo);
        queueItem.autoplay = true;
        queueItem.preloadTime = PRELOAD_TIME;
        return queueItem;
    }

    function refreshTracks(callback) {
        convertTracklistToMediaInfoList(_originalTracks, callback);
    }

    function getOriginalTrackByIndex(index) {
        return _originalTracks[index];
    }

    function getCastUrl(track, cb) {
        if (track.streamUrl === undefined || track.streamUrl.replace === undefined) {
            return cb(track.streamUrl);
        }

        var castUrl = track.streamUrl.replace(STREAM_URL_MATCH, CAST_URL_REPLACE);
        $.ajax(castUrl, {success: cb});
    }

    function onCastUrlResponse(track) {
        var mediaInfo = new _chrome.cast.media.MediaInfo(track.cast);
        mediaInfo.metadata = new _chrome.cast.media.MusicTrackMediaMetadata();
        mediaInfo.metadata.metadataType = _chrome.cast.media.MetadataType.MUSIC_TRACK;
        mediaInfo.metadata.artist = _trackBeingAdapted.artist;
        mediaInfo.metadata.trackNumber = parseInt(_trackBeingAdapted.trackNumber);
        mediaInfo.metadata.title = _trackBeingAdapted.title;
        mediaInfo.metadata.images = [{url:getLargeImageUrl(_trackBeingAdapted.image)}];
        mediaInfo.contentType = 'audio/mp4; codecs=mp4a.40.5';

        _trackInfoCallback(mediaInfo);
    }

    function getLargeImageUrl(imageUrl) {
        if (imageUrl !== undefined && imageUrl.replace !== undefined) {
            return imageUrl.replace(IMAGE_SIZE_MATCH, LARGE_IMAGE_REPLACE);
        } else {
            debug.error('Attempted to convert undefined image URL');
            return '';
        }
    }

    return {
        convertTracklistToMediaInfoList: convertTracklistToMediaInfoList,
        getOriginalTrackByIndex: getOriginalTrackByIndex,
        refreshTracks: refreshTracks,
        getLargeImageUrl: getLargeImageUrl
    };
});

