/*
 * Analytics Tracking Wrapper for 7digital.com
 * Currently wraps Google Analytics
 * (c) 7digital.com
 *
 * Firing an event-tracking call is as simple as:
 *
 * track.event(
 *    'Event Category',
 *    'Event Action',
 *    'Event Label'
 *    eventValue
 * );
 *
 * Arguments directly mirror Google Analytics calls:
 *
 * AddItem(
 *    TransactionId,
 *    SKU (shopProductID_trackID),
 *    Product Name (optional),
 *    category (optional),
 *    Price
 *    quantity (1)
 * )
 *
 * AddTransaction(
 *    TransactionId,
 *    Affiliation (partnerString),
 *    OrderTotal,
 *    tax (optional),
 *    shipping (optional),
 *    city (optional),
 *    state (optional),
 *    country (optional)
 * )
 */

define('webstore-client/analytics/track',[
    'webstore-client/utilities/stopwatch',
    'webstore-client/utilities/page-type',
    'client-config',
    'webstore-client/analytics/google-analytics'
], function (Stopwatch, getPageType, clientConfig) {
    var timedEvents = {},
        initialised = false;

    function getArgs(args) {
        return Array.prototype.slice.call(args);
    }

    function pageView() {
        if (clientConfig.gatCode) {
            return window.ga('send', 'pageview');
        }
    }

    function event() {
        if (clientConfig.gatCode) {
            var eventArgs = getArgs(arguments);
            eventArgs.unshift('send', 'event');
            return window.ga.apply(window.ga, eventArgs);
        }
    }

    function trackTiming() {
        if (clientConfig.gatCode) {
            var eventArgs = getArgs(arguments);
            eventArgs.unshift('send', 'timing');

            if (!eventArgs[5]) {

                var pageType = getPageType();
                pageType = pageType.substring(0, 1).toUpperCase() + pageType.substring(1) + ' page';

                eventArgs.splice(5, 0, pageType);
            }

            return window.ga.apply(window.ga, eventArgs);
        }
    }

    function getBucket(diff) {
        var bucketBounds = [0, 100, 500, 1500, 2500, 5000, 7500, 10000, 15000],
            numBucketBounds = bucketBounds.length;

        for (var i = 1; i < numBucketBounds; ++i) {
            if (diff < bucketBounds[i]) {
                return bucketBounds[i - 1] + '-' + (bucketBounds[i] - 1);
            }
        }

        return bucketBounds[numBucketBounds - 1] + '+';
    }

    function trackTimedEvent(startTime, category, label) {
        var diff = (new Date()).getTime() - startTime,
            bucket = getBucket(diff);

        event(category, bucket, label, diff);
    }

    function startTimedEvent(category, label) {
        timedEvents[category] = {
            label: label,
            start: (new Date()).getTime()
        };
    }

    function endTimedEvent(category) {
        trackTimedEvent(
            timedEvents[category].start,
            category,
            timedEvents[category].label
        );
    }

    function startNewStopwatch() {
        var stopwatch = new Stopwatch();
        stopwatch.start();

        return stopwatch;
    }

    function addTransaction(transactionId, affiliation, revenue, currency) {
        if (clientConfig.gatCode) {
            window.ga('require', 'ecommerce');
            return window.ga('ecommerce:addTransaction', {
                id: transactionId,
                affiliation: affiliation,
                revenue: revenue,
                currency: currency
            });
        }
    }

    function addToTransaction(transactionId, sku, name, category, price, quantity) {
        if (clientConfig.gatCode) {
            return window.ga('ecommerce:addItem', {
                id: transactionId,
                sku: sku,
                name: name,
                category: category,
                price: price,
                quantity: quantity
            });
        }
    }

    function trackTransaction() {
        if (clientConfig.gatCode) {
            return window.ga('ecommerce:send');
        }
    }

    function createTracker() {
        if (clientConfig.gatCode) {
            var gatDomain = clientConfig.gatDomain ? clientConfig.gatDomain : 'auto';

            window.ga('create', clientConfig.gatCode, gatDomain, {
                allowLinker: true, siteSpeedSampleRate: 100
            });

            window.ga('require', clientConfig.googleOptimizeContainerId);
        }
    }

    function trackUser() {
        if (clientConfig.gatCode && clientConfig.user.id) {
            window.ga('set', 'userId', clientConfig.user.id);
        }
    }

    if (!initialised) {
        createTracker();
        trackUser();

        initialised = true;
    }

    return {
        event: event,
        trackTiming: trackTiming,
        pageView: pageView,
        startTimedEvent: startTimedEvent,
        endTimedEvent: endTimedEvent,
        getBucket: getBucket, // exposed for test only
        startTransaction: addTransaction,
        addToTransaction: addToTransaction,
        endTransaction: trackTransaction,
        startNewStopwatch: startNewStopwatch
    };
});

