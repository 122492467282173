/*
 * Media Query Module
 */
define('webstore-client/utilities/media-query',[
    'jquery',
    'lodash',
    'webstore-client/lib/match-media'
], function ($, _, matchMedia) {

    var _changeEvent = ('onorientationchange' in window ? 'orientationchange' : 'resize'),
        _viewports = {
            'desktop': 'only screen and (min-width: 960px)',
            'less-than-desktop': 'only screen and (max-width: 960px)',
            'tablet-portrait': 'only screen and (min-width: 768px) and (max-width: 959px)',
            'less-than-tablet-portrait': 'only screen and (max-width: 767px)',
            'mobile-landscape': 'only screen and (min-width: 480px) and (max-width: 767px)',
            'mobile-portrait': 'only screen and (max-width: 479px)'
        },
        _previousViewport = getCurrent();

    /**
     * Assign window resize handler
     */
    $(window).on(_changeEvent, _.debounce(function (e) {
        var currentViewport = getCurrent();

        if (_.isEqual(currentViewport, _previousViewport)) { return; }

        _previousViewport = currentViewport;
        updateBodyClass();
        $(window).trigger('viewportChange', { viewport: getCurrent() });
    }, 700));

    /**
     * Return _viewports keys for all possible viewport matches
     */
    function getCurrent() {
        return _.reduceRight(_viewports, function (result, value, key) {
            if (matchMedia(value).matches) {
                result.push(key);
            }
            return result;
        }, []);
    }

    /**
     * Add current viewport short names to body class
     */
    function updateBodyClass() {
        var allViewports = _.keys(_viewports).join(' '),
            currentViewports = getCurrent().join(' ');

        $('body').removeClass(allViewports).addClass(currentViewports);
    }

    /**
     * Provide all possible matches for current viewport
     */
    function matches(viewport) {
        if (viewport in _viewports) {
            return matchMedia(_viewports[viewport]).matches;
        }
        return false;
    }

    updateBodyClass();

    return {
        getCurrent: getCurrent,
        matches: matches,
        __changeEvent: _changeEvent,
        __viewport: _viewports
    };
});

