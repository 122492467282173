/*
 * Custom Utilities for 7digital.com
 * (c) 7digital.com
 */

define('webstore-client/utilities/urlhelpers',[
    'webstore-client/utilities/location'
], function (location) {

    function getPathName() {
        var pathName = location.pathname;
        // ensure we have the leading slash
        return (pathName[0] !== '/' ? '/' : '') + pathName;
    }

    function getQueryStr() {
        var search = location.search;
        if (!search) { return search; }
        return (search[0] !== '?' ? '?' : '') + search;
    }

    function paramsWithDefault(url) {
        var paramsIndex;
        url = url || location.href;

        paramsIndex = url.indexOf('?');
        if (paramsIndex === -1) {
            return '';
        }

        return url.substring(paramsIndex + 1);
    }

    /*
     * returns true if the current location query string contains the name as a key
     * with or without a value
     */
    function hasParam(name, url) {
        var queryStringParameters,
            nameValuePairs,
            nameValue,
            positionOfSeparator,
            thisName;

        queryStringParameters = paramsWithDefault(url);
        nameValuePairs = queryStringParameters.split('&');

        for (var i = 0, n = nameValuePairs.length; i < n; ++i) {
            nameValue = nameValuePairs[i];
            positionOfSeparator = nameValue.indexOf('=');
            if (positionOfSeparator > -1) {
                thisName = nameValue.slice(0, positionOfSeparator);
            } else {
                thisName = nameValue;
            }

            if (thisName === name) {
                return true;
            }
        }

        return false;
    }

    function addSearchParameter(url, param) {
        if (url.indexOf('?') > -1) {
            return url + '&' + param;
        }
        return url + '?' + param;
    }

    function removeSearchParameter(url, param) {
        var urlParts,
            queryString,
            pattern,
            updatedParams;

        urlParts = url.split('?');
        queryString = urlParts[1];

        if (!queryString) {
            return url;
        }

        pattern = new RegExp('&?' + param + '(=[^&]+)?');
        updatedParams = queryString.replace(pattern, '');

        if (updatedParams[0] === '&') {
            updatedParams = updatedParams.slice(1);
        }

        if (updatedParams.length === 0) {
            return urlParts[0];
        }

        return urlParts[0] + '?' + updatedParams;
    }

    function paramValue(paramName, url) {
        var queryStringParameters,
            a,
            b,
            positionOfThisSeparator,
            nameValuePairs,
            thisName,
            thisPair,
            thisValue;

        queryStringParameters = paramsWithDefault(url);

        nameValuePairs = queryStringParameters.split('&');

        for (a = 0, b = nameValuePairs.length; a < b; a = a + 1) {

            thisPair = nameValuePairs[a];
            positionOfThisSeparator = thisPair.indexOf('=');

            if (positionOfThisSeparator > -1) {

                thisName = thisPair.substring(0, positionOfThisSeparator);

                if (thisName === paramName) {
                    thisValue = thisPair.substring(positionOfThisSeparator + 1);
                    thisValue = decodeURIComponent(thisValue);
                    return thisValue;
                }
            }
        }

        return undefined;
    }

    function getHashParameterValue(name) {
        var a,
            b,
            hashStringParameters,
            parameters,
            positionOfThisSeparator,
            nameValuePairs,
            thisName,
            thisPair,
            thisValue,
            value;

        parameters = {};
        hashStringParameters = location.hash.substring(1);
        nameValuePairs = hashStringParameters.split('&');

        for (a = 0, b = nameValuePairs.length; a < b; a = a + 1) {

            thisPair = nameValuePairs[a];
            positionOfThisSeparator = thisPair.indexOf('=');

            if (positionOfThisSeparator > -1) {
                thisName = thisPair.substring(0, positionOfThisSeparator);
                thisValue = thisPair.substring(positionOfThisSeparator + 1);
                thisValue = decodeURIComponent(thisValue);
                parameters[thisName] = thisValue;
            }
        }

        value = parameters[name];
        return value;
    }

    function isStringOfDigits(thing) {
        var matchArray,
            pattern;

        if ((thing === undefined) || (thing === null)) {
            return false;
        }

        if (typeof (thing) !== 'string') {
            thing = thing.toString();
        }

        pattern = /^[0-9]+$/;
        matchArray = thing.match(pattern);
        return (matchArray !== null);
    }

    function removeTrailingForwardSlash(string) {

        var lastCharacterOfString = string.substring(string.length - 1);

        if (lastCharacterOfString === '/') {
            // Remove trailing "/" if it exists from the string
            string = string.substring(0, string.length - 1);
        }

        return string;
    }

    return {
        getPathName: getPathName,
        getQueryStr: getQueryStr,
        hasParam: hasParam,
        addSearchParameter: addSearchParameter,
        removeSearchParameter: removeSearchParameter,
        getHashParameterValueByName: getHashParameterValue,
        paramValue: paramValue,
        looksLikeAReleaseOrTrackId: isStringOfDigits,
        removeTrailingForwardSlash: removeTrailingForwardSlash
    };
});

