define('webstore-client/ui/packshot',[
    'jquery',
    'lodash',
    'webstore-client/contentloading/controller'
], function ($, _, controller) {

    function showLoadedImage(e) {
        setTimeout(function () {
            $(e.target).addClass('fade-showing');
        }, 20);
    }

    function unbindListeners() {
        $('.packshot img, .feature-packshot img').off('load', showLoadedImage);
    }

    function bindListeners() {
        $('.packshot img, .feature-packshot img').on('load', showLoadedImage);
    }

    controller.onNavStart(unbindListeners);
    controller.onNavEnd(bindListeners);
    $('.packshot img, .feature-onestowatch-packshot img, .feature-packshot img').addClass('fade-showing');
});

