define('template-ajax-layout',['handlebars'], function(Handlebars) {

Handlebars.registerPartial("head", Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var helper;

  return "<title>"
    + this.escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"title","hash":{},"data":data}) : helper)))
    + "</title>\n";
},"useData":true}));

Handlebars.registerPartial("meta-dynamic", Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var helper;

  return "<meta name=\"description\" content=\""
    + this.escapeExpression(((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"description","hash":{},"data":data}) : helper)))
    + "\" data-dynamic=\"true\"/>\n";
},"3":function(depth0,helpers,partials,data) {
    var helper, alias1=this.escapeExpression;

  return "	<meta property=\""
    + alias1(((helper = (helper = helpers.property || (depth0 != null ? depth0.property : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"property","hash":{},"data":data}) : helper)))
    + "\" content=\""
    + alias1(this.lambda((depth0 != null ? depth0.content : depth0), depth0))
    + "\" data-dynamic=\"true\"/>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.description : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.meta : depth0),{"name":"each","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"useData":true}));

Handlebars.registerPartial("notice-internal", Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "<aside class=\"notice-error\" data-dynamic=\"true\">\n	<div class=\"notice-error-content\">\n		This page is being built and is only visible internally.\n	</div>\n</aside>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.pageBeingBuilt : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"useData":true}));

Handlebars.registerPartial("breadcrumbs", Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1, alias1=helpers.helperMissing, alias2=this.escapeExpression, alias3=this.lambda;

  return "        <h1 class=\"breadcrumbs-container\">\n            <i class=\"flag-icon s-"
    + alias2((helpers.lowerCase || (depth0 && depth0.lowerCase) || alias1).call(depth0,((stack1 = (depth0 != null ? depth0.shop : depth0)) != null ? stack1.countryCode : stack1),{"name":"lowerCase","hash":{},"data":data}))
    + "\"></i>\n            <span>"
    + alias2(alias3(((stack1 = (depth0 != null ? depth0.shop : depth0)) != null ? stack1.name : stack1), depth0))
    + " "
    + alias2(alias3(((stack1 = (depth0 != null ? depth0.shop : depth0)) != null ? stack1.localName : stack1), depth0))
    + "</span>\n        </h1>\n        <p class=\"breadcrumb-strapline\">"
    + alias2((helpers.translate || (depth0 && depth0.translate) || alias1).call(depth0,"7digHomePageTitle",{"name":"translate","hash":{},"data":data}))
    + "</p>\n";
},"3":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=helpers.helperMissing, alias2=this.escapeExpression, alias3=this.lambda;

  return "        <nav class=\"breadcrumb-links\">\n            <div itemscope itemtype=\"http://data-vocabulary.org/Breadcrumb\">\n                <a href=\"/\" itemprop=\"url\">\n                    <i class=\"flag-icon s-"
    + alias2((helpers.lowerCase || (depth0 && depth0.lowerCase) || alias1).call(depth0,((stack1 = (depth0 != null ? depth0.shop : depth0)) != null ? stack1.countryCode : stack1),{"name":"lowerCase","hash":{},"data":data}))
    + "\"></i>\n                    <span>"
    + alias2(alias3(((stack1 = (depth0 != null ? depth0.shop : depth0)) != null ? stack1.name : stack1), depth0))
    + " "
    + alias2(alias3(((stack1 = (depth0 != null ? depth0.shop : depth0)) != null ? stack1.localName : stack1), depth0))
    + "</span>\n                </a>\n            </div>\n"
    + ((stack1 = (helpers.foreach || (depth0 && depth0.foreach) || alias1).call(depth0,(depth0 != null ? depth0.breadcrumbs : depth0),{"name":"foreach","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "        </nav>\n        <p class=\"breadcrumb-strapline\">"
    + alias2((helpers.translate || (depth0 && depth0.translate) || alias1).call(depth0,"7digHomePageTitle",{"name":"translate","hash":{},"data":data}))
    + "</p>\n";
},"4":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.url : depth0),{"name":"if","hash":{},"fn":this.program(5, data, 0, blockParams, depths),"inverse":this.program(13, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"5":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.$last : depth0),{"name":"unless","hash":{},"fn":this.program(6, data, 0, blockParams, depths),"inverse":this.program(8, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"6":function(depth0,helpers,partials,data) {
    var helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "                        <div itemscope itemtype=\"http://data-vocabulary.org/Breadcrumb\">&nbsp;/\n                            <a href=\""
    + alias3(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"url","hash":{},"data":data}) : helper)))
    + "\" itemprop=\"url\">\n                                <span itemprop=\"title\">"
    + alias3(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"title","hash":{},"data":data}) : helper)))
    + "</span></a>\n                        </div>\n\n";
},"8":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,((stack1 = (depths[3] != null ? depths[3].breadcrumbs : depths[3])) != null ? stack1.linkLast : stack1),{"name":"if","hash":{},"fn":this.program(9, data, 0, blockParams, depths),"inverse":this.program(11, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"9":function(depth0,helpers,partials,data) {
    var helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "                            <div itemscope itemtype=\"http://data-vocabulary.org/Breadcrumb\">&nbsp;/\n\n                                <a href=\""
    + alias3(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"url","hash":{},"data":data}) : helper)))
    + "\" itemprop=\"url\">\n                                    <span itemprop=\"title\">"
    + alias3(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"title","hash":{},"data":data}) : helper)))
    + "</span></a>\n                            </div>\n";
},"11":function(depth0,helpers,partials,data) {
    var helper;

  return "                            <div>&nbsp;/\n\n                                <span>"
    + this.escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"title","hash":{},"data":data}) : helper)))
    + "</span>\n                            </div>\n";
},"13":function(depth0,helpers,partials,data) {
    var helper;

  return "                    <div>&nbsp;/\n\n                        <span>"
    + this.escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"title","hash":{},"data":data}) : helper)))
    + "</span>\n                    </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "<div class=\"breadcrumbs\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.isHomePage : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.program(3, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true,"useDepths":true}));

Handlebars.registerPartial("notice-baskets-merged", Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.basketsMergedCount : depth0),{"name":"if","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"2":function(depth0,helpers,partials,data) {
    return "<aside class=\"header-notice-warning\" data-dynamic=\"true\">\n	<div class=\"notice-error-content\">\n		<p>"
    + this.escapeExpression((helpers.translate || (depth0 && depth0.translate) || helpers.helperMissing).call(depth0,"BasketsMerged",(depth0 != null ? depth0.basketsMergedCount : depth0),{"name":"translate","hash":{},"data":data}))
    + "</p>\n	</div>\n</aside>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.basketsMerged : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"useData":true}));

return Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "                <div class=\"main-floating\">\n                    "
    + ((stack1 = ((helper = (helper = helpers.body || (depth0 != null ? depth0.body : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"body","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n                </div>\n";
},"3":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "                <article class=\"main-container\">\n"
    + ((stack1 = this.invokePartial(partials.breadcrumbs,depth0,{"name":"breadcrumbs","data":data,"indent":"                    ","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + "                    "
    + ((stack1 = ((helper = (helper = helpers.body || (depth0 != null ? depth0.body : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"body","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n                </article>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div id=\"partialResponse\">\n    <div id=\"headResponse\">\n"
    + ((stack1 = this.invokePartial(partials.head,depth0,{"name":"head","data":data,"indent":"        ","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + "    </div>\n    <div id=\"metaProps\">\n"
    + ((stack1 = this.invokePartial(partials['meta-dynamic'],depth0,{"name":"meta-dynamic","data":data,"indent":"        ","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + "    </div>\n    <div id=\"notices\">\n"
    + ((stack1 = this.invokePartial(partials['notice-internal'],depth0,{"name":"notice-internal","data":data,"indent":"        ","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + ((stack1 = this.invokePartial(partials['notice-baskets-merged'],depth0,{"name":"notice-baskets-merged","data":data,"indent":"        ","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + "    </div>\n    <div id=\"pageContent\">\n        <div class=\"body-container\" role=\"main\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.noMainContainer : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>";
},"usePartial":true,"useData":true})

});
