/*
 * navigation module
 * handles link clicks and updates url accordingly
 * Functionality for 7digital.com
 * (c) 7digital.com
 */

define('webstore-client/contentloading/navigation',[
    'jquery',
    'webstore-client/contentloading/anchorchecker',
    'webstore-client/utilities/history',
    'webstore-client/utilities/urlhelpers',
    'debug',
    'webstore-client/utilities/location'
], function ($, anchorchecker, history, urlhelpers, debug, location) {

    function updateUrlForValidLinkClicks(event) {
        var anchor, url;
        anchor = $(event.currentTarget);

        if (event.ctrlKey || event.shiftKey || event.metaKey) { return; }

        if (anchorchecker.supportsPartialPage(anchor)) {
            event.preventDefault();
            url = anchor.attr('href');

            partialPageLoad(url);
        }
    }

    function partialPageLoad(url) {
        var prismicRef = urlhelpers.paramValue('prismicRef', location.href);
        if (prismicRef) {
            url = urlhelpers.addSearchParameter(url, 'prismicRef=' + prismicRef);
        }

        history.pushUrl(url);
    }

    function fullPageLoad(url) {
        debug.log(url);
        url = urlhelpers.addSearchParameter(url, 'fallback=true');
        window.location.href = url;
    }

    function init(bodySelection) {
        bodySelection = bodySelection || $('body');
        bodySelection.on('click', 'a', updateUrlForValidLinkClicks);
    }

    return {
        init: init,
        updateUrlForValidLinkClicks: updateUrlForValidLinkClicks,
        fullPageLoad: fullPageLoad,
        partialPageLoad: partialPageLoad
    };

});

