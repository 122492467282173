
//Index of UI modules which need initialising by app.js

define('webstore-client/ui/lazyload',[
    'jquery',
    'blazy',
    'lodash'
], function ($, Blazy, _) {
    // Beware: the visual regression tests load this module and call Blazy.load
    $(window).resize(_.debounce(lazyload, 500));
    function lazyload() {
        var b = new Blazy({
            selector: '.lazy-load',
            successClass: 'lazy-load-success fade-showing',
            errorClass: 'lazy-load-error',
            error: function (e) {
                var defaultImage = e.getAttribute('data-src');

                /* Current Blazy version removes the data-src attribute before
                 * the error handler, so if we can't access the original image,
                 * leave it blank. If Blazy merges a patch, appending a query
                 * string sometimes allows a block packshot through Adblock. */

                if (defaultImage) {
                    e.src = defaultImage;
                }
            }
        });

        return b;
    }

    return lazyload();
});

