define('webstore-client/lib/match-media',[], function () {
	/**
	* Adapted from the matchMedia.js
	* @see https://github.com/paulirish/matchMedia.js
	*/
	return function matchMedia(media) {
		if (window.matchMedia) {
			return window.matchMedia(media);
		}

		var styleMedia = (window.styleMedia || window.media);

		if (!styleMedia) {
			var style = document.createElement('style'),
				script = document.getElementsByTagName('script')[0],
				info = null;

			style.type = 'text/css';
			style.id = 'matchmediajs-test';

			script.parentNode.insertBefore(style, script);

			info = ('getComputedStyle' in window) &&
					window.getComputedStyle(style, null) ||
					style.currentStyle;

			styleMedia = {
				matchMedium: function (media) {
					var text = '@media ' + media + '{ #matchmediajs-test { width: 1px; } }';

					if (style.styleSheet) {
						style.styleSheet.cssText = text;
					} else {
						style.textContent = text;
					}

					return info.width === '1px';
				}
			};
		}

		return {
			matches: styleMedia.matchMedium(media || 'all'),
			media: media || 'all'
		};
	};
});

