/*
 * Live search data module for 7digital.com
 * (c) 7digital.com
 */

define('webstore-client/ui/search/live-search-data',[
    'lodash',
    'jquery',
    'debug',
    'webstore-client/utilities/ajax',
    'webstore-client/lib/jsurl',
    'translate',
    'client-config',
    'webstore-client/ui/search/url-builder',
    'webstore-client/ui/search/get-model-items'
], function (_, $, debug, ajax, Url,
             translate, clientConfig, searchUrl, getModelItems) {
    var _searchUrl;
    var _pendingRequests = [];

    function mapLink(artistSlug, releaseSlug, trackNum, discNum) {
        var parts = ['/artist/', artistSlug];
        if (releaseSlug) { parts.push('/release/', releaseSlug); }
        if (trackNum) { parts.push('?h=', trackNum); }
        if (discNum) { parts.push('&d=', discNum); }
        return parts.join('');
    }

    function mapImageUrl(url) {
        return url.replace('http:', '');
    }

    function buildArtistModel(artistResult) {
        var artist = artistResult.artist;

        return {
            type: 'Artist',
            mainText: artist.appearsAs || artist.name,
            url: mapLink(artist.slug)
        };
    }

    function titleWithVersion(trackOrRelease) {
        var version = trackOrRelease.version;
        version = version ? ' (' + version + ')' : '';

        return trackOrRelease.title + version;
    }

    function buildAlbumModel(releaseResult) {
        var album = releaseResult.release,
            artist = album.artist;

        return {
            type: 'Album',
            mainText: titleWithVersion(album),
            subText: artist.appearsAs || artist.name,
            imageUrl: mapImageUrl(album.image),
            url: mapLink(artist.slug, album.slug)
        };
    }

    function buildTrackModel(trackResult) {
        var track = trackResult.track,
            album = track.release,
            artist = track.artist;

        return {
            type: 'Track',
            mainText: titleWithVersion(track),
            subText: artist.appearsAs || artist.name,
            imageUrl: mapImageUrl(album.image),
            // track.artist !== album.artist, e.g. when album is various-artists
            url: mapLink(album.artist.slug, album.slug, track.number, track.discNumber)
        };
    }

    function getSearchResults(searchTerm, searchType) {
        var url = new Url('//api.7digital.com/1.2/');
        url.path += searchType + '/search';
        //jscs: disable
        url.query.oauth_consumer_key = '7drfpc993qp5';
        //jscs: enable
        url.query.usageTypes = 'download';
        url.query.pageSize = 3;
        url.query.q = searchTerm;
        url.query.shopId = clientConfig.shop.id;

        if (searchType === 'release') { url.query.type = 'album'; }

        // jsUrl+IE removes the relative protocol, so remove it here and
        // re-add the '//' manually, below
        url.protocol = '';

        return ajax({
            url: '//' + url.toString(),
            dataType: 'json',
            background: true
        });
    }

    function extractResultsFromResponse(response) {
        if (response && response.length && response[0].searchResults) {
            return response[0].searchResults.searchResult || [];
        }

        return [];
    }

    function updateModelForResultItems(model, items) {
        model.itemCount = items.length;
        model.hasItems = model.itemCount > 0;
        model.noItemFocused = true;
        _.forEach(items, function (item, i) { item.itemId = i; });
    }

    function updateModelForSearchTerm(model, searchTerm) {
        model.searchTerm = searchTerm;
        model.searchResultsUrl =
            searchUrl.fullSearchResults(_searchUrl, searchTerm);
    }

    function cancelPendingUpdates() {
        if (_pendingRequests.length) {
            _.forEach(_pendingRequests, function (req) {
                req.abort();
            });

            _pendingRequests = [];
        }
    }

    function updateModelWithResults(model, cb) {
        var searchTerm = model.searchTerm;

        var releaseReq = getSearchResults(searchTerm, 'release');
        var trackReq = getSearchResults(searchTerm, 'track');
        var artistReq = getSearchResults(searchTerm, 'artist');

        _pendingRequests.push(releaseReq);
        _pendingRequests.push(trackReq);
        _pendingRequests.push(artistReq);

        $.when(releaseReq, trackReq, artistReq)
        .done(function (releaseResponse, trackResponse, artistResponse) {
            // receives one argument for each deferred in the 'when'
            // each argument is [data, statusText, jqXHR]

            _pendingRequests = [];

            var artistResults = extractResultsFromResponse(artistResponse);
            var albumResults = extractResultsFromResponse(releaseResponse);
            var trackResults = extractResultsFromResponse(trackResponse);

            model.artists = _.map(artistResults, buildArtistModel);
            model.albums = _.map(albumResults, buildAlbumModel);
            model.tracks = _.map(trackResults, buildTrackModel);

            var items = getModelItems(model);
            updateModelForResultItems(model, items);

            model.searchTermUsedForVisibleResults = searchTerm;

            cb();
        })
        .fail(function () {
            updateModelForResultItems(model, []);
            cb();
        });
    }

    function init(searchUrl) {
        _searchUrl = searchUrl;
    }

    return {
        init: init,
        __getSearchResults: getSearchResults,
        updateModelForSearchTerm: updateModelForSearchTerm,
        updateModelWithResults: updateModelWithResults,
        cancelPendingUpdates: cancelPendingUpdates
    };
});

