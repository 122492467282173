/*
* Tracks an Ecommerce Transaction to Google Analytics
* (c) 7digital.com
*
* Note that the "(window||document).location.hostname" is used for the
* purposes of filtering transcations in Analytics
* http://www.ewbanalytics.com/blog/2011/3/8/filtering-ecommerce-transactions-across-multiple-subdomains.html
*
*/

define('webstore-client/analytics/track.ecommerce',[
    'jquery',
    'webstore-client/analytics/track',
    'debug',
    'webstore-client/utilities/environment'
], function ($, track, debug, env) {

    function trackTransaction(data, transactionCurrency) {

        track.startTransaction(
            data.Id,
            data.Affiliation || env.hostname(),
            data.Total,
            transactionCurrency
        );

        $.each(data.Items, function addItemToTransaction(idx, item) {
            debug.log(item);
            track.addToTransaction(
                item.TransactionId,
                item.Id,
                item.Name,
                item.Category,
                item.Price,
                item.Quantity
            );
        });

        track.endTransaction();
    }

    return {
        trackTransaction: trackTransaction
    };
});

