define('webstore-client/ui/search/search-filters',[
    'jquery',
    'webstore-client/contentloading/controller',
    'webstore-client/ui/search/submit-filter'
], function ($, contentLoading, submitFilter) {
    var $filter,
        isLoaded = false,
        currentState,
        $applyButton;

    function initialise() {
        if (!isLoaded) {
            contentLoading.onNavEnd(initialise);
            isLoaded = true;
        }

        $filter = $('.search-filter');

        if ($filter.length > 0) {
            initialiseFilters();
        }
    }

    function toggleApplyButton(value) {
        $applyButton.prop('disabled', !value);
    }

    function updateFormData() {
        var state = $filter.serialize();
        var hasSelection = state && state.length;

        toggleApplyButton(hasSelection && (state !== currentState));
    }

    function initialiseFilters() {
        currentState = $filter.serialize();
        $applyButton = $filter.find('button');
        toggleApplyButton(false);

        $filter.on('change', updateFormData);
        $filter.on('submit', submitFilter);
    }

    initialise();
});

