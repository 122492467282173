define('webstore-client/ui/shop-suggest',[
    'jquery',
    'webstore-client/utilities/ajax',
    'template-notice-wrong-shop',
    'webstore-client/locker/mark-owned',
    'client-config',
    'webstore-client/contentloading/controller',
    'debug'
], function ($, ajax, renderWrongShopNotice, markOwned, clientConfig, contentLoadingController, debug) {
    var initialised = false;

    function disableBuyButtons() {
        $('.buy-button').attr('disabled', 'disabled');
        $('.multiple-price').attr('disabled', 'disabled').removeAttr('href');
    }

    if (!initialised) {
        ajax({ url: '/shop-suggest', background: true })
        .done(function (shopSuggest) {
            if (shopSuggest.noShopAvailable || shopSuggest.onWrongShop) {
                var notice = renderWrongShopNotice(shopSuggest);
                $('.body-container').before(notice);

                clientConfig.isOnWrongShop = true;
                disableBuyButtons();
                contentLoadingController.onNavEnd(disableBuyButtons);
            }
        })
        .fail(function (jqXhr) {
            debug.ajaxError('couldn\'t get suggested shop', jqXhr);
        });
    }

    initialised = true;
});

