define('webstore-client/ui/basket/remove-buttons',[
    'jquery',
    'lodash',
    'webstore-client/ui/basket/controller'
], function ($, _, controller) {

    var initialised = false;

    function bindRemoveButtons() {
        $('#page-container').on('submit', 'form[action$="/basket/remove"]', function (e) {

            e.preventDefault();
            e.stopPropagation();
            var button = $('.basket-item-remove', e.target);

            if (isClickable(button)) {
                var itemId = $('input[name="itemId"]', e.target).val();

                button.addClass('is-removing');
                controller.remove(itemId);
            }
        });
    }

    function isClickable(button) {
        return (!button.hasClass('is-removing'));
    }

    if (!initialised) {
        bindRemoveButtons();

        initialised = true;
    }
});

