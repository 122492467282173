define('webstore-client/locker/ownership',[
    'lodash',
    'client-config',
    'webstore-client/utilities/ajax'
], function (_, config, ajax) {
    var availablePackages = {};
    var locker = [];
    var ajaxPromise;

    function _trackIsOwnedInPackage(packageId, trackId) {
        return _.contains(ownedTrackPackages(trackId), packageId);
    }

    function ownedReleasePackages() {
        var packagesOwnedForEachTrack = _(availablePackages)
            .pluck('trackId')
            .map(ownedTrackPackages)
            .value();

        return _.intersection.apply(null, packagesOwnedForEachTrack);
    }

    function ownedTrackPackages(trackId) {
        var ownedFormats = _(locker.ownedTrackIds)
            .filter({id: trackId}).pluck('formats').flatten().value();

        var availablePackagesForTrack = _(availablePackages)
            .filter({trackId: trackId})
            .pluck('packages').flatten().value();

        return _(availablePackagesForTrack).map(function (pkg) {
            var packageIsOwned = _.intersection(pkg.formats, ownedFormats).length === pkg.formats.length;

            if (packageIsOwned) {
                return pkg.id;
            }
        }).compact().value();
    }

    function isTrackOwned(trackId) {
        return !!_.find(locker.ownedTrackIds, {id: +trackId});
    }

    function isReleaseOwned(releaseId) {
        return _.contains(locker.ownedReleaseIds, +releaseId);
    }

    function fetchLockerData() {
        if (!ajaxPromise ||
            (ajaxPromise.state && ajaxPromise.state() === 'resolved')) {

            ajaxPromise = ajax({
                background: true,
                dataType: 'json',
                url: config.root + '/yourmusic'
            });

            ajaxPromise.done(setData);
            ajaxPromise.fail();
        }

        return ajaxPromise;
    }

    function setData(l, a) {
        setLockerData(l);
        setReleaseData(a);
    }

    function setLockerData(l) {
        locker = l || [];
    }

    function setReleaseData(a) {
        if (!a) { return false; }
        availablePackages = a;
    }

    function hasData() {
        return (!!locker.ownedTrackIds && locker.ownedTrackIds.length > 0);
    }

    function clearData() {
        locker = [];
        availablePackages = [];
    }

    return {
        ownedTrackPackages: ownedTrackPackages,
        ownedReleasePackages: ownedReleasePackages,
        isTrackOwned: isTrackOwned,
        isReleaseOwned: isReleaseOwned,

        fetchLockerData: fetchLockerData,
        hasData: hasData,
        setData: setData,
        setLockerData: setLockerData,
        setReleaseData: setReleaseData,
        clearData: clearData
    };
});

