/*
 * Debug module for 7digital.com
 * (c) 7digital.com
 */

define('debug',['lodash', 'client-config'], function (_, clientConfig) {
    var initialised = false, pSlice = Array.prototype.slice;

    function supported(level) {
        return Boolean(window.console && (level in console));
    }

    function log() {
        if (clientConfig.isDevelopment && supported('log')) {
            if (console.log.apply) {
                console.log.apply(console, arguments);
            } else {
                //For IE9
                console.log(Array.prototype.slice.call(arguments));
            }
        }
    }

    function error(message, err, meta) {
        if (clientConfig.isDevelopment) {
            if (supported('error')) {
                console.error({ message: message, error: err, meta: meta });
            }
            return;
        }
    }

    function stringifyProperties(obj) {
        return _.mapValues(obj, function (value) {
            if (_.isArray(value) || _.isPlainObject(value)) {
                return JSON.stringify(value);
            }
            if (_.isObject(value)) {
                return value.toString();
            }
            return value;
        });
    }

    function ajaxError(message, jqXhr, meta) {
        meta = meta || {};
        meta.statusText = jqXhr.statusText;
        if (meta.statusText === 'abort') {
            return log(message + ' (request aborted)', meta);
        }
        return error(message, null, meta);
    }

    if (!initialised) {
        initialised = true;
    }

    return {
        log: log,
        error: error,
        ajaxError: ajaxError
    };
});

