define('webstore-client/locker/mark-owned',[
    'lodash',
    'jquery',
    'translate',
    'webstore-client/locker/ownership',
    'client-config'
], function (_, $, translate, availability, config) {
    var text = translate('YouOwnThis');

    function updateOwnership() {
        var promise;

        if (!config.user.isSignedIn || config.isOnWrongShop) {
            promise = $.Deferred().reject();
        } else {
            if (!availability.hasData()) {
                promise = availability.fetchLockerData();
            } else {
                promise = $.Deferred().resolve();
            }
        }

        return promise;
    }

    function updateReleaseBuyButtons() {
        if (!config.isOnWrongShop) {
            $('.packshot-price[data-releaseid]').each(function () {
                var $el = $(this),
                    releaseId = $el.attr('data-releaseid'),
                    lockerUrl = $el.attr('data-locker-url');

                if (availability.isReleaseOwned(releaseId)) {
                    $el.replaceWith(renderInLockerLink(lockerUrl));
                }
            });
        }
    }

    function updateTrackBuyButtons() {
        if (!config.isOnWrongShop) {
            $('tr[data-trackid]').each(function () {
                var $row = $(this),
                    trackId = $row.attr('data-trackid');

                var $el = $row.find('.packshot-price'),
                    lockerUrl = $el.attr('data-locker-url');

                if (availability.isTrackOwned(trackId)) {
                    $el.replaceWith(renderInLockerLink(lockerUrl));
                }
            });
        }
    }

    function renderInLockerLink(lockerUrl) {
        return $('<a>', {
            'href': lockerUrl,
            'class': 'in-locker',
            text: text
        });
    }

    return {
        updateOwnership: updateOwnership,
        updateReleaseBuyButtons: updateReleaseBuyButtons,
        updateTrackBuyButtons: updateTrackBuyButtons
    };
});

