/*
 * environment module for 7digital.com
 * (c) 7digital.com
 */

define('webstore-client/utilities/environment',[],function () {

    return {
        hostname: function hostname() {
            return (window || document).location.hostname;
        }
    };
});

