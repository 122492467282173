define('webstore-client/pages/charts',[
    'jquery',
    'lodash'
], function ($, _) {
    var targetHash = window.location.hash.slice(1),
        initialised = false;

    function getVisibleChartId() {
        return $('.packshot-collection-menu')
                .find('.is-visible a')
                .data('collection-id');
    }

    function init() {
        if (targetHash.length === 0) { return; }
        if (_.isEqual(getVisibleChartId(), targetHash)) { return; }
    }

    if (!initialised) {
        init();
    }

    initialised = true;
});

