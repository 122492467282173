define('webstore-client/ui/playback/controller',[
    'lodash',
    'jquery',
    'webstore-client/ui/playback/player/browser-controller',
    'webstore-client/ui/playback/player/google-cast/cast-controller',
    'webstore-client/ui/playback/playlist-manager'
], function (_, $, browserController, castController, playlistManager) {

    var _isInitialized = false;
    var _castDeviceIsConnected = false;
    var _playerController;
    var _wakeUpHandlers = [];
    var _stateChangeHandlers = [];
    var _progressHandlers = [];
    var _playlistEndedHandlers = [];
    var _volumeChangeHandlers = [];
    var _muteStatusChangeHandlers = [];
    var _castDeviceStatusChangeHandlers = [];

    init();

    function init() {
        if (!_isInitialized) {
            playlistManager.onPlaylistUpdate(onPlaylistUpdate);
            playlistManager.onPlaylistPositionUpdate(onPlaylistPositionUpdate);
            playlistManager.onPlaylistNextTrack(onPlaylistNextTrack);
            playlistManager.onPlaylistPreviousTrack(onPlaylistPreviousTrack);

            castController.onReceiverAction(dispatchCastDeviceStatusChange);
            castController.onSessionStopped(onCastSessionStopped);
            switchControllers();
            _isInitialized = true;
        }
    }

    function switchControllers() {
        if (isUsingBrowserPlayer()) {
            removeControllerEvents(browserController);
            addControllerEvents(castController);
            _playerController = castController;
        } else {
            removeControllerEvents(castController);
            addControllerEvents(browserController);
            _playerController = browserController;
        }
    }

    function addControllerEvents(ctrl) {
        if (ctrl === browserController) {
            ctrl.onWakeUp(dispatchWakeUp); // Cast does not require WakeUp
        } else {
            ctrl.onVolumeChange(dispatchVolumeChange); // JPlayer volume is not changed from elsewhere
            ctrl.onMuteStatusChange(dispatchMuteStatusChange);
        }
        ctrl.onStateChange(dispatchStateChange);
        ctrl.onProgress(dispatchProgress);
        ctrl.onPlaylistEnded(dispatchPlaylistEnded);
    }

    function removeControllerEvents(ctrl) {
        if (ctrl === browserController) {
            ctrl.removeWakeUpListener(dispatchWakeUp);
        } else {
            ctrl.removeVolumeChangeListener();
            ctrl.removeMuteStatusChangeListener();
        }
        ctrl.removeStateChangeListener(dispatchStateChange);
        ctrl.removeProgressListener(dispatchProgress);
        ctrl.removePlaylistEndedListener(dispatchPlaylistEnded);
    }

    function getError() {
        return _playerController.getError();
    }

    function clearError() {
        _playerController.clearError();
    }

    function setSelectors(selectors) {
        if (isUsingBrowserPlayer()) {
            _playerController.setSelectors(selectors);
        }
    }

    function isReady() {
        return _playerController.isReady();
    }

    function isMuted() {
        return _playerController.isMuted();
    }

    function isUsingBrowserPlayer() {
        return _playerController === browserController;
    }

    function playPlaylist(playlistData) {
        browserController.initAudioElement();
        playlistManager.playPlaylist(playlistData);
    }

    function resume() {
        _playerController.resume();
    }

    function pause() {
        _playerController.pause();
    }

    function abortLoad() {
        playlistManager.abortLoad();
        _playerController.abortLoad();
    }

    function stop() {
        _playerController.stop();
    }

    function next() {
        playlistManager.next();
    }

    function previous() {
        playlistManager.previous();
    }

    function seek(seekRatio) {
        if (!isUsingBrowserPlayer()) {
            _playerController.seek(seekRatio);
        }
    }

    function setVolume(value) {
        _playerController.setVolume(value);
    }

    function toggleMute() {
        _playerController.toggleMute();
    }

    function currentTrack() {
        return _playerController.currentTrack();
    }

    function currentTrackTime() {
        return _playerController.currentTrackTime();
    }

    function currentTrackDuration() {
        return _playerController.currentTrackDuration();
    }

    function currentPlaylistMeta() {
        return playlistManager.getQueue().meta;
    }

    function playlistIsActive(type, id) {
        return playlistManager.playlistIsActive(type, id);
    }

    function shouldUpdatePlaybackBar() {
        return _playerController.shouldUpdatePlaybackBar();
    }

    function isPlaying() {
        return _playerController.isPlaying();
    }

    function isLoading() {
        return _playerController.isLoading();
    }

    function castDeviceIsConnecting() {
        return castController.isConnecting();
    }

    function castDeviceIsConnected() {
        return _castDeviceIsConnected;
    }

    function clearPlaylist() {
        playlistManager.clearPlaylist();
        _playerController.clearPlaylist();
    }

    function initCastDevice() {
        castController.initSession(onCastDeviceReady);
    }

    function onPlaylistUpdate() {
        var mediaQueue = playlistManager.getQueue();
        _playerController.play(mediaQueue.playlist, mediaQueue.position);
    }

    function onPlaylistPositionUpdate() {
        var currentPlaylist = playlistManager.getQueue();
        _playerController.jumpToPlaylistPosition(currentPlaylist.position);
    }

    function onPlaylistNextTrack() {
        _playerController.next();
    }

    function onPlaylistPreviousTrack() {
        _playerController.previous();
    }

    /**
     * Event listeners are added directly to this controller which manages
     * adding / removing of listeners on the concrete controllers.
     */
    function onWakeUp(cb) {
        _wakeUpHandlers.push(cb);
    }

    function onStateChange(cb) {
        _stateChangeHandlers.push(cb);
    }

    function onProgress(cb) {
        _progressHandlers.push(cb);
    }

    function onPlaylistEnded(cb) {
        _playlistEndedHandlers.push(cb);
    }

    function onVolumeChange(cb) {
        _volumeChangeHandlers.push(cb);
    }

    function onMuteStatusChange(cb) {
        _muteStatusChangeHandlers.push(cb);
    }

    function onCastDeviceStatusChange(cb) {
        _castDeviceStatusChangeHandlers.push(cb);
    }

    function dispatchWakeUp() {
        _.invoke(_wakeUpHandlers, _.call);
    }

    function dispatchStateChange() {
        _.invoke(_stateChangeHandlers, _.call);
    }

    function dispatchProgress() {
        _.invoke(_progressHandlers, _.call);
    }

    function dispatchPlaylistEnded() {
        playlistManager.clearPlaylist();
        playlistManager.abortLoad();
        _.invoke(_playlistEndedHandlers, _.call);
    }

    function dispatchVolumeChange(value) {
        _.invoke(_volumeChangeHandlers, _.call, undefined, value);
    }

    function dispatchMuteStatusChange(value) {
        _.invoke(_muteStatusChangeHandlers, _.call, undefined, value);
    }

    function dispatchCastDeviceStatusChange(isConnected) {
        if (isConnected !== undefined) {
            _castDeviceIsConnected = isConnected;
        }
        _.invoke(_castDeviceStatusChangeHandlers, _.call);
    }

    /**
     * Cast Specific Events
     */
    function onCastDeviceReady() {
        dispatchCastDeviceStatusChange(true);

        var mediaQueue = playlistManager.getQueue();
        var currentTime = currentTrackTime();
        pause();

        // We want to ensure the current pause listeners have fired before switching.
        setTimeout(function () {
            // Google guidelines dictate we must not set volume / mute status here
            // Instead our UI will update to reflect the reported state of the device.
            switchControllers();
            castController.play(mediaQueue.playlist, mediaQueue.position, currentTime);
        }, 0);
    }

    function onCastSessionStopped() {
        dispatchCastDeviceStatusChange(false);

        if (playlistManager.getQueue().playlist.length === 0) {
            switchControllers();
        } else {
            var mediaQueue = playlistManager.getQueue();
            var currentTime = currentTrackTime();
            var duration = currentTrackDuration();
            var isMute = isMuted();

            switchControllers();
            browserController.play(mediaQueue.playlist, mediaQueue.position);
            if (browserController.isMuted() !== isMute) {
                browserController.toggleMute();
            }

            setTimeout(function () {
                browserController.setPlayheadSeek(currentTime, duration);
            }, 0);
        }
    }

    return {
        setSelectors: setSelectors,
        isReady: isReady,
        isMuted: isMuted,
        isUsingBrowserPlayer: isUsingBrowserPlayer,

        playPlaylist: playPlaylist,
        pause: pause,
        resume: resume,
        stop: stop,
        next: next,
        seek: seek,
        previous: previous,
        setVolume: setVolume,
        toggleMute: toggleMute,
        abortLoad: abortLoad,
        clearPlaylist: clearPlaylist,

        isPlaying: isPlaying,
        isLoading: isLoading,
        getError: getError,
        clearError: clearError,
        currentTrack: currentTrack,
        currentPlaylistMeta: currentPlaylistMeta,
        playlistIsActive: playlistIsActive,

        onWakeUp: onWakeUp,
        onStateChange: onStateChange,
        onProgress: onProgress,
        onPlaylistEnded: onPlaylistEnded,
        onVolumeChange: onVolumeChange,
        onMuteStatusChange: onMuteStatusChange,
        onCastDeviceStatusChange: onCastDeviceStatusChange,

        initCastDevice: initCastDevice,

        castDeviceIsConnected: castDeviceIsConnected,
        castDeviceIsConnecting: castDeviceIsConnecting,
        shouldUpdatePlaybackBar: shouldUpdatePlaybackBar,
        currentTrackDuration: currentTrackDuration,
        currentTrackTime: currentTrackTime
    };
});

