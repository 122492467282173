define('webstore-client/pages/download-purchase',[
    'client-config',
    'webstore-client/analytics/track.ecommerce'
], function (clientConfig, trackEcommerce) {
    return function pageLoaded() {
        if (window.sdEcommerceData !== undefined) {
            trackEcommerce.trackTransaction(window.sdEcommerceData,
                clientConfig.shop.currencyCode);

            delete window.sdEcommerceData;
        }
    };
});

