define('webstore-client/pages/yourmusic',[
    'jquery',
    'webstore-client/utilities/history'
], function ($, history) {
    function performSearch(path, q, sortBy) {
        var url = path;
        var query = {};
        if (q) { query.q = q; }
        if (sortBy) { query.sortBy = sortBy; }

        var queryString = $.param(query);
        if (queryString) {
            url += '?' + queryString;
        }
        history.pushUrl(url);
    }

    function resetQueryValue($q) {
        var query = $q.val();
        $q.val('');
        $q.val(query);
    }

    return function () {
        var $form = $('.yourmusic-controls form');
        var $q = $('input[name="q"]', $form);
        var $sortBy = $('select[name="sortBy"]', $form);
        var path = $form.attr('action');
        var query;

        //This needs to be run every time and not in the initialised block as
        //we need to listen for 'submit' directly on the form element (it
        //doesn't bubble)
        $form.on('submit', function (e) {
            e.preventDefault();
            e.stopPropagation();

            $q.blur();
            resetQueryValue($q);

            if ($q.val() === $q.attr('value')) {
                performSearch(path, $q.val(), $sortBy.val());
            } else {
                performSearch(path, $q.val());
            }
        });

        $sortBy.on('change', function (e) {
            performSearch(path, $q.val(), $sortBy.val());
        });

        resetQueryValue($q);
    };
});

