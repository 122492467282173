define('template-live-search',['handlebars'], function(Handlebars) {

Handlebars.registerPartial("live-search-item-artist", Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return " is-focused";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "<li class=\"live-search-item-container"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.focused : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" data-itemId=\""
    + alias3(((helper = (helper = helpers.itemId || (depth0 != null ? depth0.itemId : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"itemId","hash":{},"data":data}) : helper)))
    + "\">\n	<a href=\""
    + alias3(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"url","hash":{},"data":data}) : helper)))
    + "\" class=\"live-search-item-link\">\n		<div class=\"live-search-details-artist\">\n			<strong class=\"live-search-primary-text\">"
    + alias3(((helper = (helper = helpers.mainText || (depth0 != null ? depth0.mainText : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"mainText","hash":{},"data":data}) : helper)))
    + "</strong>\n		</div>\n	</a>\n</li>\n";
},"useData":true}));

Handlebars.registerPartial("live-search-item", Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return " is-focused";
},"3":function(depth0,helpers,partials,data) {
    var helper;

  return "			<p class=\"live-search-secondary-text\">"
    + this.escapeExpression(((helper = (helper = helpers.subText || (depth0 != null ? depth0.subText : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"subText","hash":{},"data":data}) : helper)))
    + "</p>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "<li class=\"live-search-item-container"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.focused : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" data-itemId=\""
    + alias3(((helper = (helper = helpers.itemId || (depth0 != null ? depth0.itemId : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"itemId","hash":{},"data":data}) : helper)))
    + "\">\n	<a href=\""
    + alias3(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"url","hash":{},"data":data}) : helper)))
    + "\" class=\"live-search-item-link\">\n		<div class=\"live-search-image\">\n			<img src=\""
    + alias3(((helper = (helper = helpers.imageUrl || (depth0 != null ? depth0.imageUrl : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"imageUrl","hash":{},"data":data}) : helper)))
    + "\" alt=\""
    + alias3(((helper = (helper = helpers.mainText || (depth0 != null ? depth0.mainText : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"mainText","hash":{},"data":data}) : helper)))
    + "\">\n		</div>\n		<div class=\"live-search-details\">\n			<strong class=\"live-search-primary-text\">"
    + alias3(((helper = (helper = helpers.mainText || (depth0 != null ? depth0.mainText : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"mainText","hash":{},"data":data}) : helper)))
    + "</strong>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.subText : depth0),{"name":"if","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "		</div>\n	</a>\n</li>\n";
},"useData":true}));

return Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.hasItems : depth0),{"name":"if","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"2":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "<ul id=\"live-search-container\" class=\"live-search-container\">\n\n	<li class=\"live-search-see-all-results"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.noItemFocused : depth0),{"name":"if","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.hasItems : depth0),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "	</li>\n\n	<li class=\"live-search-section\">\n		<a href=\"/search/release?q="
    + alias3(((helper = (helper = helpers.searchTerm || (depth0 != null ? depth0.searchTerm : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"searchTerm","hash":{},"data":data}) : helper)))
    + "\" class=\"live-search-section-name\">"
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias1).call(depth0,"Albums",{"name":"translate","hash":{},"data":data}))
    + "</a>\n		<ul class=\"live-search-items\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.albums : depth0),{"name":"if","hash":{},"fn":this.program(7, data, 0),"inverse":this.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "		</ul>\n	</li>\n\n	<li class=\"live-search-section\">\n		<a href=\"/search/track?q="
    + alias3(((helper = (helper = helpers.searchTerm || (depth0 != null ? depth0.searchTerm : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"searchTerm","hash":{},"data":data}) : helper)))
    + "\" class=\"live-search-section-name\">"
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias1).call(depth0,"Tracks",{"name":"translate","hash":{},"data":data}))
    + "</a>\n		<ul class=\"live-search-items\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.tracks : depth0),{"name":"if","hash":{},"fn":this.program(12, data, 0),"inverse":this.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "		</ul>\n	</li>\n\n    <li class=\"live-search-section\">\n        <a href=\"/search/artist?q="
    + alias3(((helper = (helper = helpers.searchTerm || (depth0 != null ? depth0.searchTerm : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"searchTerm","hash":{},"data":data}) : helper)))
    + "\" class=\"live-search-section-name\">"
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias1).call(depth0,"Artists",{"name":"translate","hash":{},"data":data}))
    + "</a>\n        <ul class=\"live-search-items\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.artists : depth0),{"name":"if","hash":{},"fn":this.program(14, data, 0),"inverse":this.program(17, data, 0),"data":data})) != null ? stack1 : "")
    + "        </ul>\n    </li>\n</ul>\n";
},"3":function(depth0,helpers,partials,data) {
    return " is-focused";
},"5":function(depth0,helpers,partials,data) {
    var helper, alias1=helpers.helperMissing, alias2=this.escapeExpression;

  return "		<a href=\""
    + alias2(((helper = (helper = helpers.searchResultsUrl || (depth0 != null ? depth0.searchResultsUrl : depth0)) != null ? helper : alias1),(typeof helper === "function" ? helper.call(depth0,{"name":"searchResultsUrl","hash":{},"data":data}) : helper)))
    + "\">"
    + alias2((helpers.translate || (depth0 && depth0.translate) || alias1).call(depth0,"SeeSearchResultsFor",(depth0 != null ? depth0.searchTerm : depth0),{"name":"translate","hash":{},"data":data}))
    + "</a>\n";
},"7":function(depth0,helpers,partials,data) {
    var stack1, helper, options, buffer = "";

  stack1 = ((helper = (helper = helpers.albums || (depth0 != null ? depth0.albums : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"albums","hash":{},"fn":this.program(8, data, 0),"inverse":this.noop,"data":data}),(typeof helper === "function" ? helper.call(depth0,options) : helper));
  if (!helpers.albums) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"8":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = this.invokePartial(partials['live-search-item'],depth0,{"name":"live-search-item","data":data,"indent":"\t\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "");
},"10":function(depth0,helpers,partials,data) {
    return "			<li class=\"live-search-no-results\">"
    + this.escapeExpression((helpers.translate || (depth0 && depth0.translate) || helpers.helperMissing).call(depth0,"SearchNoResults",{"name":"translate","hash":{},"data":data}))
    + "</li>\n";
},"12":function(depth0,helpers,partials,data) {
    var stack1, helper, options, buffer = "";

  stack1 = ((helper = (helper = helpers.tracks || (depth0 != null ? depth0.tracks : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"tracks","hash":{},"fn":this.program(8, data, 0),"inverse":this.noop,"data":data}),(typeof helper === "function" ? helper.call(depth0,options) : helper));
  if (!helpers.tracks) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"14":function(depth0,helpers,partials,data) {
    var stack1, helper, options, buffer = "";

  stack1 = ((helper = (helper = helpers.artists || (depth0 != null ? depth0.artists : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"artists","hash":{},"fn":this.program(15, data, 0),"inverse":this.noop,"data":data}),(typeof helper === "function" ? helper.call(depth0,options) : helper));
  if (!helpers.artists) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"15":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = this.invokePartial(partials['live-search-item-artist'],depth0,{"name":"live-search-item-artist","data":data,"indent":"                    ","helpers":helpers,"partials":partials})) != null ? stack1 : "");
},"17":function(depth0,helpers,partials,data) {
    return "                <li class=\"live-search-no-results\">"
    + this.escapeExpression((helpers.translate || (depth0 && depth0.translate) || helpers.helperMissing).call(depth0,"SearchNoResults",{"name":"translate","hash":{},"data":data}))
    + "</li>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.searchTerm : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true})

});
