define('webstore-client/ui/basket/buy-buttons',[
    'jquery',
    'lodash',
    'webstore-client/utilities/history',
    'webstore-client/ui/basket/controller',
    'webstore-client/contentloading/controller',
    'webstore-client/ui/basket/buy-button-states',
    'webstore-client/locker/mark-owned'
], function ($, _, history, basketController, contentLoadingController, buttonStates, locker) {

    var initialised = false;
    var states = buttonStates.states;

    function bindButtons() {
        $('#page-container').on('submit', 'form[action$="/basket/add"]', function (e) {
            e.preventDefault();
            var pressedButton = $('button.buy-button', e.target);

            if (isClickable(pressedButton)) {
                if (pressedButton.hasClass(states.inBasket)) {
                    history.pushUrl('/checkout');
                    return;
                }

                var releaseId = $('input[name="releaseId"]', e.target).val();

                var pkg = $('input[name="packageId"]', e.target);
                var packageId;

                if (pkg.attr('type') === 'radio') {
                    packageId = $('input[name="packageId"]:checked').val();
                } else {
                    packageId = pkg.val();
                }

                var trackId = $('input[name="trackId"]', e.target).val();

                var addResult = addToBasket(releaseId, packageId, trackId);

                buttonStates.removeState(pressedButton, states.hasError);
                buttonStates.addState(pressedButton, states.adding);

                addResult.always(function () {
                    buttonStates.removeState(pressedButton, states.adding);
                });
                addResult.fail(function () {
                    buttonStates.addState(pressedButton, states.hasError);
                });
            }
        });
    }

    function isClickable(button) {
        return button.length > 0
            && !button.hasClass(states.adding);
    }

    function addToBasket(releaseId, packageId, trackId) {
        if (trackId) {
            return basketController.add(releaseId, packageId, trackId);
        } else {
            return basketController.add(releaseId, packageId);
        }
    }

    function markOwned() {
        locker.updateOwnership().done(locker.updateReleaseBuyButtons);
    }

    if (!initialised) {
        bindButtons();
        basketController.onUpdate(buttonStates.syncButtonsToBasket);
        contentLoadingController.onNavEnd(buttonStates.syncButtonsToBasket);
        contentLoadingController.onNavEnd(markOwned);

        buttonStates.syncButtonsToBasket();
        markOwned();

        initialised = true;
    }
});

