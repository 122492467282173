define('webstore-client/ui/basket/controller',[
    'client-config',
    'webstore-client/utilities/ajax',
    'jquery',
    'webstore-client/analytics/track',
    'webstore-client/utilities/location',
    'lodash',
    'debug'
], function (config, ajax, $, track, location, _, debug) {

    var listenerContext = $('<div></div>');

    var cachedBasketResponse;
    function cacheBasketResponse(basketResponse) {
        var toCache = _.cloneDeep(basketResponse);

        //These properties are transient so don't cache them
        delete toCache.basket.itemAdded;
        delete toCache.basket.itemRemoved;

        cachedBasketResponse = toCache;
    }

    function cachedBasketWithError() {
        var res = _.cloneDeep(cachedBasketResponse) || { basket: {} };
        res.basket.hasError = res.basket.shouldDisplay = true;
        return res;
    }

    function callEndpoint(ajaxParams) {
        var basketPromise = $.Deferred();
        var stopwatch = track.startNewStopwatch();
        var ajaxPromise = ajax(ajaxParams);

        ajaxPromise.done(function (basketResponse) {
            track.trackTiming('Add to Basket Latency', location.pathname,
                stopwatch.stop());

            if (!basketResponse || !basketResponse.basket) {
                debug.error('Malformed basket response', null, {
                    request: ajaxParams, response: basketResponse
                });
                return basketPromise.reject(cachedBasketWithError());
            }

            if (basketResponse.basket.hasError) {
                return basketPromise.reject(cachedBasketWithError());
            }

            basketPromise.resolve(basketResponse);
        });
        ajaxPromise.fail(function (jqXhr, textStatus) {
            if (jqXhr.status > 0) {
                //Not sure what happened here! Log it
                debug.ajaxError('Basket request failed', jqXhr, {
                    request: ajaxParams
                });
            }
            basketPromise.reject(cachedBasketWithError());
        });

        basketPromise.done(cacheBasketResponse);

        return basketPromise;
    }

    function triggerUpdatedEvent(basket) {
        listenerContext.trigger('basket-updated', basket);
    }

    function onUpdate(cb) {
        listenerContext.on('basket-updated', function (e, data) {
            cb(data);
        });
    }

    function add(releaseId, packageId, trackId) {
        var data = {
            releaseId: releaseId,
            packageId: packageId
        };
        if (trackId) { data.trackId = trackId; }
        return callEndpoint({
            background: true,
            url: config.root + '/basket/add',
            type: 'POST',
            data: $.param(data)
        })
            .always(triggerUpdatedEvent);
    }

    function remove(itemId) {
        var data = { itemId: itemId };
        return callEndpoint({
            background: true,
            url: config.root + '/basket/remove',
            type: 'POST',
            data: $.param(data)
        })
            .always(triggerUpdatedEvent);
    }

    var pendingFetch;
    function fetchBasket() {
        // Re-use the same deferred if it's still pending
        if (pendingFetch && pendingFetch.state() === 'pending') {
            return pendingFetch;
        }
        pendingFetch = callEndpoint({
            background: true,
            url: config.root + '/basket/items'
        });
        return pendingFetch;
    }

    function refresh() {
        return fetchBasket().always(triggerUpdatedEvent);
    }

    function getCurrent() {
        if (!cachedBasketResponse) {
            return fetchBasket();
        } else {
            return $.Deferred().resolve(cachedBasketResponse);
        }
    }

    return {
        add: add,
        remove: remove,
        refresh: refresh,
        onUpdate: onUpdate,
        getCurrent: getCurrent
    };
});

