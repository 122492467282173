define('webstore-client/ui/packshot-collection',[
    'jquery',
    'webstore-client/ui/lazyload'
], function ($, lazyload) {

    // Extracted functionality for toggling a packshot collection
    function showPackshotCollection(id) {
        if (!id) { return false; }
        var collection = $('.packshot-collection[data-collection-id=' + id + ']'),
            current = $('.packshot-collection.is-visible'),
            classList = 'is-visible is-hidden';

        current.toggleClass(classList);
        collection.toggleClass(classList);
    }

    function idsMatch(id1, id2) {
        // Eliminate type discrepancies, e.g. jQuery will auto-parse numbers
        // from data attributes but not from option values
        return id1.toString() === id2.toString();
    }

    // Packshot collection navigation handler - mobile
    $('#page-container').on('change', '.packshot-collection-navigation select', function (e) {
        var selectedClass = 'is-selected',
            selectedValue = $('option:selected', this).val(),
            menuItems = $(this).parent().find('li');

        // Add selected class to original menu item - for desktop UX
        menuItems.parent().find('.' + selectedClass).removeClass(selectedClass);
        menuItems.filter(function () {
            return idsMatch($('a', this).data('collection-id'), selectedValue);
        }).addClass(selectedClass);

        showPackshotCollection(selectedValue);

        setTimeout(function () {
            lazyload.revalidate();
        }, 0);
    });

    // Packshot collection navigation handler - desktop
    $('#page-container').on('click', '.packshot-collection-navigation a', function (e) {
        var selectedClass = 'is-selected',
            field = $('.packshot-collection-selection'),
            selectedValue = $(this).data('collection-id'),
            navigation = $(this).parent().parent();

        navigation.find('.' + selectedClass).removeClass(selectedClass);
        $(this).parent().addClass(selectedClass);

        // Select the correct option for the mobile UX
        $('option', field).filter(function () {
            return idsMatch($(this).val(), selectedValue);
        }).prop('selected', true);

        showPackshotCollection(selectedValue);

        lazyload.revalidate();
        e.preventDefault();
    });

});

