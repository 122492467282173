define('template-notice-wrong-shop',['handlebars'], function(Handlebars) {

return Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "	<p>"
    + this.escapeExpression((helpers.translate || (depth0 && depth0.translate) || helpers.helperMissing).call(depth0,"StoreUnavailableInRegion",{"name":"translate","hash":{},"data":data}))
    + "</p>\n";
},"3":function(depth0,helpers,partials,data) {
    var stack1, helper, options, buffer = "";

  stack1 = ((helper = (helper = helpers.onWrongShop || (depth0 != null ? depth0.onWrongShop : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"onWrongShop","hash":{},"fn":this.program(4, data, 0),"inverse":this.noop,"data":data}),(typeof helper === "function" ? helper.call(depth0,options) : helper));
  if (!helpers.onWrongShop) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"4":function(depth0,helpers,partials,data) {
    var alias1=helpers.helperMissing, alias2=this.escapeExpression;

  return "	<p>"
    + alias2((helpers.translate || (depth0 && depth0.translate) || alias1).call(depth0,"OnWrongShop",{"name":"translate","hash":{},"data":data}))
    + "</p>\n	<i class=\"flag-icon s-"
    + alias2((helpers.lowerCase || (depth0 && depth0.lowerCase) || alias1).call(depth0,(depth0 != null ? depth0.expectedCountryCode : depth0),{"name":"lowerCase","hash":{},"data":data}))
    + "\"></i>\n	<a data-allow-partial=\"false\" href=\"/shop-suggest\">\n		"
    + alias2((helpers.translate || (depth0 && depth0.translate) || alias1).call(depth0,"GoToExpectedShop",(depth0 != null ? depth0.expectedHost : depth0),{"name":"translate","hash":{},"data":data}))
    + "\n	</a>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<aside class=\"header-notice-warning notice-wrong-shop\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.noShopAvailable : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</aside>\n";
},"useData":true})

});
