/*
 * Live search module for 7digital.com
 * (c) 7digital.com
 */

define('webstore-client/ui/search/live-search',[
    'jquery',
    'lodash',
    'webstore-client/analytics/track',
    'webstore-client/ui/search/live-search-data',
    'template-live-search',
    'webstore-client/utilities/history',
    'debug',
    'webstore-client/utilities/media-query',
    'webstore-client/ui/search/get-model-items'
], function ($, _, track, liveSearchData,
             template, history, debug, mediaQuery, getModelItems) {

    var _cache = {};
    var _model = {};
    var _persistentFocusedItemId = -1;
    var _searchAbort = false;

    var _key = {
        enter: 13,
        esc: 27,
        left: 37,
        up: 38,
        right: 39,
        down: 40
    };

    function getSearchContainer() {
        _cache.$searchContainer = _cache.$searchContainer || $('.header-search');
        return _cache.$searchContainer;
    }

    function getSearchForm() {
        _cache.$searchForm = _cache.$searchForm || getSearchBox().closest('form');
        return _cache.$searchForm;
    }

    function getSearchBox() {
        _cache.$searchBox = _cache.$searchBox || $('#search-input');
        return _cache.$searchBox;
    }

    function getSearchLabel() {
        _cache.$searchLabel = _cache.$searchLabel || $('.header-search-label');
        return _cache.$searchLabel;
    }

    function getSearchTerm() {
        return $.trim(getSearchBox().val());
    }

    function shouldSearchForTerm(searchTerm) {
        return (searchTerm && searchTerm.length > 1);
    }

    function showLoadingFeedback() {
        getSearchLabel().addClass('is-waiting');
    }

    function hideLoadingFeedback() {
        getSearchLabel().removeClass('is-waiting');
    }

    function getDropDown() {
        return _cache.dropDown;
    }

    function dropDownIsShowing() {
        var dropDown = getDropDown();

        if (dropDown) {
            return (!dropDown.hasClass('is-hidden'));
        }

        return false;
    }

    function hideDropDown() {
        var dropDown = getDropDown();

        if (dropDown) {
            dropDown.addClass('is-hidden');
            dropDown.attr('aria-hidden', 'true');
        }
    }

    function showDropDown() {
        var dropDown = getDropDown();

        if (dropDown) {
            dropDown.removeClass('is-hidden');
            dropDown.removeAttr('aria-hidden');
        }
        buildDropDown();
    }

    function buildDropDown() {
        var newDropDown = $(template(_model));
        var oldDropDown = getDropDown();

        if (oldDropDown) {
            oldDropDown.remove();
        }

        if (newDropDown.length && getSearchTerm().length > 1) {
            getSearchContainer().append(newDropDown);
            _cache.dropDown = newDropDown;
        }
    }

    function performSearch() {

        //Capture the search term as it can change before AJAX request completes
        var requestSearchTerm = _model.searchTerm;

        if (requestSearchTerm !== _model.searchTermUsedForVisibleResults) {
            // prevent multiple AJAX requests for the same term
            // e.g. for modifier keypresses

            showLoadingFeedback();
            liveSearchData.updateModelWithResults(_model, function () {

                _persistentFocusedItemId = -1;
                showDropDown();

                if (requestSearchTerm === _model.searchTerm) {
                    //If the term has changed, another request has been fired since,
                    //so wait for that one to hide the feedback
                    hideLoadingFeedback();
                }

            });
        }
    }

    function getItems() {
        if (_model.hasItems) {
            return getModelItems(_model);
        }
        return [];
    }

    //Gets the position of an item within it's category, i.e. the 0th/1st/2nd track

    function getItemSubId(item) {
        var filtered = _.filter(getItems(), function (x) {
            return (x.type === item.type);
        });
        return _.indexOf(filtered, item);
    }

    function focusItem(id) {
        id = parseInt(id, 10);

        if (id !== _model.currentlyFocusedItemId) {
            unfocusAllItems(false);
            getItems()[id].focused = true;
            _model.noItemFocused = false;
            _model.currentlyFocusedItemId = id;
            getSearchContainer().find('li[data-itemid="' + id + '"]').addClass('is-focused').focus();
        }
    }

    function unfocusAllItems(performRender) {
        if (_model.hasItems && !_model.noItemFocused) {
            getItems()[_model.currentlyFocusedItemId].focused = false;
            _model.noItemFocused = true;
            _model.currentlyFocusedItemId = -1;
            getSearchContainer().find('.is-focused').removeClass('is-focused').blur();
        }
    }

    function focusNextItem() {
        var currentFocus = _model.currentlyFocusedItemId;

        if (!_model.hasItems) {
            return;
        }

        if (_model.noItemFocused) {
            focusItem(0);
        } else if (currentFocus < (_model.itemCount - 1)) {
            focusItem(currentFocus + 1);
        } else {
            unfocusAllItems();
        }
    }

    function focusPreviousItem() {
        var currentFocus = _model.currentlyFocusedItemId;

        if (!_model.hasItems) {
            return;
        }

        if (_model.noItemFocused) {
            focusItem(_model.itemCount - 1);
        } else if (currentFocus > 0) {
            focusItem(currentFocus - 1);
        } else {
            unfocusAllItems();
        }
    }

    function trackClickEvent(e) {
        var itemInDom = $(e.target).closest('.live-search-item-container');
        if (itemInDom.length) {
            var itemId = parseInt(itemInDom.attr('data-itemId'), 10);
            trackItemClick(itemId);
        } else {
            trackFullSearch();
        }
    }

    function trackItemClick(itemId) {
        var item = getItems()[itemId];
        track.event(
            'Live Search Click-through',
            item.type,
            getSearchTerm().toLowerCase(), (getItemSubId(item) + 1)
        );
    }

    function trackFullSearch() {
        track.event(
            'Show More Search Results',
            'Live Search',
            getSearchTerm().toLowerCase()
        );
    }

    function navigateToItem(id) {
        var item = getItems()[id];
        getSearchBox().blur();
        history.pushUrl(item.url);
        trackItemClick(id);
    }

    function navigateToSearchResults() {
        var url = _model.searchResultsUrl;
        getSearchBox().blur();
        history.pushUrl(url);
        trackFullSearch();
    }

    function searchTermHasChanged() {
        return (_model.searchTerm !== getSearchTerm());
    }

    function killPreviousSearches() {
        //Old searches are now invalid, signal to searches queued in the debouncer
        //that they should be aborted
        _searchAbort = true;
        liveSearchData.cancelPendingUpdates();

        if (searchTermHasChanged()) {
            unfocusAllItems();
        }
    }

    var performSearchWithDebounce = _.debounce(function () {
        if (!_searchAbort) {
            performSearch();
        }
    }, 300);

    function performDebouncedSearch() {
        _searchAbort = false;
        performSearchWithDebounce();
    }

    function createDropDownIfRequired() {
        var searchTerm = getSearchTerm();

        killPreviousSearches();
        liveSearchData.updateModelForSearchTerm(_model, searchTerm);

        if (shouldSearchForTerm(searchTerm)) {
            performDebouncedSearch();
            showDropDown();
        } else {
            hideDropDown();
        }
    }

    function handleSearchBoxKeyUp(e) {
        var keyCode = e.which;

        if (keyCode === _key.esc) {
            getSearchBox().val('');
            hideDropDown();
        }

        if (!_.contains(_key, keyCode)) {
            createDropDownIfRequired();
        }
    }

    function handleSearchBoxKeyDown(e) {
        var keyCode = e.which;

        if (keyCode === _key.down) {
            focusNextItem();
            e.preventDefault();
            _persistentFocusedItemId = _model.currentlyFocusedItemId;
        } else if (keyCode === _key.up) {
            focusPreviousItem();
            _persistentFocusedItemId = _model.currentlyFocusedItemId;
            e.preventDefault();
        }
    }

    function focusItemFromMouseEvent(e) {
        var item = $(e.target).closest('.live-search-item-container');
        if (item.length) {
            var itemId = parseInt(item.attr('data-itemId'), 10);
            focusItem(itemId);
        } else {
            unfocusAllItems();
        }
    }

    function bindEvents() {
        getSearchBox().on('keyup', handleSearchBoxKeyUp);

        getSearchBox().on('keydown', handleSearchBoxKeyDown);

        getSearchBox().on('focus', function (e) {
            createDropDownIfRequired();

            // don't bubble-up to body focusin/click listener
            e.stopPropagation();
        });

        getSearchContainer().on('mousemove', '.live-search-item-link', _.throttle(function (e) {
            focusItemFromMouseEvent(e);
        }, 100));

        getSearchContainer().on('mousemove', '.live-search-see-all-results', _.throttle(function (e) {
            unfocusAllItems();
        }, 100));

        getSearchContainer().on('mouseleave', '#live-search-container', function () {
            //Mouseleave also fires when the dropdown gets hidden by e.g. a click handler,
            //so we check if this is a 'legit' mouseleave event, i.e. the drop down is actually showing.
            if (dropDownIsShowing()) {
                if (_persistentFocusedItemId !== -1) {
                    focusItem(_persistentFocusedItemId);
                } else {
                    unfocusAllItems();
                }
            }
        });

        getSearchContainer().on('click', '#live-search-container a', function (e) {
            _persistentFocusedItemId = -1;

            //Hide if click is inside the drop down on a link
            hideDropDown();
            trackClickEvent(e);
        });

        $('body').on('focus click', function (e) {
            var targetInSearchTools = getSearchContainer().find(e.target);
            if (targetInSearchTools.length === 0 || $(e.target).is('.header-home-link')) {
                //Don't hide if click focus is in the search form area or home link
                hideDropDown();
            }
        });

        getSearchForm().submit(function (e) {
            e.preventDefault();
            e.stopPropagation();

            //Make sure we have the latest search term as submit can happen
            //through various means before a livesearch has triggered.
            liveSearchData.updateModelForSearchTerm(_model, getSearchTerm());
            killPreviousSearches();

            if (dropDownIsShowing()) {
                var currentFocus = _model.currentlyFocusedItemId;
                var persistentFocus = _persistentFocusedItemId;
                // ensure currently focused item was focused by the keyboard to prevent
                // navigating to a mouseover`d item, otherwise, go to full search
                if (persistentFocus !== -1 && currentFocus === persistentFocus) {
                    navigateToItem(persistentFocus);
                } else {
                    navigateToSearchResults();
                    unfocusAllItems();
                    _persistentFocusedItemId = -1;
                }

                hideDropDown();
            } else {
                navigateToSearchResults();
            }
        });
    }

    function unBindEvents() {
        getSearchBox().off('keyup', handleSearchBoxKeyUp);
        getSearchBox().off('keydown', handleSearchBoxKeyDown);
        getSearchBox().off('focus');
        getSearchContainer().off('mousemove', '.live-search-item-link');
        getSearchContainer().off('mousemove', '.live-search-see-all-results');
        getSearchContainer().off('mouseleave', '#live-search-container');
        getSearchContainer().off('click', '#live-search-container a');
        getSearchForm().off('submit');
        if (getDropDown()) { getDropDown().remove(); }
    }

    function updateBindings() {
        function update() {
            if (mediaQuery.matches('less-than-tablet-portrait')) {
                unBindEvents();
            } else {
                bindEvents();
            }
        }
        $(window).on('viewportChange', update);
        update();
    }

    function initialise() {
        var stopwatch = track.startNewStopwatch();

        var formAction = getSearchForm().attr('action');
        liveSearchData.init(formAction);

        _model.searchTerm = getSearchTerm();
        _model.hasItems = false;
        _model.noItemFocused = true;
        _model.currentlyFocusedItemId = -1;

        updateBindings();

        // Build drop-drown if search term has been entered before JS has initialised
        if (getSearchBox().is(':focus')) {
            createDropDownIfRequired();
        }

        var timeTaken = stopwatch.stop();
        track.trackTiming('Performance', 'Live Search Initialisation', timeTaken);
    }

    function destroy() {
        unBindEvents();
        _cache = {};
        _model = {};
    }

    return {
        init: initialise,
        destroy: destroy
    };
});

