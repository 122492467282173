define('webstore-client/ui/search/submit-filter',[
    'webstore-client/utilities/location',
    'webstore-client/contentloading/navigation',
    'jquery',
    'webstore-client/lib/jsurl',
    'webstore-client/utilities/urlhelpers',
    'webstore-client/analytics/track'
], function (location, navigation, $, Url, urlHelper, analytics) {

    function trackFilterUpdate(appliedFilters) {
        analytics.event('Filter', 'Search', appliedFilters.toString());
    }

    return function submitFilter(event) {
        event.preventDefault();

        var search = new Url(location.toString());
        var filterQuery;

        var filter = $(this).find(':checked').map(function (i, el) {
            return el.value;
        }).toArray();

        trackFilterUpdate(filter.join(','));

        filter = encodeURIComponent(filter.join(','));
        filterQuery = filter.length ? '&f=' + filter : '';

        var currentPath = urlHelper.getPathName();
        navigation.partialPageLoad(currentPath + '?q=' + search.query.q + filterQuery);
    };
});

