/*
 * Ajax content loader module - does an ajax request for a partial page refresh
 * Functionality for 7digital.com
 * (c) 7digital.com
 */

define('webstore-client/contentloading/fetcher',[
    'jquery',
    'webstore-client/utilities/ajax',
    'debug',
    'webstore-client/analytics/track',
    'template-error-message',
    'template-ajax-layout',
    'translate',
    'client-config'
], function ($, ajax, debug, track, renderErrorMessage, renderAjaxLayout, translate, config) {

    function renderFullErrorResponse() {
        var errorBody = renderErrorMessage();
        var locals = {
            title: translate('Error500Title'),
            body: errorBody,
            shop: config.shop
        };

        return renderAjaxLayout(locals);
    }

    function fetchContent(url, progressCb, callback) {
        var stopwatch;
        debug.log('ajaxing to ' + url);
        stopwatch = track.startNewStopwatch();

        return ajax({
            url: url,
            xhr: function () {
                var xhr = $.ajaxSettings.xhr();
                if (xhr.addEventListener) {
                    xhr.addEventListener('progress', function (e) {
                        if (e.lengthComputable) {
                            progressCb(e.loaded / e.total);
                        }
                    }, false);
                }
                return xhr;
            }
        }).done(function (data) {
            track.trackTiming('Partial Page Load', 'Response Time',
                stopwatch.stop());

            return callback(data);
        }).fail(function (jqXhr, textStatus, errorThrown) {
            debug.ajaxError('Partial page request failed', jqXhr, { url: url });

            // Indicates another link was clicked before this one finished
            if (textStatus === 'abort') { return; }

            // Use error response from server
            if (jqXhr.status > 0) { return callback(jqXhr.responseText); }

            //Render error on client side as last resort - user offline?
            return callback(renderFullErrorResponse());
        });
    }

    return {
        fetchContent: fetchContent
    };
});

