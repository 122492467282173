define('template-header-basket',['handlebars'], function(Handlebars) {

return Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "<li class=\"header-basket\" id=\"header-basket\">\n	<a class=\"header-basket-link\" href=\"/checkout\">\n		<p class=\"icon-basket\">\n			<em class=\"header-basket-total\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.isFree : depth0),{"name":"if","hash":{},"fn":this.program(2, data, 0),"inverse":this.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "			</em>\n			<em class=\"header-basket-quantity\">("
    + this.escapeExpression(((helper = (helper = helpers.itemCount || (depth0 != null ? depth0.itemCount : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"itemCount","hash":{},"data":data}) : helper)))
    + ")</em>\n		</p>\n	</a>\n</li>\n";
},"2":function(depth0,helpers,partials,data) {
    return "				"
    + this.escapeExpression((helpers.translate || (depth0 && depth0.translate) || helpers.helperMissing).call(depth0,"FreePrice",{"name":"translate","hash":{},"data":data}))
    + "\n";
},"4":function(depth0,helpers,partials,data) {
    var helper;

  return "				"
    + this.escapeExpression(((helper = (helper = helpers.formattedTotalPrice || (depth0 != null ? depth0.formattedTotalPrice : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"formattedTotalPrice","hash":{},"data":data}) : helper)))
    + "\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper, options, buffer = "";

  stack1 = ((helper = (helper = helpers.basket || (depth0 != null ? depth0.basket : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"basket","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data}),(typeof helper === "function" ? helper.call(depth0,options) : helper));
  if (!helpers.basket) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true})

});
