(function () {
    define('webstore-client/ui/playback/player/google-cast/cast-controller',[
        'debug',
        'webstore-client/ui/playback/player/google-cast/cast-track-adapter',
        'webstore-client/ui/playback/player/google-cast/google-cast-proxy',
        'webstore-client/ui/playback/player/google-cast/cast-detection',
        'webstore-client/analytics/track',
        'lodash'
    ], function (debug, mediaAdapter, googleCast, castDetection, analytics, _) {

        var PAUSE_OPERATION = 'PAUSE_OPERATION';
        var RESUME_OPERATION = 'RESUME_OPERATION';
        var STOP_OPERATION = 'STOP_OPERATION';
        var SEEK_OPERATION = 'SEEK_OPERATION';
        var JUMP_OPERATION = 'JUMP_OPERATION';
        var PREVIOUS_OPERATION = 'PREVIOUS_OPERATION';
        var NEXT_OPERATION = 'NEXT_OPERATION';
        var ABORT_OPERATION = 'ABORT_OPERATION';
        var QUEUE_LOAD_OPERATION = 'QUEUE_LOAD_OPERATION';
        var STATUS_OPERATION = 'STATUS_OPERATION';

        var MAX_REFRESH_TRIES = 2;
        var ERROR_UNAVAILABLE = 'PreviewNotAvailable';

        var _chrome = googleCast.chrome;
        var _adapter = mediaAdapter;
        var _isReady = false;
        var _error;
        var _session;
        var _isMuted = false;
        var _currentMedia;
        var _currentTrack;
        var _currentTrackDuration;
        var _currentTrackTime;
        var _currentVolume;
        var _internalTimer;
        var _playlistUpdateInProgress;
        var _attemptedRefreshes;
        var _onRequestSessionCallback;
        var _onProgressUpdateCallback;
        var _onStateChangeCallback;
        var _onPlaylistEndedCallback;
        var _onSessionStoppedCallback;
        var _onVolumeChangeCallback;
        var _onMuteStatusChangeCallback;
        var _playingLastTrack;
        var _onReceiverActionCallback;
        var _isConnecting;
        var _sessionStartTime;
        var _currentTrackTitle;

        /********************
         * Public API
         *******************/
        function initSession(cb) {
            _onRequestSessionCallback = cb;

            var session = castDetection.getExistingSession();
            if (session !== undefined) {
                onRequestSessionSuccess(session);
            } else {
                _chrome.cast.removeReceiverActionListener(receiverActionHandler);
                _chrome.cast.addReceiverActionListener(receiverActionHandler);
                _chrome.cast.requestSession(onRequestSessionSuccess, onLaunchError);
            }
        }

        function receiverActionHandler(receiver, action) {
            _isConnecting = action === _chrome.cast.ReceiverAction.CAST;
            if (_onReceiverActionCallback) {
                _onReceiverActionCallback();
            }
        }

        function play(playlist, position, currentTrackTime) {
            stop();
            _attemptedRefreshes = 0;
            _playlistUpdateInProgress = true;
            _currentTrackTime = currentTrackTime || 0;
            _adapter.convertTracklistToMediaInfoList(playlist, onQueueItemsReady.bind(this, position));
            forceTrackInfoUpdate(position);
        }

        function pause() {
            _currentMedia.pause(new _chrome.cast.media.PauseRequest(),
                onMediaCommandSuccess, onMediaError.bind(this, PAUSE_OPERATION));
            stopInternalStatusUpdate();
        }

        function resume() {
            _currentMedia.play(new _chrome.cast.media.PlayRequest(),
                onMediaCommandSuccess, onMediaError.bind(this, RESUME_OPERATION));
            startInternalStatusUpdate();
        }

        function stop() {
            stopInternalStatusUpdate();
            if (!_currentMedia) {
                return;
            }
            _currentMedia.stop(new _chrome.cast.media.StopRequest(), onMediaCommandSuccess, onMediaError.bind(this, null));
            _currentTrackTime = 0;
            notifyUpdate();
        }

        function seek(seekRatio) {
            var request = new _chrome.cast.media.SeekRequest();
            request.currentTime = Math.floor(seekRatio * currentTrackDuration());
            _currentMedia.seek(request, onMediaCommandSuccess,
                onMediaError.bind(this, SEEK_OPERATION));
        }

        function previous() {
            forceTrackInfoUpdate(getCurrentTrackIndex() - 1);

            _currentMedia.queuePrev(onMediaCommandSuccess,
                onMediaError.bind(this, PREVIOUS_OPERATION));
        }

        function next() {
            forceTrackInfoUpdate(getCurrentTrackIndex() + 1);

            _currentMedia.queueNext(onMediaCommandSuccess,
                onMediaError.bind(this, NEXT_OPERATION));
        }

        function jumpToPlaylistPosition(position) {
            _currentMedia.queueJumpToItem(_currentMedia.items[position].itemId,
                onMediaCommandSuccess, onMediaError.bind(this, JUMP_OPERATION));

            forceTrackInfoUpdate(position);
        }

        function setVolume(level) {
            _session.setReceiverVolumeLevel(level, onMediaCommandSuccess, onMediaError);
        }

        function toggleMute() {
            _isMuted = !_isMuted;
            _session.setReceiverMuted(_isMuted, onMediaCommandSuccess, onMediaError);
            // Some devices (eg the LG Music Flow), don't natively support a MUTE capability.
            // To provide the best user-experience we mute the audio-in (our stream) as well.
            _currentMedia.setVolume(new _chrome.cast.media.VolumeRequest(new _chrome.cast.Volume(null, _isMuted)),
                onMediaCommandSuccess, onMediaError);
        }

        function getError() {
            return _error;
        }

        function clearError() {
            _error = undefined;
        }

        function clearPlaylist() {
            stop();
            _session.stop();
        }

        function isReady() {
            return _isReady;
        }

        function isMuted() {
            return _isMuted;
        }

        function isConnecting() {
            return _isConnecting;
        }

        function abortLoad() {
            stopInternalStatusUpdate();
            stop();
        }

        function currentTrack() {
            return _currentTrack;
        }

        function currentTrackTime() {
            return _currentTrackTime;
        }

        function currentTrackDuration() {
            return _currentTrackDuration;
        }

        function isPlaying() {
            if (hasError()) {
                return false;
            }
            return _currentMedia ? _currentMedia.playerState === _chrome.cast.media.PlayerState.PLAYING : false;
        }

        function isLoading() {
            if (hasError()) {
                return false;
            }
            if (_playlistUpdateInProgress) {
                return true;
            }
            return _currentMedia ? _currentMedia.playerState === _chrome.cast.media.PlayerState.BUFFERING : true;
        }

        function shouldUpdatePlaybackBar() {
            return true;
        }

        function onStateChange(cb) {
            _onStateChangeCallback = cb;
        }

        function onProgress(cb) {
            _onProgressUpdateCallback = cb;
        }

        function onPlaylistEnded(cb) {
            _onPlaylistEndedCallback = cb;
        }

        function onVolumeChange(cb) {
            _onVolumeChangeCallback = cb;
        }

        function onMuteStatusChange(cb) {
            _onMuteStatusChangeCallback = cb;
        }

        function onSessionStopped(cb) {
            _onSessionStoppedCallback = cb;
        }

        function onReceiverAction(cb) {
            _onReceiverActionCallback = cb;
        }

        function removeStateChangeListener(cb) {
            _onStateChangeCallback = null;
        }

        function removeProgressListener(cb) {
            _onProgressUpdateCallback = null;
        }

        function removePlaylistEndedListener(cb) {
            _onPlaylistEndedCallback = null;
        }

        function removeVolumeChangeListener(cb) {
            _onVolumeChangeCallback = null;
        }

        function removeMuteStatusChangeListener(cb) {
            _onMuteStatusChangeCallback = null;
        }

        function removeReceiverActionListener() {
            _onReceiverActionCallback = null;
        }

        /********************
         * Internal Methods
         *******************/
        function startInternalStatusUpdate() {
            stopInternalStatusUpdate();
            if (_session !== null) {
                _internalTimer = setInterval(onInternalStatusUpdate, 500);
            }
        }

        function stopInternalStatusUpdate() {
            if (_internalTimer !== undefined) {
                clearInterval(_internalTimer);
            }
        }

        function refreshDeadMediaQueue() {
            analytics.event('Cast', 'Error', 'Stream URL Expired');

            var restartPosition = _currentMedia.media.metadata.trackNumber - 1;
            _currentTrackTime = 0;
            _attemptedRefreshes++;
            _adapter.refreshTracks(onQueueItemsReady.bind(this, restartPosition));
        }

        function updateCurrentTrack() {
            var currentIndex = getCurrentTrackIndex();
            _currentTrack = _adapter.getOriginalTrackByIndex(currentIndex);
            _currentTrackDuration = _currentMedia.media.duration;
        }

        function handleMediaChange(nextMedia) {
            if (_currentMedia) {
                _currentMedia.removeUpdateListener(onMediaStatusUpdate);
            }
            _currentMedia = nextMedia;
            _currentMedia.addUpdateListener(onMediaStatusUpdate);
        }

        function readDeviceVolume(volume) {
            var volumeChanged = volume.level !== _currentVolume;
            var muteStatusChanged = volume.muted !== _isMuted;

            _currentVolume = volume.level;
            _isMuted = volume.muted;

            if (volumeChanged && _onVolumeChangeCallback) {
                _onVolumeChangeCallback(_currentVolume);
            }

            if (muteStatusChanged && _onMuteStatusChangeCallback) {
                _onMuteStatusChangeCallback(_isMuted);
            }
        }

        function reset() {
            stopInternalStatusUpdate();
            if (_currentMedia) {
                _currentMedia.removeUpdateListener(onMediaStatusUpdate);
            }
            if (_session) {
                _session.removeUpdateListener(onSessionUpdate);
            }

            // Don't reset _currentTrackTime - it's needed for seamless switch to jPlayer
            _isReady = false;
            _session = null;
            _currentMedia = null;
            _currentTrack = null;
        }

        function getCurrentTrackIndex() {
            return _.findIndex(_currentMedia.items, {itemId:_currentMedia.currentItemId}, _currentMedia.items);
        }

        function forceTrackInfoUpdate(position) {
            _currentTrack = _adapter.getOriginalTrackByIndex(position);
            notifyUpdate();
        }

        function notifyUpdate() {
            if (_onStateChangeCallback !== undefined) {
                _onStateChangeCallback();
            }
        }

        function showError(key, isFatal) {
            _error = {translationKey:key, isFatal:isFatal};
            notifyUpdate();
        }

        function hasError() {
            return _error !== undefined;
        }

        /*******************
         * Events
         *******************/
        function onRequestSessionSuccess(session) {
            var hasVideoOut = session.receiver.capabilities.indexOf(_chrome.cast.Capability.VIDEO_OUT) > -1;
            analytics.event('Cast', 'Initialize', hasVideoOut ? 'VIDEO_OUT' : 'NO_VIDEO_OUT');

            _sessionStartTime = new Date().getTime();
            _session = session;
            _isReady = true;
            _isConnecting = false;
            _session.addUpdateListener(onSessionUpdate);
            _onRequestSessionCallback();
        }

        function onSessionUpdate() {
            readDeviceVolume(_session.receiver.volume);

            if (_session.status === _chrome.cast.SessionStatus.STOPPED) {

                var sessionLength = new Date().getTime() - _sessionStartTime;
                analytics.event('Cast', 'End', String(sessionLength / 1000));

                reset();
                if (_onSessionStoppedCallback !== undefined) {
                    _onSessionStoppedCallback();
                }
            }
        }

        function onQueueItemsReady(position, mediaInfoQueue) {
            _playlistUpdateInProgress = false;
            var queueLoadRequest = new _chrome.cast.media.QueueLoadRequest(mediaInfoQueue);
            queueLoadRequest.startIndex = position;
            queueLoadRequest.items[position].startTime = _currentTrackTime;
            _session.queueLoad(queueLoadRequest, onMediaDiscovered, onMediaError.bind(this, QUEUE_LOAD_OPERATION));
        }

        function onInternalStatusUpdate() {
            if (_currentMedia &&
                _currentMedia.playerState === _chrome.cast.media.PlayerState.IDLE &&
                !_playlistUpdateInProgress
            ) {
                stopInternalStatusUpdate();
                if (_currentMedia.idleReason === _chrome.cast.media.IdleReason.FINISHED && _playingLastTrack) {
                    stop();
                    _onPlaylistEndedCallback();
                } else {
                    // Stream *might* have failed
                    // See http://stackoverflow.com/questions/32223498/google-cast-detect-queueitems-that-fail-to-load
                    _currentMedia.getStatus(new _chrome.cast.media.GetStatusRequest(),
                        onMediaCommandSuccess.bind(this, true), onMediaError.bind(this, STATUS_OPERATION));
                }
            } else {
                _currentTrackTime = _currentMedia ? _currentMedia.getEstimatedTime() : 0;
                if (_onProgressUpdateCallback) {
                    _onProgressUpdateCallback();
                }
            }
        }

        function onMediaStatusUpdate(isAlive) {
            if (isAlive === false && !_playlistUpdateInProgress &&
                _currentMedia.idleReason !== _chrome.cast.media.IdleReason.FINISHED) {
                // If we're dead for reasons other than a track finished, we assume a stream expiration
                if (canAttemptMediaRefresh()) {
                    return refreshDeadMediaQueue();
                } else {
                    return showError(ERROR_UNAVAILABLE, false);
                }
            } else if (_currentMedia.playerState !== _chrome.cast.media.PlayerState.IDLE) {
                if (_currentMedia.items.length > 0) {
                    _playingLastTrack = _currentMedia.currentItemId === _currentMedia.items[_currentMedia.items.length - 1].itemId;
                }
                _attemptedRefreshes = 0;
                updateCurrentTrack();
                notifyUpdate();

                if (_currentMedia.media.metadata.title !== _currentTrackTitle) {
                    analytics.event('Cast', 'Track', String(_currentMedia.media.metadata.artist + ' - ' + _currentMedia.media.metadata.title));
                    _currentTrackTitle = _currentMedia.media.metadata.title;
                }
            }
        }

        function canAttemptMediaRefresh() {
            return _attemptedRefreshes < MAX_REFRESH_TRIES;
        }

        function onMediaDiscovered(media) {
            handleMediaChange(media);
            startInternalStatusUpdate();
            readDeviceVolume(_session.receiver.volume);
        }

        function onMediaCommandSuccess(restartInternalUpdate) {
            if (restartInternalUpdate) {
                startInternalStatusUpdate();
            }
        }

        function onLaunchError(error) {
            stopInternalStatusUpdate();
            if (error.code !== _chrome.cast.ErrorCode.CANCEL) {
                debug.error(error);
            }
        }

        function onMediaError(operation, error) {
            stopInternalStatusUpdate();
            if (operation !== null) {
                debug.error(operation + ' error', error);
            }
            if (operation === QUEUE_LOAD_OPERATION) {
                /*
                  If the Queue fails to load before a track has even started playing, we make
                  the assumption that the stream is not available for preview.
                 */
                analytics.event('Cast', 'Error', 'Preview Not Available');
                showError(ERROR_UNAVAILABLE, false);
            }
        }

        return {
            initSession: initSession,
            isReady: isReady,
            isMuted: isMuted,
            isConnecting: isConnecting,

            play: play,
            pause: pause,
            resume: resume,
            stop: stop,
            seek: seek,
            next: next,
            previous: previous,
            jumpToPlaylistPosition: jumpToPlaylistPosition,
            setVolume: setVolume,
            toggleMute: toggleMute,
            clearPlaylist: clearPlaylist,
            reset: reset,
            abortLoad: abortLoad,

            isPlaying: isPlaying,
            isLoading: isLoading,
            getError: getError,
            clearError: clearError,
            currentTrack: currentTrack,

            onStateChange: onStateChange,
            onProgress: onProgress,
            onPlaylistEnded: onPlaylistEnded,
            onSessionStopped: onSessionStopped,
            onVolumeChange: onVolumeChange,
            onMuteStatusChange: onMuteStatusChange,
            onReceiverAction: onReceiverAction,

            removeStateChangeListener: removeStateChangeListener,
            removeProgressListener: removeProgressListener,
            removePlaylistEndedListener: removePlaylistEndedListener,
            removeVolumeChangeListener: removeVolumeChangeListener,
            removeMuteStatusChangeListener: removeMuteStatusChangeListener,
            removeReceiverActionListener: removeReceiverActionListener,

            shouldUpdatePlaybackBar: shouldUpdatePlaybackBar,
            currentTrackDuration: currentTrackDuration,
            currentTrackTime: currentTrackTime
        };
    });
})();

