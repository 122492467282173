(function (global) {
    define('webstore-client/ui/playback/player/google-cast/google-cast-proxy',[], function () {

        function proxy() {
            return global.chrome;
        }

        function onCastAPIavailable(onAvailable, onError) {
            global['__onGCastApiAvailable'] = function (loaded, errorInfo) {
                if (loaded) {
                    onAvailable();
                } else {
                    onError(errorInfo);
                }
            };
        }

        return {
            chrome: proxy(),
            onCastAPIavailable: onCastAPIavailable
        };
    });
})(window);

