define('webstore-client/ui/playback/player/display',[
    'jquery',
    'lodash',
    'webstore-client/ui/playback/controller',
    'template-player-display',
    'webstore-client/ui/playback/player/google-cast/cast-detection',
    'webstore-client/ui/playback/player/google-cast/cast-intro-message',
    'webstore-client/ui/playback/player/volume-slider'
], function ($, _, controller, renderDisplay, castDetection, castToolTip, volume) {
    var $playerContainer = $('#player');
    var _cachedCurrentTrack = {};
    var initialised = false;
    var _displayedTime;

    castDetection.onDeviceAvailability(setCastDeviceAvailability);

    function setCastDeviceAvailability(isAvailable) {
        if (isAvailable) {
            $playerContainer.addClass('cast-available');
            castToolTip.display();
        } else {
            $playerContainer.removeClass('cast-available');
        }
    }

    function updateDisplay(model) {
        var currentVolume = volume.getValue();
        var updatedDisplay = renderDisplay(model);
        $('.player-display', $playerContainer).replaceWith(updatedDisplay);

        volume.setValue(currentVolume);

        if (controller.isUsingBrowserPlayer()) {
            // Force jplayer to rebind after rendering
            controller.setSelectors({
                seekBar: '#player-progress-bar-buffered',
                playBar: '#player-progress-bar-current'
            });
        }
    }

    //Sadly we need both hidden and visible classes for css transitions not
    //to do weird things on pageload.
    function show() {
        $playerContainer.removeClass('is-hidden');
        $playerContainer.addClass('is-visible');
        castToolTip.hasBeenSeen();

        if (!controller.isUsingBrowserPlayer()) {
            // Force buffer bar to full width when casting
            var playbackBarWidth = $('.player-progress-bar-background').width();
            $('#player-progress-bar-buffered').width(playbackBarWidth);
        }
    }

    function hide() {
        $playerContainer.addClass('is-hidden');
        $playerContainer.removeClass('is-visible');
        castToolTip.removeIfSeen();
    }

    function sync() {
        var currentTrack = controller.currentTrack() || {};
        var error = controller.getError();

        if (error) {
            if (!error.isFatal) {
                // Prevent showing the same error continually
                controller.clearError();
            }

            updateDisplay({
                track: currentTrack,
                error: error.translationKey
            });

            show();

        } else if (currentTrack.trackId !== _cachedCurrentTrack.trackId) {
            updateDisplay({
                track: currentTrack
            });

            _cachedCurrentTrack = currentTrack;

            if (!controller.isUsingBrowserPlayer() && !controller.isPlaying()) {
                show();
            }
        }

        if (controller.isPlaying()) {
            $('#player-play-pause').removeClass('paused');
            show();

        } else {
            $('#player-play-pause').addClass('paused');
            if (!error && !currentTrack.trackId) {
                hide();
            }
        }

        if (controller.isLoading()) {
            $('#player-now-playing').addClass('is-loading');
        } else {
            $('#player-now-playing').removeClass('is-loading');
        }
    }

    function syncRemainingTime() {
        $.jPlayer.timeFormat.padMin = false;

        var isCasting = !controller.isUsingBrowserPlayer();
        var currentTime = controller.currentTrackTime();
        var duration = controller.currentTrackDuration();
        var timeToShow = $.jPlayer.convertTime(
            isCasting ? currentTime : duration - currentTime
        );

        if (_displayedTime !== timeToShow) {
            var textDisplay = isCasting ? timeToShow : '-' + timeToShow;
            $('#player-progress-time').text(textDisplay);
        }

        _displayedTime = timeToShow;

        if (controller.shouldUpdatePlaybackBar()) {
            var progressWidth = String((currentTime / duration) * 100) + '%';
            $('#player-progress-bar-current').width(progressWidth);
        }
    }

    function syncCastDeviceStatus() {
        var castIconButton = $playerContainer.find('#player-cast-icon-button');

        if (controller.castDeviceIsConnecting()) {
            castIconButton.removeClass('player-cast-connected player-cast-to-device');
            castIconButton.addClass('player-cast-connecting');
        } else if (controller.castDeviceIsConnected()) {
            castIconButton.removeClass('player-cast-connecting player-cast-to-device');
            castIconButton.addClass('player-cast-connected');
        } else {
            castIconButton.removeClass('player-cast-connecting player-cast-connected');
            castIconButton.addClass('player-cast-to-device');
        }
    }

    function setMuteStatus(isMute) {
        var muteToggleBtn = $('#player-mute-toggle-btn');
        if (isMute) {
            muteToggleBtn.removeClass('player-volume-mute');
            muteToggleBtn.addClass('player-volume-unmute');
        } else {
            muteToggleBtn.removeClass('player-volume-unmute');
            muteToggleBtn.addClass('player-volume-mute');
        }
    }

    if (!initialised) {
        $('#player').addClass('is-hidden');
        initialised = true;
    }

    return {
        sync: sync,
        syncRemainingTime: syncRemainingTime,
        syncCastDeviceStatus: syncCastDeviceStatus,
        hide: hide,
        setMuteStatus: setMuteStatus
    };
});

