define('template-error-message',['handlebars'], function(Handlebars) {

return Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var alias1=helpers.helperMissing, alias2=this.escapeExpression;

  return "<div class=\"error\">\n    <h1 class=\"error-header\">"
    + alias2((helpers.translate || (depth0 && depth0.translate) || alias1).call(depth0,"Error500Title",{"name":"translate","hash":{},"data":data}))
    + "</h1>\n    <p class=\"error-content\">"
    + alias2((helpers.translate || (depth0 && depth0.translate) || alias1).call(depth0,"Error500Message",{"name":"translate","hash":{},"data":data}))
    + "</p>\n</div>";
},"useData":true})

});
