define('template-yourmusic-track-download-link',['handlebars'], function(Handlebars) {

return Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "<a href=\""
    + alias3(((helper = (helper = helpers.trackDownloadLinkUrl || (depth0 != null ? depth0.trackDownloadLinkUrl : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"trackDownloadLinkUrl","hash":{},"data":data}) : helper)))
    + "\" download=\""
    + alias3(((helper = (helper = helpers.trackDownloadLinkFilename || (depth0 != null ? depth0.trackDownloadLinkFilename : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"trackDownloadLinkFilename","hash":{},"data":data}) : helper)))
    + "\">"
    + alias3(((helper = (helper = helpers.trackDownloadLinkText || (depth0 != null ? depth0.trackDownloadLinkText : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"trackDownloadLinkText","hash":{},"data":data}) : helper)))
    + "</a>\n";
},"useData":true})

});
