define('webstore-client/utilities/ajax',[
    'jquery',
    'lodash',
    'debug'
], function ($, _, debug) {

    var pendingRequest, defaultOpts = { background: false };

    function dispatchRequest(passedOpts) {
        var opts = _.defaults(passedOpts, defaultOpts);

        // Background requests are not included in the pending request
        // 'flow', i.e. they don't cancel requests and aren't cancelled by
        // other requests.
        if (!opts.background && pendingRequest) {

            debug.log('theres a request pending');
            debug.log('its state is %s', pendingRequest.state());

            if (pendingRequest.state() === 'pending') {

                debug.log('aborting pending request');
                pendingRequest.abort();
            }
        }

        debug.log('ajaxing');
        var request = $.ajax(opts);

        if (!opts.background) {
            pendingRequest = request;
        }

        return request;
    }

    return dispatchRequest;
});

