define('webstore-client/pages/checkout',[
    'jquery',
    'webstore-client/ui/basket/controller',
    'template-checkout-basket',
    'webstore-client/locker/ownership'
], function ($, basketController, renderCheckoutBasket, availability) {
    var $voucherField, $voucherSubmit, $toggleVoucherMobile, $voucherForm,
        $allForms, $checkoutBasketContainer, $paymentMethods;

    function bindElements() {
        $allForms = $('.checkout form');
        $toggleVoucherMobile = $('#togglevouchermobile');
        $voucherForm = $('.payment-voucher');
        $voucherField = $('input[name="voucher-code"]');
        $voucherSubmit = $('.payment-voucher button');
        $paymentMethods = $('#payment-methods');
    }

    function updateBasketView(basketResponse) {
        var newMarkup = renderCheckoutBasket(basketResponse);
        $('#checkout-basket').replaceWith(newMarkup);
        $checkoutBasketContainer = $('.checkout-basket-container');

        if (basketResponse.basket.isFree &&
            basketResponse.basket.items.length !== 0) {

            $paymentMethods.remove();
            $checkoutBasketContainer
                .addClass('is-free has-items')
                .removeClass('is-empty');
        } else if (basketResponse.basket.items.length === 0) {
            $paymentMethods.remove();
            $checkoutBasketContainer
                .addClass('is-empty')
                .removeClass('has-items is-free');
        } else {
            $checkoutBasketContainer
                .addClass('has-items')
                .removeClass('is-empty is-free');
        }
    }

    function syncVoucherState() {
        var voucherValue = $voucherField.val();

        var isDisabled = (voucherValue && voucherValue.length < 5);
        $voucherSubmit.prop('disabled', isDisabled);
    }

    function toggleVoucherVisibilityOnMobile(isVisible) {
        $voucherForm.toggleClass('is-visible-mobile', isVisible);

        if (isVisible) {
            $voucherForm.find('input:eq(0)').focus();
        }
    }

    function disableSubmitButton() {
        $(this).find('[type="submit"]').prop('disabled', true);
    }

    function clearLockerCache() {
        availability.clearData();
    }

    return function pageLoaded() {
        $('body').addClass('hides-menu');

        bindElements();
        clearLockerCache();

        basketController.onUpdate(updateBasketView);

        $allForms.on('submit', disableSubmitButton);
        $toggleVoucherMobile.on('click tap', toggleVoucherVisibilityOnMobile);

        syncVoucherState();
        $voucherField.on('keyup', syncVoucherState);
    };
});

