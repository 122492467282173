define('webstore-client/ui/basket/buy-button-states',[
    'jquery',
    'lodash',
    'webstore-client/ui/basket/controller',
    'webstore-client/utilities/get-relevant-buttons',
    'client-config'
], function ($, _, basketController, getRelevantButtons, clientConfig) {

    //The is-adding state is controlled by the buy-buttons module instead
    var states = {
        standard: '',
        hasError: 'has-error',
        inBasket: 'is-in-basket',
        adding: 'is-adding'
    };

    function addState(buttons, state) {
        buttons.addClass(state);
    }

    function removeState(buttons, state) {
        buttons.removeClass(state);
    }

    function syncButtonsToBasket() {
        basketController.getCurrent().done(function (basketResponse) {
            if (!clientConfig.isOnWrongShop) {
                var allButtons = $('.buy-button');
                removeState(allButtons, states.inBasket);

                _.forEach(basketResponse.basket.items, function (item) {
                    var buttons = getRelevantButtons(item.releaseId, item.trackId);
                    addState(buttons, states.inBasket);
                });
            }
        });
    }

    return {
        states: states,
        addState: addState,
        removeState: removeState,
        syncButtonsToBasket: syncButtonsToBasket
    };
});

