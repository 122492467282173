/*
 * content loading controller module
 * wires together events required for content loading
 * Functionality for 7digital.com
 * (c) 7digital.com
 */

define('webstore-client/contentloading/controller',[
    'jquery',
    'webstore-client/contentloading/navigation',
    'webstore-client/utilities/history',
    'webstore-client/contentloading/fetcher',
    'webstore-client/contentloading/pageupdater',
    'webstore-client/analytics/track',
    'webstore-client/utilities/location',
    'client-config',
    'debug'
], function ($, navigation, history, fetcher, pageUpdater, track, location, config, debug) {

    var listenerContext = $('<div></div>');
    var navEvent = {
        start: 'nav:start',
        progress: 'nav:progress',
        end: 'nav:end'
    };

    function triggerProgress(progress) {
        if (progress < 1) {
            listenerContext.trigger(navEvent.progress, { progress: progress });
        }
    }

    function init() {
        var stopwatch, timeTaken;

        if (!history.isSupported || config.disablePartialPage) { return; }

        stopwatch = track.startNewStopwatch();
        navigation.init();

        history.onUrlChange(function (url, data) {
            var request, previousState;

            function updatePageWithContent(result) {
                var previousState, currentState;

                function handlePageUpdated(err) {
                    listenerContext.trigger(navEvent.end);

                    if (err) {
                        debug.error('Error updating page content', err, {
                            url: url,
                            passedContent: result
                        });
                        return navigation.fullPageLoad(url);
                    }

                    if (data.hash) {
                        history.pushHash(data.hash);
                    }
                }

                history.urlChangeHandled();
                pageUpdater.updatePage(result, data.scrollTop, handlePageUpdated);
            }

            listenerContext.trigger(navEvent.start);

            request = fetcher.fetchContent(url, triggerProgress, updatePageWithContent);

        });

        timeTaken = stopwatch.stop();
        track.trackTiming('Performance', 'Provisional - content loading' +
            'controller initialisation', timeTaken);
    }

    function onNavStart(cb) {
        listenerContext.on(navEvent.start, cb);
    }

    function onNavProgress(cb) {
        listenerContext.on(navEvent.progress, function (evt, data) { cb(data); });
    }

    function onNavEnd(cb) {
        listenerContext.on(navEvent.end, cb);
    }

    return {
        init: init,
        onNavStart: onNavStart,
        onNavEnd: onNavEnd,
        onNavProgress: onNavProgress
    };
});

