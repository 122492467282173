define('webstore-client/ui/playback/play-buttons/event-handler',[
    'jquery',
    'webstore-client/contentloading/controller',
    'webstore-client/ui/playback/controller',
    'webstore-client/ui/playback/player/display',
    'webstore-client/ui/playback/play-buttons/display',
    'webstore-client/analytics/track'
], function ($, contentLoadingController, controller, playerDisplay, playButtonsDisplay, track) {
    var initialised = false;

    function bindTrackPlayLinks() {
        $('#page-container').on('click', '[data-track-play]', function (e) {
            if (controller.getError()) {
                playerDisplay.sync();

            } else if (controller.isReady()) {
                var trackId = $(this).closest('[data-trackid]').data('trackid').toString();
                var releaseId = $(this).closest('[data-releaseid]').data('releaseid').toString();

                if (controller.currentTrack() &&
                    controller.currentTrack().trackId === trackId) {

                    if (controller.isLoading()) {
                        //Abort if we know which track is playing and that one
                        //has been clicked during load
                        controller.abortLoad();
                        track.event('Player', 'Interaction', 'Abort');

                    } else if (controller.isPlaying()) {
                        controller.pause();
                        track.event('Player', 'Interaction', 'Pause');
                    } else {
                        controller.resume();
                        track.event('Player', 'Interaction', 'Resume');
                    }
                } else {
                    if (!controller.currentTrack() && controller.isLoading()) {
                        //Abort if we don't know which track is playing and any
                        //track has been clicked during load
                        controller.abortLoad();
                        track.event('Player', 'Interaction', 'Abort');

                    } else {
                        //States are updated here for UI feel/responsiveness,
                        //rather than waiting for stateChange callback (which
                        //may only happen after an ajax playlist fetch call
                        //completes, slooooow)
                        playButtonsDisplay.setTrackPlaying(trackId);

                        var playlistType = $(this).closest('[data-playlist]').data('playlist');
                        var artistId = $(this).closest('[data-artistid]').data('artistid').toString();
                        var playlist = {type:playlistType, trackId:trackId};
                        if (playlistType === 'artist-top-tracks') {
                            playlist.artistId = artistId;
                        } else {
                            playlist.releaseId = releaseId;
                        }

                        controller.playPlaylist(playlist);
                    }
                }
            }
            e.preventDefault();
            e.stopPropagation();
        });
    }

    function bindReleasePlayLinks() {
        $('#page-container').on('click', '[data-release-play]', function (e) {
            if (controller.getError()) {
                playerDisplay.sync();

            } else if (controller.isReady()) {
                var releaseId = $(this).closest('[data-releaseid]').data('releaseid').toString();
                var playlistType = $(this).closest('[data-playlist]').data('playlist');
                if (!playlistType) { playlistType = 'release'; }

                if (controller.isLoading()) {
                    controller.abortLoad();
                    track.event('Player', 'Interaction', 'Abort');

                } else if (
                    controller.playlistIsActive('release', releaseId) ||
                    controller.playlistIsActive('yourmusic-release', releaseId)) {

                    if (controller.isPlaying()) {
                        controller.pause();
                        track.event('Player', 'Interaction', 'Pause');

                    } else {
                        controller.resume();
                        track.event('Player', 'Interaction', 'Resume');
                    }
                } else {
                    //States are updated here for UI feel/responsiveness,
                    //rather than waiting for stateChange callback (which may
                    //only happen after an ajax playlist fetch call completes,
                    //slooooow)
                    playButtonsDisplay.setReleaseLoading(releaseId);

                    var playlist = {releaseId:releaseId};
                    if (playlistType === 'yourmusic-release') {
                        playlist.type = 'yourmusic-release';
                    } else {
                        playlist.type = 'release';
                    }

                    controller.playPlaylist(playlist);
                }
            }
            e.preventDefault();
            e.stopPropagation();
        });
    }

    if (!initialised) {
        bindTrackPlayLinks();
        bindReleasePlayLinks();

        contentLoadingController.onNavEnd(playButtonsDisplay.sync);
        controller.onStateChange(playButtonsDisplay.sync);

        initialised = true;
    }
});

