define('webstore-client/ui/search',[
    'lodash',
    'jquery',
    'webstore-client/ui/search/live-search',
    'webstore-client/ui/search/mobile-search',
    'webstore-client/utilities/media-query'
], function (_, $, liveSearch, mobileSearch, mediaQuery) {
    var initialised = false;
    var fixedClass = 'has-fixed-header';
    var $container = $('.page-container');
    var $accountHeader = $('.header-account');
    var $document = $(document);

    function toggleSearchModule(isFirst) {
        var init = liveSearch.init;
        var destroy = mobileSearch.destroy;

        if (mediaQuery.matches('less-than-tablet-portrait')) {
            init = mobileSearch.init;
            destroy = liveSearch.destroy;
        }

        init();

        if (!isFirst) {
            destroy();
        }
    }

    function toggleFixedHeader(event) {
        var fixAfterHeight = $accountHeader.height() + 5;

        $container.toggleClass(fixedClass, $document.scrollTop() > fixAfterHeight);
    }

    if (!initialised) {
        toggleSearchModule(true);
        $(window).on('viewportChange', function () {
            toggleSearchModule();
        });

        $document.on('scroll', $container, _.throttle(toggleFixedHeader, 200));

        toggleFixedHeader();

        initialised = true;
    }
});

