define('webstore-client/ui/notice',[
    'lodash',
    'jquery',
    'webstore-client/contentloading/controller',
    'lib/jquery.cookie'
], function (_, $, contentloading) {
    var initialised = false;
    var cookieName = 'hideNotices';
    var cookieExpiry = new Date('9999/12/31');
    var cookieToSelectorPattern = /([^,]+)/g;

    function buildSelectorsFromCookie(cookie) {
        return cookie.replace(cookieToSelectorPattern, '[data-notice-id="$1"]');
    }

    function showNotices() {
        var cookieVal = $.cookie(cookieName);
        var toShow = $('[data-notice="true"].is-hidden');
        if (cookieVal) {
            var hidden = buildSelectorsFromCookie(cookieVal);
            toShow = toShow.not(hidden);
        }
        toShow.toggleClass('is-visible is-hidden');
    }

    function setCookie(cookieVal) {
        $.cookie(cookieName, cookieVal, { path: '/', expires: cookieExpiry });
    }

    function persistHide(noticeKey) {
        if (!noticeKey) { return; }

        var cookieVal = $.cookie(cookieName);

        if (!cookieVal) {
            return setCookie(noticeKey);
        }

        if (!_.contains(cookieVal.split(','), noticeKey)) {
            return setCookie(cookieVal += ',' + noticeKey);
        }
    }

    function bindCloseLinks() {
        $('#page-container').on('click', '[data-notice-close]', function (e) {
            var notice = $(e.target).closest('[data-notice]');
            var noticeKey = notice.data('notice-id');

            persistHide(noticeKey);
            notice.toggleClass('is-hidden is-visible');

            e.stopPropagation();
            e.preventDefault();
        });
    }

    if (!initialised) {
        showNotices();
        bindCloseLinks();
        contentloading.onNavEnd(showNotices);
        initialised = true;
    }
});

