define('webstore-client/ui/side-navigation',[
    'jquery',
    'webstore-client/analytics/track'
], function ($, analytics) {

    var _initialized = false;
    var _isOpen = false;

    init();

    function init() {
        if (_initialized) {
            return;
        }
        _initialized = true;
        $('.header-sidebar-control').on('click', toggleSidebar);
        $('.side-navigation-submenu-title').on('click', toggleSubMenu);
        $('.side-navigation-check').remove();
        $('.side-navigation-item a').on('click', trackLink);
    }

    function hide() {
        $('#side-navigation').removeClass('open-lt-desktop');
        $('#page-container').removeClass('sidenav-open-lt-desktop');
        $('html, body').removeClass('disable-body-scroll');
        $('.content-interaction-blocked.overlay').off('click', toggleSidebar);
        $('.content-interaction-blocked').remove();
        _isOpen = false;
    }

    function toggleSidebar(event) {
        event.stopPropagation();
        event.preventDefault();

        $('#side-navigation').toggleClass('open-lt-desktop');
        $('#page-container').toggleClass('sidenav-open-lt-desktop');
        $('html, body').toggleClass('disable-body-scroll');

        if (!_isOpen) {
            var bodyContainer = $('.body-container');
            bodyContainer.prepend('<div class="content-interaction-blocked underlay">');
            bodyContainer.prepend('<div class="content-interaction-blocked overlay">');
            $('.content-interaction-blocked.overlay').on('click', toggleSidebar);
        } else {
            $('.content-interaction-blocked.overlay').off('click', toggleSidebar);
            $('.content-interaction-blocked').remove();
        }

        _isOpen = !_isOpen;
    }

    function toggleSubMenu(event) {
        var selectedTitle = $(event.currentTarget);
        var isOpen = selectedTitle.hasClass('is-open');
        var subMenu = selectedTitle.find('~ .side-navigation-submenu').eq(0);
        var subMenuHeight = subMenu[0].scrollHeight;
        var newHeight = isOpen ? 0 : subMenuHeight;
        var transitionDuration = Math.min(subMenuHeight / 1200, 0.6);

        $('.side-navigation-submenu').css('maxHeight', 0);
        $('.side-navigation-submenu-title').removeClass('is-open');

        selectedTitle.toggleClass('is-open', !isOpen);
        subMenu.css('transitionDuration', transitionDuration + 's');
        subMenu.css('maxHeight', newHeight);

        trackMenuEvent((!isOpen) ? 'Opened submenu' : 'Closed submenu', selectedTitle.text());
    }

    function trackLink(event) {
        var detail = $(event.target).text();
        trackMenuEvent('Menu item', detail);
    }

    function trackMenuEvent(eventName, detail) {
        var isMobile = $('.page-container').hasClass('sidenav-open-lt-desktop');

        analytics.event(
                'Menu',
                eventName + ' - ' + ((isMobile) ? 'Small screen' : 'Large screen'),
                detail
        );
    }

    return {
        hide: hide
    };
});

