define('webstore-client/ui/playback/player/google-cast/cast-intro-message',['template-cast-intro-message', 'jquery'], function (castIntroMessage, $) {
    var introMessageSeen = 'chromeIntroMessage';
    var showMessage = function () {
        return window.localStorage && !window.localStorage[introMessageSeen];
    };

    function display() {
        if (showMessage()) {
            var $el = $('.player-cast-icon-container').append(castIntroMessage());
            $el.one('click', close);
        }
    }

    function close() {
        hasBeenSeen();
        removeIfSeen();
    }

    function hasBeenSeen() {
        window.localStorage[introMessageSeen] = true;
    }

    function removeIfSeen() {
        if (!showMessage()) {
            $('#player .cast-intro-message').remove();
        }
    }

    return {
        hasBeenSeen: hasBeenSeen,
        removeIfSeen: removeIfSeen,
        display: display
    };
});

