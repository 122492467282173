define('webstore-client/utilities/get-relevant-buttons',[
    'jquery'
], function ($) {
    return function getRelevantButtons(releaseId, trackId) {
        //All release buttons relevant to current item
        var relevantReleaseButtons = $('form input[name="releaseId"][value="' + releaseId + '"]')
            .siblings('button');

        if (!trackId) {
            //Early out for a release
            return relevantReleaseButtons;
        }

        //All track buttons for relevant release on the page
        //(whether relevant to current item or not)
        var allTrackButtons = $('form input[name="releaseId"][value="' + releaseId + '"]')
            .siblings('input[name="trackId"]')
            .siblings('button');

        //All track buttons relevant to current item
        var relevantTrackButtons = $('form input[name="releaseId"][value="' + releaseId + '"]')
            .siblings('input[name="trackId"][value="' + trackId + '"]')
            .siblings('button');

        // Single track releases count as bought if their only track is added.
        // This works around some basket API weirdness...
        // This is for release pages...
        if (allTrackButtons.length === 1 && relevantTrackButtons.length === 1) {
            return relevantTrackButtons.add(relevantReleaseButtons);
        }

        // And this is for overview pages
        if (relevantReleaseButtons.length > 0 && trackId && relevantTrackButtons.length === 0) {
            return relevantReleaseButtons.filter('.is-single-track-release');
        }

        return relevantTrackButtons;
    };
});

