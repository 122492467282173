define('template-checkout-basket',['handlebars'], function(Handlebars) {

return Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "		<p class=\"basket-empty-notice\"><a href=\"/\">"
    + this.escapeExpression((helpers.translate || (depth0 && depth0.translate) || helpers.helperMissing).call(depth0,"BasketEmpty",{"name":"translate","hash":{},"data":data}))
    + "</a></p>\n";
},"3":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.escapeExpression;

  return ((stack1 = helpers['if'].call(depth0,((stack1 = (depth0 != null ? depth0.basket : depth0)) != null ? stack1.hasError : stack1),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "		<ul>\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.basket : depth0)) != null ? stack1.items : stack1),{"name":"each","hash":{},"fn":this.program(6, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "		</ul>\n		</div>\n		<div class=\"checkout-basket-column-last\">\n"
    + ((stack1 = helpers['if'].call(depth0,((stack1 = (depth0 != null ? depth0.basket : depth0)) != null ? stack1.isFree : stack1),{"name":"if","hash":{},"fn":this.program(14, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n			<p class=\"checkout-total\">\n				"
    + alias1((helpers.translate || (depth0 && depth0.translate) || helpers.helperMissing).call(depth0,"OrderTotal",{"name":"translate","hash":{},"data":data}))
    + ": <strong>"
    + alias1(this.lambda(((stack1 = (depth0 != null ? depth0.basket : depth0)) != null ? stack1.formattedTotalPrice : stack1), depth0))
    + "</strong>\n			</p>\n\n"
    + ((stack1 = helpers['if'].call(depth0,((stack1 = (depth0 != null ? depth0.basket : depth0)) != null ? stack1.isFree : stack1),{"name":"if","hash":{},"fn":this.program(16, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "		</div>\n";
},"4":function(depth0,helpers,partials,data) {
    return "			<p role=\"alert\" class=\"notice-error\">"
    + this.escapeExpression((helpers.translate || (depth0 && depth0.translate) || helpers.helperMissing).call(depth0,"Error_Processing_Request",{"name":"translate","hash":{},"data":data}))
    + "</p>\n";
},"6":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "		<li class=\"checkout-item\">\n\n			<a href=\""
    + alias3(((helper = (helper = helpers.releaseUrl || (depth0 != null ? depth0.releaseUrl : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"releaseUrl","hash":{},"data":data}) : helper)))
    + "\" class=\"checkout-packshot-image\">\n				<img src=\""
    + alias3(((helper = (helper = helpers.packshotUrl || (depth0 != null ? depth0.packshotUrl : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"packshotUrl","hash":{},"data":data}) : helper)))
    + "\" alt=\""
    + alias3(((helper = (helper = helpers.artistName || (depth0 != null ? depth0.artistName : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"artistName","hash":{},"data":data}) : helper)))
    + " - "
    + alias3(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" />\n			</a>\n\n			<p  class=\"checkout-item-details\">\n				<a class=\"checkout-item-name\" href=\""
    + alias3(((helper = (helper = helpers.releaseUrl || (depth0 != null ? depth0.releaseUrl : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"releaseUrl","hash":{},"data":data}) : helper)))
    + "\">"
    + alias3(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"name","hash":{},"data":data}) : helper)))
    + "</a>\n				<a class=\"checkout-item-artist\" href=\""
    + alias3(((helper = (helper = helpers.artistUrl || (depth0 != null ? depth0.artistUrl : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"artistUrl","hash":{},"data":data}) : helper)))
    + "\">"
    + alias3(((helper = (helper = helpers.artistName || (depth0 != null ? depth0.artistName : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"artistName","hash":{},"data":data}) : helper)))
    + "</a>\n				<span class=\"basket-item-format\">"
    + alias3(((helper = (helper = helpers['package'] || (depth0 != null ? depth0['package'] : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"package","hash":{},"data":data}) : helper)))
    + "</span>\n				<strong class=\"checkout-item-price\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.isVoucher : depth0),{"name":"if","hash":{},"fn":this.program(7, data, 0),"inverse":this.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + "				</strong>\n			</p>\n\n			<form class=\"checkout-item-remove\" action=\"/basket/remove\" method=\"post\">\n				<input type=\"hidden\" name=\"itemId\" value=\""
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n				<button type=\"submit\" class=\"basket-item-remove\">&times;\n					<span>"
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias1).call(depth0,"Remove",{"name":"translate","hash":{},"data":data}))
    + "</span>\n				</button>\n			</form>\n\n\n		</li>\n";
},"7":function(depth0,helpers,partials,data) {
    return "					"
    + this.escapeExpression((helpers.translate || (depth0 && depth0.translate) || helpers.helperMissing).call(depth0,"Voucher",{"name":"translate","hash":{},"data":data}))
    + "\n";
},"9":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.isFree : depth0),{"name":"if","hash":{},"fn":this.program(10, data, 0),"inverse":this.program(12, data, 0),"data":data})) != null ? stack1 : "");
},"10":function(depth0,helpers,partials,data) {
    return "						"
    + this.escapeExpression((helpers.translate || (depth0 && depth0.translate) || helpers.helperMissing).call(depth0,"FreePrice",{"name":"translate","hash":{},"data":data}))
    + "\n";
},"12":function(depth0,helpers,partials,data) {
    var helper;

  return "						"
    + this.escapeExpression(((helper = (helper = helpers.formattedPrice || (depth0 != null ? depth0.formattedPrice : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"formattedPrice","hash":{},"data":data}) : helper)))
    + "\n";
},"14":function(depth0,helpers,partials,data) {
    return "				<p class=\"basket-free-purchase\">"
    + this.escapeExpression((helpers.translate || (depth0 && depth0.translate) || helpers.helperMissing).call(depth0,"FreePurchase",{"name":"translate","hash":{},"data":data}))
    + "</p>\n";
},"16":function(depth0,helpers,partials,data) {
    return "			<form class=\"basket-free-container\" action=\"/CheckoutProcessingDispatch/ProcessPayment\" method=\"post\">\n				<button type=\"submit\">"
    + this.escapeExpression((helpers.translate || (depth0 && depth0.translate) || helpers.helperMissing).call(depth0,"CompletePurchase",{"name":"translate","hash":{},"data":data}))
    + "</button>\n			</form>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div id=\"checkout-basket\">\n	<div class=\"checkout-basket-column-first\">\n"
    + ((stack1 = helpers.unless.call(depth0,((stack1 = (depth0 != null ? depth0.basket : depth0)) != null ? stack1.shouldDisplay : stack1),{"name":"unless","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true})

});
